import React, { useCallback, useEffect, useMemo, useState } from "react";
import AccordionMultiField from "components/AccordionMultiField/AccordionMultiField";
import { useTranslation } from "react-i18next";
import { Box, Chip, Grid, makeStyles, Tooltip } from "@material-ui/core";
import { useMetaView } from "app/contexts/meta-view-context";
import { useLegalBasisSuggestion } from "app/handlers/legalBasisHandler";
import StarIcon from "@material-ui/icons/Star";
import { debounce, isEqual } from "lodash-es";
import { ResourceField } from "../../../../components/ResourceField";
import { RESOURCE_TYPES } from "../../../handlers/resourceHandler";
import { useResources } from "../../../contexts/resource-context";
import { useParams } from "react-router-dom";
import { useAuthentication } from "../../../handlers/authentication/authentication-context";
import { QUESTION_TYPE } from "components/Question/QuestionTypes";
import Question from "components/Question/Question";

export interface Purpose {
  readonly id: string;
  readonly purposeDescription: string;
  readonly legalBasisId: string;
  readonly lbOtherLegalBasisValue: string;
  readonly lbContractValue: string;
  readonly lbLegalObligationValue: string;
  readonly lbVerificationValue: string;
  readonly lbVitalInterestsValue: string;
  readonly lbControllerInterestValue: string;
  readonly lbInterestOfTheDataSubjectValue: string;
  readonly lbInterestBalancingValue: string;
  readonly lbConsentContentValue: string;
  readonly lbObtainingConsentValue: string;
  readonly lbPublicInterestValue: string;
  readonly lbDefaultValue: string;
}

/* PURPOSE CHIP */
const useLegalBasisChipStyles = makeStyles(theme => ({
  legalBasis: {
    backgroundColor: theme.palette.blue[50],
    maxWidth: "300px"
  },
  emptyLegalBasis: {
    backgroundColor: theme.palette.alert.warningBackground,
    color: theme.palette.alert.warning
  }
}));
const PurposeLegalBasisChip = ({ legalBasis }: { legalBasis: string }) => {
  const cls = useLegalBasisChipStyles();
  const { t } = useTranslation("questionnaires");
  const { translateById } = useResources();

  return (
    <Chip
      className={legalBasis ? cls.legalBasis : cls.emptyLegalBasis}
      label={legalBasis ? translateById(RESOURCE_TYPES.LEGAL_BASIS, legalBasis) : t("noLegalBasis")}
    />
  );
};

/* PURPOSE CONDITIONAL TEXT */
interface PurposeConditionalTextFieldProps {
  readonly propertyName: string;
  readonly value?: string | undefined;
  readonly disabled?: boolean;
  readonly legalBasisId?: string;
  readonly onChange: (val: { [key: string]: string }) => void;
}
const PurposeConditionalTextField = ({
  propertyName,
  value,
  legalBasisId,
  onChange,
  disabled
}: PurposeConditionalTextFieldProps) => {
  const { t } = useTranslation("questionnaires");
  const { resources, resourcesLoaded } = useResources();
  const isDefaultLegalBasis = useMemo(() => {
    if (legalBasisId && resourcesLoaded) {
      const allLegalBasis = resources[RESOURCE_TYPES.LEGAL_BASIS];
      return Boolean(allLegalBasis.find(({ id }) => id === legalBasisId)?.defaultResource);
    } else return true;
  }, [legalBasisId, resources, resourcesLoaded]);

  const label = t(`${propertyName}:questionTitle`);

  const onChangeCallback = useCallback(
    value => {
      onChange({ [propertyName]: value });
    },
    [onChange, propertyName]
  );
  return (
    <Question
      qType={QUESTION_TYPE.TEXT_AREA}
      questionName={label}
      value={value || ""}
      onChange={onChangeCallback}
      disabled={disabled}
      infoId={`infocard.pa.page2.purposeAccordion.${isDefaultLegalBasis ? propertyName : "isDefaultLegalBasis"}`}
      pt={1}
      pb={1}
    />
  );
};

/* PURPOSE ACCORDION */
const usePurposeAccordionStyles = makeStyles(theme => ({
  placeholder: {
    fontStyle: "italic",
    color: theme.palette.grey[500]
  }
}));

type PurposeAccordionProps = {
  readonly purpose: Purpose;
  readonly paOrgUnitIds: string[];
  readonly onDelete: (id: string) => void;
  readonly onSave: (val: Purpose) => void;
  readonly isCreatedNew?: boolean;
  readonly disabled?: boolean;
};
const PurposeAccordion = ({ purpose, onDelete, onSave, isCreatedNew, disabled }: PurposeAccordionProps) => {
  const cls = usePurposeAccordionStyles();
  const { t } = useTranslation("questionnaires");
  const { suggestLegalBasis } = useLegalBasisSuggestion();
  const { setInfoId } = useMetaView();
  const { id } = useParams();
  const { auth } = useAuthentication();
  const isLegalBasisReadOnly = auth?.permissions.includes("pa_read_legalbasis_org");
  const isLegalBasisAllowWrite = auth?.permissions.includes("pa_write_legalbasis_org");
  const [currentPurpose, setCurrentPurpose] = useState<Purpose>(purpose || {});
  const [dirty, setDirty] = useState<boolean>(false);

  useEffect(() => {
    const diff = Object.keys(currentPurpose).some(
      key => !isEqual(currentPurpose[key as keyof Purpose], purpose[key as keyof Purpose])
    );
    setDirty(diff);
  }, [currentPurpose, purpose]);

  useEffect(() => {
    if (purpose) {
      setCurrentPurpose(purpose);
    }
  }, [purpose]);
  const [suggestedId, setSuggestedId] = useState<string | null>(null);

  const refreshSuggestedLegalBasis = useMemo(
    () =>
      debounce(async name => {
        const data = await suggestLegalBasis(name);
        setSuggestedId(data?.id ? data.id : null);
      }, 1000),
    [suggestLegalBasis]
  );

  useEffect(() => {
    if (currentPurpose.purposeDescription) {
      refreshSuggestedLegalBasis(currentPurpose.purposeDescription);
    }
  }, [currentPurpose.purposeDescription, refreshSuggestedLegalBasis]);

  const updateCurrentPurpose = useCallback((updatedFields: Partial<Purpose>) => {
    setCurrentPurpose(currentPurpose => ({ ...currentPurpose, ...updatedFields }));
  }, []);

  /* TITLE */
  const legalBasisChipEl = isLegalBasisReadOnly && <PurposeLegalBasisChip legalBasis={currentPurpose.legalBasisId} />;
  const titleEl = (
    <Box display="flex" alignItems="center" width="100%">
      <Box flexGrow={1} className={!currentPurpose.purposeDescription ? cls.placeholder : ""}>
        {currentPurpose.purposeDescription || t("0_2_purposes:placeholder")}
      </Box>
      <Box>{legalBasisChipEl}</Box>
    </Box>
  );

  /* CONTENT */
  const onChangeName = useCallback(
    purposeDescription => {
      updateCurrentPurpose({ purposeDescription });
    },
    [updateCurrentPurpose]
  );

  const purposeNameEl = (
    <Question
      qType={QUESTION_TYPE.TEXT_AREA}
      questionName={t("0_2_purposes:questionTitle")}
      value={currentPurpose.purposeDescription || ""}
      onChange={onChangeName}
      error={!currentPurpose.purposeDescription}
      helperText={!currentPurpose.purposeDescription ? t("questionnaires:mandatoryField") : ""}
      disabled={disabled}
      infoId={"infocard.pa.page2.purposeAccordion.purpose"}
      pt={0}
      pb={0}
    />
  );
  const contentBasicEl = <Box>{purposeNameEl}</Box>;

  const onChangeLegalBasis = useCallback(
    legalBasisId => {
      updateCurrentPurpose({ legalBasisId: legalBasisId });
    },
    [updateCurrentPurpose]
  );

  const onFocusLegalBasis = useCallback(() => setInfoId("infocard.pa.page2.purposeAccordion.legalBasis"), [setInfoId]);

  const renderOptionEl = useCallback(
    (resourceId, translatedResource) => (
      <Box my={1} display="flex" justifyContent="center" width="100%">
        <Box flexGrow={1}>{translatedResource}</Box>
        {resourceId === suggestedId && (
          <Box>
            <Tooltip title={t("recommendedLegalBasis")}>
              <StarIcon fontSize="small" style={{ color: "rgb(76, 175, 80)" }} />
            </Tooltip>
          </Box>
        )}
      </Box>
    ),
    [suggestedId, t]
  );
  const contentExpertEl = (
    <Box mt={1} mb={3}>
      <Grid container>
        <Grid item xs={6}>
          <Box mr={2}>{purposeNameEl}</Box>
        </Grid>
        <Grid item xs={6}>
          <Box ml={2}>
            <ResourceField
              label={t("legalBasis:questionTitle")}
              onChange={onChangeLegalBasis}
              value={currentPurpose.legalBasisId}
              resourceType={RESOURCE_TYPES.LEGAL_BASIS}
              multiSelect={false}
              onFocus={onFocusLegalBasis}
              renderOption={renderOptionEl}
              disabled={disabled || !isLegalBasisAllowWrite}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

  const onChangeConditionalFields = useCallback(
    data => {
      updateCurrentPurpose(data);
    },
    [updateCurrentPurpose]
  );
  const renderConditionalFields = useCallback(() => {
    let fields = [];
    switch (currentPurpose.legalBasisId) {
      case "44414090f506cb232d45":
      case "4112f143-2d7b-46ba-bfcc-6c7a8aeb96a1":
      case "c249a22d-11aa-4e88-acb0-e716c0fb2104":
      case "ea7977f3-bfe4-4b61-ad30-f26b7ec70a20":
        fields = ["lbObtainingConsentValue", "lbConsentContentValue", "lbVerificationValue"];
        break;
      case "3c66b8ec02d2584cdfe2":
        fields = ["lbOtherLegalBasisValue"];
        break;
      case "e3e0121e85f97ba0ea62":
      case "346c4294-f759-4089-9738-a7f5c5bd70ec":
      case "44683922-9abb-46dd-b4d3-373b21183754":
        fields = ["lbContractValue"];
        break;
      case "d9a5f4773153a11ac85f":
      case "9f80dae3-e159-4736-b026-804ebfcbb9a5":
        fields = ["lbLegalObligationValue"];
        break;
      case "37ac81056b3c03a00197":
      case "4fe67b70-cd3f-4a2c-9e5c-a24f8abc7cc0":
      case "1fbf3e3f-7911-4d4f-880f-404cffea16ef":
      case "045cc5ee-0708-48ab-a2e7-58fe5ea578fd":
        fields = ["lbVitalInterestsValue"];
        break;
      case "6dd236adea54334de3a4":
      case "1376e36a-d3d1-486a-b51b-14ddc475f92c":
        fields = ["lbControllerInterestValue", "lbInterestOfTheDataSubjectValue", "lbInterestBalancingValue"];
        break;
      case "e30f6ab21dbfd277da99":
      case "32318996-e72a-48d7-9474-a3c3f9b7e5d4":
      case "7e8e834c-16ec-40f7-96d6-c584b363d5bd":
      case "d874b2de-0849-42a7-8b35-5833973194d3":
        fields = ["lbPublicInterestValue"];
        break;
      default:
        fields = ["lbDefaultValue"];
        break;
    }

    return fields.map(field => (
      <PurposeConditionalTextField
        key={field}
        legalBasisId={currentPurpose.legalBasisId}
        propertyName={field}
        value={currentPurpose[field as keyof Purpose]}
        onChange={onChangeConditionalFields}
        disabled={disabled || !isLegalBasisAllowWrite}
      />
    ));
  }, [currentPurpose, onChangeConditionalFields, disabled, isLegalBasisAllowWrite]);

  const renderConditionalFieldsEl = renderConditionalFields();

  const onClickDelete = useCallback(() => onDelete(purpose.id), [onDelete, purpose.id]);
  const onClickSave = useCallback(() => {
    onSave(currentPurpose);
    setDirty(false);
  }, [currentPurpose, onSave]);
  const onClickCancel = useCallback(() => {
    setCurrentPurpose(purpose);
    setDirty(false);
  }, [purpose]);

  return (
    <AccordionMultiField
      id={"purposes " + currentPurpose.id}
      key={currentPurpose.id}
      field={"purposes"}
      title={titleEl}
      hasCancelAndSave={true}
      onClickSave={onClickSave}
      onClickCancel={onClickCancel}
      disableButton={disabled}
      disableOnlySaveButton={!currentPurpose.purposeDescription}
      isNewMultiFiled={isCreatedNew}
      deleteMultiField={onClickDelete}
      dirty={dirty}
      placeholder={undefined}
      index={undefined}
      titleType={undefined}
      deleteButtonText={undefined}
      deleteButtonHint={undefined}
      cancelButtonText={undefined}
      saveButtonText={undefined}
      onFocus={undefined}
      getStyle={undefined}
      accordionsExpanded={undefined}
      setAccordionsExpanded={undefined}
      editButtonText={undefined}
      onClickEdit={undefined}
      additionalLeftButton={undefined}
      loading={undefined}
    >
      {isLegalBasisReadOnly ? contentExpertEl : contentBasicEl}
      {isLegalBasisReadOnly && renderConditionalFieldsEl}
    </AccordionMultiField>
  );
};

export default PurposeAccordion;
