import { useCallback } from "react";
import overviewBaseController, {
  OverviewController,
  OverviewNewItem,
  OverviewResult,
  OverviewSetup
} from "components/Overview/controllers/overviewBaseController";
import { COLLECTIONS } from "app/collections";
import { useTranslation } from "react-i18next";
import {
  getLabelsFilter,
  getOrganizationFilter,
  getProcessTaskFilter,
  getTomProtectionObjectiveFilter,
  getTomStatusFilter
} from "app/pages/shared/Filters/filters";
import { AxiosInstance, AxiosResponse } from "axios";
import { createOverviewItemDefaultName } from "app/utils/create-overview-item-default-name";
import { resourcesDecorator } from "components/Overview/controllers/decorator/resourcesDecorator";
import { tDeletedEntry } from "app/handlers/dataTypeTranslatorHandler";
import { departmentsDecorator } from "../../../../components/Overview/controllers/decorator/departmentsDecorator";
import { usePageContextRefresher } from "../../shared/PageTemplate/PageTemplate";
import i18n from "app/i18n";

export interface TomOverviewCollectionParams {
  readonly exportToXLSX: (ids: string[]) => Promise<void>;
  readonly exportAllToPdf: (ids: string[]) => Promise<void>;
  readonly exportToPdf: (ids: string[]) => Promise<void>;
  readonly exportToAttachments: (ids: string[], idToName: Map<string, string>) => Promise<void>;
}

const TomOverviewController = (
  axiosInstance: AxiosInstance,
  collectionParams: TomOverviewCollectionParams
): OverviewController => {
  const { t } = useTranslation();
  const { t: tFilter } = useTranslation("filter_criteria");
  const translateItem = useCallback(
    obj => ({ ...obj, subTitle: obj.subTitle === "deleted" ? tDeletedEntry({ t }) : obj.subTitle }),
    [t]
  );
  const baseController = overviewBaseController(axiosInstance, COLLECTIONS.TOM, translateItem, [
    resourcesDecorator,
    departmentsDecorator
  ]);
  const getCurrentPage = () => window.location.pathname.split("/").pop();
  const reloadPageContext = usePageContextRefresher();

  const getFilters = useCallback(
    (overviewResult: OverviewResult) => [
      getOrganizationFilter("allOrgUnitIds", overviewResult._departments, t, "department"),
      getOrganizationFilter("orgUnitId", overviewResult._departments, t, "affectedDepartment"),
      getOrganizationFilter("furtherOrgUnitIds", overviewResult._departments, t, "furtherDepartments"),
      getTomStatusFilter("status", t),
      getLabelsFilter("labelIds", overviewResult._resources, t),
      getProcessTaskFilter("openTasks", t),
      getTomProtectionObjectiveFilter("protectionObjectiveIds", overviewResult._resources, t)
    ],
    [t]
  );

  const getSortings = useCallback(
    () => [
      {
        field: "title",
        type: "asc",
        label: tFilter("aToZ")
      },
      {
        field: "title",
        type: "desc",
        label: tFilter("zToA")
      },
      {
        field: "createdAt",
        type: "desc",
        label: tFilter("newFirst")
      },
      {
        field: "createdAt",
        type: "asc",
        label: tFilter("oldFirst")
      }
    ],
    [tFilter]
  );

  const getOverview = async (setup: OverviewSetup) => {
    const isProcessSpecific = getCurrentPage() === "general" ? "" : "process-specific";
    const data = await baseController.getOverview(setup, `/overview/${isProcessSpecific}`, {
      headers: { "Accept-Language": i18n.language }
    });
    if (!data) {
      return null;
    }
    return {
      ...data,
      filters: getFilters(data),
      sortings: getSortings()
    };
  };

  const goToItem = (id: string) => {
    baseController.goToItem(`/toms/${id}/description`);
  };

  const addItem = async (data: OverviewNewItem): Promise<AxiosResponse> => {
    const isProcessSpecific = getCurrentPage() !== "general";
    return await baseController.addItem({
      name: data.title,
      processSpecific: isProcessSpecific
    });
  };

  const addItemAndGo = async (data: OverviewNewItem) => {
    const isProcessSpecific = getCurrentPage() !== "general";
    const response = await baseController.addItem({
      name: data?.title || createOverviewItemDefaultName(t("measures")),
      processSpecific: isProcessSpecific
    });
    goToItem(response.headers["x-resource-id"] || "");
  };

  const deleteItem = async (id: string): Promise<void> => {
    await baseController.deleteItem(id);
  };

  const patchItem = async (id: string, data: object) => {
    const title = ("title" in data && (data.title as string)) || "";
    return await baseController.patchItem(id, {
      name: title
    });
  };

  const exportItems = async (format: string, ids: string[], setup: OverviewSetup) => {
    if (format === "pdf") {
      await collectionParams.exportToPdf(ids);
    } else if (format === "xlsx") {
      await collectionParams.exportToXLSX(ids);
    } else if (format === "attachments") {
      const data = await baseController.getOverview(setup);
      const idToName = (data?.allItems || []).reduce<Map<string, string>>((acc, item) => {
        acc.set(item.id, item.title);
        return acc;
      }, new Map<string, string>());
      await collectionParams.exportToAttachments(ids, idToName);
    }
  };

  const exportAllItems = async (format: string, setup: OverviewSetup) => {
    const data = await baseController.getOverview(setup);
    if (data) {
      const ids = data.allItems.map(({ id }) => id);
      if (format === "pdf") {
        await collectionParams.exportAllToPdf(ids);
      } else if (format === "xlsx") {
        await exportItems(format, ids, setup);
      } else if (format === "attachments") {
        await exportItems(format, ids, setup);
      }
    }
  };

  const addItemsFromTemplates = async (data: { templateIds: string[] }) => {
    const isProcessSpecific = getCurrentPage() !== "general";
    await axiosInstance.post(
      `/overview/templates`,
      {
        templateIds: data.templateIds,
        processSpecific: isProcessSpecific
      },
      {
        headers: { "Accept-Language": i18n.language },
        // create from template can take very long, we should not restrict it
        timeout: 0
      }
    );
    await reloadPageContext();
  };

  return {
    ...baseController,
    getOverview,
    addItemAndGo,
    addItem,
    goToItem,
    patchItem,
    deleteItem,
    exportItems,
    exportAllItems,
    addItemsFromTemplates
  };
};

export default TomOverviewController;
