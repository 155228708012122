import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import DocView from "../../../components/DocView/DocView";
import DocMetaView from "../../../components/DocMetaView/DocMetaView";
import MetaView, { META_VIEW_TABS } from "../../../components/MetaView/MetaView";
import { useMetaView } from "../../contexts/meta-view-context";
import QuestionnaireSubHeader from "../../../components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import { RiskPageButtons, RiskPageStepper } from "./RiskPagination";
import { Box, CircularProgress, FormControlLabel, Grid, Radio, RadioGroup } from "@material-ui/core";
import { AttachmentsOverviewOBS } from "../shared/Attachments/AttachmentsOverviewOBS";
import TextBody2 from "../../../components/TextBody2/TextBody2";
import { useRisk } from "../../contexts/risk-context";
import { connectedMeasuresByProtectionObjectiveInRisk, tenantRiskId } from "../../handlers/risksHandler";
import { useTom } from "../../contexts/tom-context";
import Question from "components/Question/Question";
import { COLLECTIONS } from "../../collections";
import { QUESTION_TYPE } from "../../../components/Question/QuestionTypes";

const metaViewTabIds = [META_VIEW_TABS.ASSISTANT, META_VIEW_TABS.TODOS, META_VIEW_TABS.COMMENTS];

export const RiskGeneralPage = () => {
  const { t } = useTranslation("risk_general_page");
  const { risk, initialized } = useRisk();

  return (
    <DocMetaView
      loading={!initialized}
      metaViewContent={
        <MetaView
          translationKey={"risk_general_page"}
          docName={risk?.title || ""}
          docId={risk?.id || ""}
          collection={COLLECTIONS.RISK}
          tabs={metaViewTabIds}
        />
      }
    >
      <DocView header={risk?.title || ""} pagination={<RiskPageStepper />}>
        <Box mt={4} />
        <QuestionnaireSubHeader text={t("subTitle")} />
        <RiskGeneral />
        <Box mt={4} />
        <RiskPageButtons leftArea={undefined} />
      </DocView>
    </DocMetaView>
  );
};

export const RiskGeneral = () => {
  const { t } = useTranslation("risk_general_page");

  const { risk, updateBasicInfoHook, riskId, initialized } = useRisk();
  const { toms, tomInitiated } = useTom();
  const { setInfo } = useMetaView();

  const infoCard = useMemo(
    () => ({
      entered: {
        title: t("risk_general_page:enteringInfoCardTitle"),
        text: t("risk_general_page:enteringInfoCardText")
      },
      title: {
        title: t("titleField"),
        text: t("riskTitleCard")
      },
      description: {
        title: t("generalDescription"),
        text: t("riskDescriptionCard")
      },
      riskOwner: {
        title: t("riskOwner"),
        text: t("riskOwnerInfoCard")
      },
      orgUnitId: {
        title: t("responsible_department"),
        text: t("riskOrgUnitInfoCard")
      },
      furtherAffectedOrgUnitIds: {
        title: t("furtherAffectedOrgUnit"),
        text: t("riskFurtherAffectedOrgUnitInfoCard")
      },
      dataLocationIds: {
        title: t("dataLocationIds"),
        text: t("affectedDataLocationsCard")
      },
      protectionObjectiveIds: {
        title: t("protectionObjectiveIds"),
        text: t("protectionObjectivesCard")
      },
      labelIds: {
        title: t("labelIds"),
        text: t("labelIdsCard")
      }
    }),
    [t]
  );

  const setEnteredInfo = useCallback(() => {
    setInfo(infoCard.entered);
  }, [infoCard.entered, setInfo]);

  useEffect(() => {
    setEnteredInfo();
  }, [setEnteredInfo]);

  /* TITLE */
  const onChangeTitle = useCallback(
    (title: string) => {
      return updateBasicInfoHook({ ...risk, title });
    },
    [risk, updateBasicInfoHook]
  );
  /* OWNER ID */
  const onChangeRiskOwnerId = useCallback(
    (ids: string[]) => {
      return updateBasicInfoHook({ ...risk, ownerUID: ids[0] || "" });
    },
    [risk, updateBasicInfoHook]
  );
  /* ORG UNIT ID */
  const onChangeOrgUnitId = useCallback(
    (orgUnitId: string) => {
      return updateBasicInfoHook({ ...risk, orgUnitId });
    },
    [risk, updateBasicInfoHook]
  );
  /* FURTHER ORG UNIT ID */
  const onChangeFurtherAffectedOrgUnitIds = useCallback(
    (furtherAffectedOrgUnitIds: string[]) => {
      return updateBasicInfoHook({ ...risk, furtherAffectedOrgUnitIds });
    },
    [risk, updateBasicInfoHook]
  );
  /* DESCRIPTION */
  const onChangeDescription = useCallback(
    (description: string) => {
      return updateBasicInfoHook({ ...risk, description });
    },
    [risk, updateBasicInfoHook]
  );
  /* PRIVACY RELEVANT */
  const onChangePrivacyRelevant = useCallback(
    event => {
      const privacyRelevant = event.target.value === "yes";
      return updateBasicInfoHook({ ...risk, privacyRelevant });
    },
    [risk, updateBasicInfoHook]
  );
  /* DATA LOCATION IDS */
  const onChangeDataLocationIds = useCallback(
    (dataLocationIds: string[]) => {
      return updateBasicInfoHook({ ...risk, dataLocationIds });
    },
    [risk, updateBasicInfoHook]
  );
  /* PROTECTION OBJECTIVE IDS */
  const isProtectionObjectiveNotRemovable = useCallback(
    (protectionObjectiveId: string) => {
      const connectedMeasures = connectedMeasuresByProtectionObjectiveInRisk(protectionObjectiveId, risk as any, toms);
      return Array.isArray(connectedMeasures) && !!connectedMeasures.length;
    },
    [risk, toms]
  );
  const protectionObjectiveNotRemovableTooltip = useCallback(
    (protectionObjectiveId: string) => {
      const connectedMeasures = connectedMeasuresByProtectionObjectiveInRisk(protectionObjectiveId, risk as any, toms);
      return `${t("stillUsedInMeasures")}:\n${connectedMeasures.map(measure => `- ${measure?.name || ""}`).join("\n")}`;
    },
    [risk, t, toms]
  );
  const onChangeProtectionObjectiveIds = useCallback(
    (protectionObjectiveIds: string[]) => {
      return updateBasicInfoHook({ ...risk, protectionObjectiveIds });
    },
    [risk, updateBasicInfoHook]
  );

  /* LABEL IDS */
  const onChangeLabelIds = useCallback(
    (labelIds: string[]) => {
      return updateBasicInfoHook({ ...risk, labelIds });
    },
    [risk, updateBasicInfoHook]
  );

  if (!initialized || !tomInitiated) {
    return (
      <Grid container justifyContent="center" spacing={3}>
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Box>
        <Box display="flex">
          <Box flex={1} mr={3}>
            <Question
              translatable={risk?.title || ""}
              questionId={"title"}
              questionName={t("titleField")}
              qType={QUESTION_TYPE.TEXT_AREA}
              value={risk?.title || ""}
              onChange={onChangeTitle}
              onBlur={setEnteredInfo}
              info={infoCard.title}
            />
          </Box>
          <Box flex={1} ml={3}>
            <Question
              qType={QUESTION_TYPE.TEXT_AREA}
              disabled={true}
              questionName={t("tenantRiskId")}
              value={risk ? tenantRiskId(risk as any) : ""}
            />
          </Box>
        </Box>
        <Question
          qType={QUESTION_TYPE.ASSIGNED_TO_USER}
          orgUnitIds={risk ? [risk.orgUnitId, ...(risk.furtherAffectedOrgUnitIds || [])] : []}
          questionId={"riskOwner"}
          questionName={t("riskOwner")}
          value={risk?.ownerUID || ""}
          onChange={onChangeRiskOwnerId}
          onBlur={setEnteredInfo}
          info={infoCard.riskOwner}
          assignType={"riskOwner"}
        />
        <Question
          qType={QUESTION_TYPE.RESPONSIBLE_ORG_UNIT}
          questionId={"responsible_department"}
          questionName={t("responsible_department")}
          value={risk?.orgUnitId || ""}
          onChange={onChangeOrgUnitId}
          onBlur={setEnteredInfo}
          info={infoCard.orgUnitId}
        />
        <Question
          qType={QUESTION_TYPE.FURTHER_ORG_UNITS}
          questionId={"furtherAffectedOrgUnit"}
          questionName={t("furtherAffectedOrgUnit")}
          value={risk?.furtherAffectedOrgUnitIds || []}
          onChange={onChangeFurtherAffectedOrgUnitIds}
          onBlur={setEnteredInfo}
          info={infoCard.furtherAffectedOrgUnitIds}
        />
        <Question
          qType={QUESTION_TYPE.TEXT_EDITOR}
          translatable={risk?.description || ""}
          questionId={"generalDescription"}
          questionName={t("generalDescription")}
          value={risk?.description || ""}
          onChange={onChangeDescription}
          onBlur={setEnteredInfo}
          info={infoCard.description}
        />
        <Question questionId={"privacyRelevant"} questionName={t("privacyRelevant")}>
          <TextBody2 text={t("privacyRelevant")} />
          <RadioGroup
            row
            name="privacyRelevant"
            value={risk?.privacyRelevant === null ? null : risk?.privacyRelevant ? "yes" : "no"}
            onChange={onChangePrivacyRelevant}
          >
            <FormControlLabel label={t("common:yes")} value={"yes"} control={<Radio color="primary" />} />
            <FormControlLabel label={t("common:no")} value={"no"} control={<Radio color="primary" />} />
          </RadioGroup>
        </Question>
        <Question
          qType={QUESTION_TYPE.DATA_SOURCE}
          questionId={"dataLocationIds"}
          questionName={t("dataLocationIds")}
          value={risk?.dataLocationIds || []}
          onChange={onChangeDataLocationIds}
          onBlur={setEnteredInfo}
          info={infoCard.dataLocationIds}
        />
        <Question
          qType={QUESTION_TYPE.PROTECTION_OBJECTIVE}
          questionId={"protectionObjectiveIds"}
          questionName={t("protectionObjectiveIds")}
          value={risk?.protectionObjectiveIds || []}
          onChange={onChangeProtectionObjectiveIds}
          onBlur={setEnteredInfo}
          isNotDeletableChip={isProtectionObjectiveNotRemovable}
          notDeletableChipTooltip={protectionObjectiveNotRemovableTooltip}
          info={infoCard.protectionObjectiveIds}
        />

        <Question
          qType={QUESTION_TYPE.LABELS}
          questionId={"labels"}
          questionName={t("labels")}
          value={risk?.labelIds || []}
          onChange={onChangeLabelIds}
          onBlur={setEnteredInfo}
          info={infoCard.labelIds}
        />
        <AttachmentsOverviewOBS docId={riskId || ""} category={"risks"} />
      </Box>
    );
  }
};
