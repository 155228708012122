const countriesJSONDB = [
  {
    id: "germany",
    eu: true,
    adequacyDecision: false
  },
  {
    id: "austria",
    eu: true,
    adequacyDecision: false
  },
  {
    id: "switzerland",
    eu: false,
    adequacyDecision: true
  },
  {
    id: "unitedStates",
    eu: false,
    adequacyDecision: true
  },
  {
    id: "unitedKingdom",
    eu: false,
    adequacyDecision: true
  },
  {
    id: "belgium",
    eu: true,
    adequacyDecision: false
  },
  {
    id: "netherlands",
    eu: true,
    adequacyDecision: false
  },
  {
    id: "luxembourg",
    eu: true,
    adequacyDecision: false
  },
  {
    id: "abkhazia",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "afghanistan",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "egypt",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "albania",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "algeria",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "andorra",
    eu: false,
    adequacyDecision: true
  },
  {
    id: "angola",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "antigua",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "equatorialGuinea",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "argentina",
    eu: false,
    adequacyDecision: true
  },
  {
    id: "armenia",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "republicOfArtsakh",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "azerbaijan",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "ethiopia",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "australia",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "bahamas",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "bahrain",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "bangladesh",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "barbados",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "belize",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "benin",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "bhutan",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "bolivia",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "bosniaAndHerzegovina",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "botswana",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "brazil",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "bruneiDarussalam",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "bulgaria",
    eu: true,
    adequacyDecision: false
  },
  {
    id: "burkinaFaso",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "burundi",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "chile",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "taiwan",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "china",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "cookIslands",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "costaRica",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "denmark",
    eu: true,
    adequacyDecision: false
  },
  {
    id: "dominica",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "dominicanRepublic",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "djibouti",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "ecuador",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "elSalvador",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "ivoryCoast",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "eritrea",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "estonia",
    eu: true,
    adequacyDecision: false
  },
  {
    id: "eswatini",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "faroeIslands",
    eu: false,
    adequacyDecision: true
  },
  {
    id: "fiji",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "finland",
    eu: true,
    adequacyDecision: false
  },
  {
    id: "france",
    eu: true,
    adequacyDecision: false
  },
  {
    id: "gabon",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "gambia",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "georgia",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "ghana",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "grenada",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "greece",
    eu: true,
    adequacyDecision: false
  },
  {
    id: "guatemala",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "guernsey",
    eu: false,
    adequacyDecision: true
  },
  {
    id: "guinea",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "guinea-Bissau",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "guyana",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "haiti",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "honduras",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "hongkong",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "india",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "indonesia",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "iraq",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "iran",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "ireland",
    eu: true,
    adequacyDecision: false
  },
  {
    id: "iceland",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "isleOfMan",
    eu: false,
    adequacyDecision: true
  },
  {
    id: "israel",
    eu: false,
    adequacyDecision: true
  },
  {
    id: "italy",
    eu: true,
    adequacyDecision: false
  },
  {
    id: "iamaica",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "japan",
    eu: false,
    adequacyDecision: true
  },
  {
    id: "yemen",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "jersey",
    eu: false,
    adequacyDecision: true
  },
  {
    id: "jordan",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "cambodia",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "cameroon",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "canada",
    eu: false,
    adequacyDecision: true
  },
  {
    id: "capeVerde",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "kazakhstan",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "qatar",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "kenya",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "kyrgyzstan",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "kiribati",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "colombia",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "comoros",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "congo",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "northKorea",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "southKorea",
    eu: false,
    adequacyDecision: true
  },
  {
    id: "kosovo",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "croatia",
    eu: true,
    adequacyDecision: false
  },
  {
    id: "cuba",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "kuwait",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "lao",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "lesotho",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "latvia",
    eu: true,
    adequacyDecision: false
  },
  {
    id: "lebanon",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "liberia",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "libya",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "liechtenstein",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "lithuania",
    eu: true,
    adequacyDecision: false
  },
  {
    id: "madagascar",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "malawi",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "malaysia",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "maldives",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "mali",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "malta",
    eu: true,
    adequacyDecision: false
  },
  {
    id: "morocco",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "marshallIslands",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "mauritania",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "mauritius",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "mexico",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "micronesia",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "moldova",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "monaco",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "mongolia",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "montenegro",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "mozambique",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "MyanmarBurma",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "namibia",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "nauru",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "nepal",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "newZealand",
    eu: false,
    adequacyDecision: true
  },
  {
    id: "nicaragua",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "niger",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "nigeria",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "niue",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "northMacedonia",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "turkishRepublicOfNorthernCyprus",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "norway",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "oman",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "eastTimorTimorLeste",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "pakistan",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "palestine",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "palau",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "panama",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "papuaNewGuinea",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "paraguay",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "peru",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "philippines",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "poland",
    eu: true,
    adequacyDecision: false
  },
  {
    id: "portugal",
    eu: true,
    adequacyDecision: false
  },
  {
    id: "rwanda",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "romania",
    eu: true,
    adequacyDecision: false
  },
  {
    id: "russia",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "solomonIslands",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "zambia",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "samoa",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "sanMarino",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "saoTomeAndPrincipe",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "saudiArabia",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "sweden",
    eu: true,
    adequacyDecision: false
  },
  {
    id: "senegal",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "serbia",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "seychelles",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "sierraLeone",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "zimbabwe",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "singapore",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "slovakia",
    eu: true,
    adequacyDecision: false
  },
  {
    id: "slovenia",
    eu: true,
    adequacyDecision: false
  },
  {
    id: "somalia",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "somaliland",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "spain",
    eu: true,
    adequacyDecision: false
  },
  {
    id: "sriLanka",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "saintKitts",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "nevis",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "saintLucia",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "saintVincent",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "grenadines",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "southAfrica",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "sudan",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "southOssetia",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "southSudan",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "suriname",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "syria",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "tajikistan",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "tanzania",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "thailand",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "togo",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "tonga",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "transnistria",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "trinidadAndTobago",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "chad",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "czechRepublic",
    eu: true,
    adequacyDecision: false
  },
  {
    id: "tunisia",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "turkey",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "turkmenistan",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "tuvalu",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "uganda",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "ukraine",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "hungary",
    eu: true,
    adequacyDecision: false
  },
  {
    id: "uruguay",
    eu: false,
    adequacyDecision: true
  },
  {
    id: "uzbekistan",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "vanuatu",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "vaticancity",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "venezuela",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "unitedArabEmirates",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "vietnam",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "belarus",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "westernSahara",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "centralAfricanRepublic",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "cyprus",
    eu: true,
    adequacyDecision: false
  }
];

const areasJSONDB = [
  {
    id: "worldwide",
    eu: false,
    adequacyDecision: false
  },
  {
    id: "eu",
    eu: true,
    adequacyDecision: false
  }
];

const countryAndAreaMap = [...countriesJSONDB, ...areasJSONDB].reduce(
  (countryIdOrAreaIdToCountryOrAreaMap, countryOrArea) =>
    countryIdOrAreaIdToCountryOrAreaMap.set(countryOrArea.id, countryOrArea),
  new Map()
);

const copyObject = obj => {
  if (!obj) {
    return obj;
  }

  return { ...obj };
};

const getCountries = () => {
  return [...countriesJSONDB.map(copyObject)];
};

const getCountriesAndAreas = () => {
  return [...countriesJSONDB, ...areasJSONDB].map(copyObject);
};

const getAreas = () => {
  return [...areasJSONDB].map(copyObject);
};

const getCountryOrArea = countryOrAreaId => {
  return copyObject(countryAndAreaMap.get(countryOrAreaId));
};

const isEUCountryOrArea = countryOrAreaId => {
  return !!getCountryOrArea(countryOrAreaId)?.eu;
};

const isEUPlusAdequacyDecisionCountryOrArea = countryOrAreaId => {
  const countryOrArea = getCountryOrArea(countryOrAreaId);
  return !!countryOrArea?.eu || !!countryOrArea?.adequacyDecision;
};

export {
  getCountries,
  getCountriesAndAreas,
  getAreas,
  isEUCountryOrArea,
  isEUPlusAdequacyDecisionCountryOrArea,
  getCountryOrArea
};
