import { apiEndpoints } from "./apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "./axios/loggedInAxiosProvider";
import { isAxiosErrorWithCode } from "./axios/axiosErrorHandler";
import i18n from "app/i18n";

export const axiosInstance = defaultOTCAuthenticatedAxios({
  baseURL: `${apiEndpoints.dataBreachUrl}/api/databreaches`
});

export async function updateDataBreachApi(
  id: string,
  {
    title,
    orgUnitId,
    summary,
    cause,
    detectionType,
    occurredAt,
    detectedAt,
    dueAt,
    status,
    assigneeUID,
    dpoUID,
    impact,
    personGroupIds,
    dataTypeIds,
    numberDataSubjects,
    numberDataSets,
    measuresTaken,
    measuresToTake,
    reportIncident,
    noReportReason,
    dataSubjectInformed,
    labelIds,
    furtherOrgUnitIds,
    incidentCauseIds,
    incidentImpactIds,
    measureTakenIds
  }: DataBreachUpdateDTO = {}
): Promise<DataBreachDTO> {
  const response = await axiosInstance.patch<DataBreachDTO>(`/` + id, {
    title,
    orgUnitId,
    summary,
    cause,
    detectionType,
    occurredAt,
    detectedAt,
    dueAt,
    status,
    assigneeUID,
    dpoUID,
    impact,
    personGroupIds,
    dataTypeIds,
    numberDataSubjects,
    numberDataSets,
    measuresTaken,
    measuresToTake,
    reportIncident,
    noReportReason,
    dataSubjectInformed,
    labelIds,
    furtherOrgUnitIds,
    incidentCauseIds,
    incidentImpactIds,
    measureTakenIds
  });

  return response.data;
}

export async function updateControllerApi(
  dataBreachId: string,
  controllerId: string,
  { name, companyName, street, zip, city, phone, fax, email, website }: ControllerUpdateDTO = {}
): Promise<ControllerDTO> {
  const response = await axiosInstance.patch<ControllerDTO>(`/${dataBreachId}/controllers/${controllerId}`, {
    name,
    companyName,
    street,
    zip,
    city,
    phone,
    fax,
    email,
    website
  });

  return response.data;
}

export const getDataBreachesApi = async (
  filter: DataBreachesFilterParameter
): Promise<{ readonly dataBreaches: DataBreachDTO[] }> => {
  const response = await axiosInstance.get(`/`, { params: filter });
  return response.data || null;
};

export const getDatabreachExports = async (params: { readonly dataBreachIDs: string[] }): Promise<Blob> => {
  const response = await axiosInstance.post(
    `/overview/exports`,
    {
      dataBreachIDs: params.dataBreachIDs
    },
    {
      headers: {
        "Accept-Language": i18n.language || "en-US"
      },
      responseType: "blob",
      timeout: 300000 // 5 minutes
    }
  );
  return new Blob([response.data]);
};

export async function updateDPOApi(
  dataBreachId: string,
  dpoId: string,
  { name, companyName, street, zip, city, phone, fax, email, website }: DPOUpdateDTO = {}
): Promise<DPODTO> {
  const response = await axiosInstance.patch<DPODTO>(`/${dataBreachId}/dpos/${dpoId}`, {
    name,
    companyName,
    street,
    zip,
    city,
    phone,
    fax,
    email,
    website
  });

  return response.data;
}

export async function updateDPAApi(
  dataBreachId: string,
  dpaId: string,
  { name, street, zip, city }: DPAUpdateDTO = {}
): Promise<DPADTO> {
  const response = await axiosInstance.patch<DPADTO>(`/${dataBreachId}/dpas/${dpaId}`, {
    name,
    street,
    zip,
    city
  });

  return response.data;
}

export async function getDataBreachByIdFromApi(id: string): Promise<DataBreachDTO | null> {
  try {
    const response = await axiosInstance.get(`/${id}`).catch(error => error);
    return response ? response.data : null;
  } catch (error: any) {
    if (isAxiosErrorWithCode(error, 404)) {
      // 404 is not found, just return null to determine that the data breach does not exists
      return null;
    }
    throw error;
  }
}

export async function getControllerByDataBreachIdFromApi(id: string): Promise<ControllerDTO | null> {
  try {
    const response = await axiosInstance.get(`/${id}/controller`);
    return response ? response.data : null;
  } catch (error: any) {
    if (isAxiosErrorWithCode(error, 404)) {
      // 404 is not found, just return null to determine that the data breach does not exists
      return null;
    }
    throw error;
  }
}

export async function getDPAByDataBreachIdFromApi(id: string): Promise<DPADTO | null> {
  try {
    const response = await axiosInstance.get(`/${id}/dpa`).catch(error => error);
    return response ? response.data : null;
  } catch (error: any) {
    if (isAxiosErrorWithCode(error, 404)) {
      // 404 is not found, just return null to determine that the data breach does not exists
      return null;
    }
    throw error;
  }
}

export async function getDPOByDataBreachIdFromApi(id: string): Promise<DPODTO | null> {
  try {
    const response = await axiosInstance.get("/" + id + "/dpo").catch(error => error);
    return response ? response.data : null;
  } catch (error: any) {
    if (isAxiosErrorWithCode(error, 404)) {
      // 404 is not found, just return null to determine that the data breach does not exists
      return null;
    }
    throw error;
  }
}

export async function downloadDataBreachNotificationWordDocumentFromAPI(
  dataBreachId: string,
  resourceTree: object[],
  detectionTypeLabel: string,
  language: string
) {
  const response = await axiosInstance.post(
    "/" + dataBreachId + "/notificationDocument",
    {
      resourceTree,
      detectionTypeLabel
    },
    {
      headers: { "Accept-Language": language },
      responseType: "blob"
    }
  );

  return {
    data: new Blob([response.data]),
    fileName: getFileNameFromContentDispositionHeader(response.headers["content-disposition"])
  };
}

const getFileNameFromContentDispositionHeader = (header: string): string => {
  return header.split(";")[1].split("=")[1].replace('"', "").replace('"', "");
};

export async function getDataBreachStatsApi(orgUnitId?: string, labelId?: string) {
  const response = await axiosInstance.get(`/stats`, {
    params: {
      orgUnitId,
      labelId
    }
  });
  return response.data.stats || [];
}

export interface DataBreachDTO {
  readonly id: string;
  readonly incId: string;
  readonly tenantId: string;
  readonly title: string | null;
  readonly orgUnitId: string | null;
  readonly summary: string | null;
  readonly cause: string | null;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly occurredAt: Date | null;
  readonly detectedAt: Date | null;
  readonly dueAt: Date | null;
  readonly detectionType: string | null;
  readonly creatorUID: string;
  readonly assigneeUID: string | null;
  readonly dpoUID: string | null;
  readonly status: DataBreachStatus;
  readonly impact: string | null;
  readonly measuresTaken: string | null;
  readonly measuresToTake: string | null;
  readonly reportIncident: boolean | null;
  readonly noReportReason: string | null;
  readonly dataSubjectInformed: boolean | null;
  readonly personGroupIds: string[];
  readonly dataTypeIds: string[];
  readonly numberDataSubjects: number | null;
  readonly numberDataSets: number | null;
  readonly labelIds: string[];
  readonly furtherOrgUnitIds: string[];
  readonly incidentCauseIds: string[];
  readonly incidentImpactIds: string[];
  readonly measureTakenIds: string[];
  readonly openTasks: string;
}

export type DataBreachUpdateDTO = Partial<Omit<DataBreachDTO, "id" | "tenantId" | "incId">>;

export type DataBreachStatus = "OPEN" | "IN_PROGRESS" | "PENDING_FEEDBACK" | "CLOSED" | "DELETED";

export interface ControllerDTO {
  readonly id: string;
  readonly tenantId: string;
  readonly dataBreachId: string;
  readonly name: string | null;
  readonly companyName: string | null;
  readonly street: string | null;
  readonly zip: string | null;
  readonly city: string | null;
  readonly phone: string | null;
  readonly fax: string | null;
  readonly email: string | null;
  readonly website: string | null;
}

export type ControllerUpdateDTO = Partial<Omit<ControllerDTO, "id" | "tenantId" | "dataBreachId">>;

export interface DPADTO {
  readonly id: string;
  readonly tenantId: string;
  readonly dataBreachId: string;
  readonly name: string | null;
  readonly street: string | null;
  readonly zip: string | null;
  readonly city: string | null;
}

export type DPAUpdateDTO = Partial<Omit<DPADTO, "id" | "tenantId" | "dataBreachId">>;

export interface DPODTO {
  readonly id: string;
  readonly tenantId: string;
  readonly dataBreachId: string;
  readonly name: string | null;
  readonly companyName: string | null;
  readonly street: string | null;
  readonly zip: string | null;
  readonly city: string | null;
  readonly phone: string | null;
  readonly fax: string | null;
  readonly email: string | null;
  readonly website: string | null;
}

export type DPOUpdateDTO = Partial<Omit<DPODTO, "id" | "tenantId" | "dataBreachId">>;

export interface DataBreachesFilterParameter {
  readonly dataBreachIds?: string[];
  readonly orgUnitIds?: string[];
  readonly incidentCauseIds?: string[];
  readonly incidentImpactIds?: string[];
  readonly measureTakenIds?: string[];
}
