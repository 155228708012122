import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMetaView } from "../../../contexts/meta-view-context";
import QuestionnaireSubHeader from "components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import Question from "components/Question/Question";
import { AttachmentsOverviewOBS } from "../../shared/Attachments/AttachmentsOverviewOBS";
import { RESOURCE_TYPES } from "app/handlers/resourceHandler";
import { useGetAssetApi, useUpdateAssetApi } from "../../../api/assetApi";
import { useUpdateQueues } from "../../../../hook/useUpdateQueues";
import { UpdateAssetDTO } from "../../../api/generated/asset-service";
import { QUESTION_TYPE } from "../../../../components/Question/QuestionTypes";
import { Box } from "@mui/material";
import { useAuthentication } from "../../../handlers/authentication/authentication-context";

const AssetsDetails = ({ readonly, id }: { readonly readonly?: boolean; readonly id: string }) => {
  const { data: asset } = useGetAssetApi({ documentId: id || "" });
  const { updateAsset } = useUpdateAssetApi({ documentId: id || "" });
  const { queueUpdates } = useUpdateQueues<UpdateAssetDTO, UpdateAssetDTO>({
    triggerUpdate: updateAsset
  });

  const { auth } = useAuthentication();

  const { t } = useTranslation("asset_details");
  const { setInfo } = useMetaView();

  const infoCard = useMemo(
    () => ({
      entering: {
        title: t("enteringInfoCardTitle"),
        text: t("enteringInfoCardText")
      }
    }),
    [t]
  );
  useEffect(() => {
    setInfo(infoCard.entering);
  }, [infoCard, setInfo]);

  const [description, setDescription] = useState<string>("");
  const [resourceAssetTypeId, setResourceAssetTypeId] = useState<string>("");
  const [amount, setAmount] = useState<number>(0);
  const [assetOwnerId, setAssetOwnerId] = useState<string>("");
  const [riskOwnerId, setRiskOwnerId] = useState<string>("");
  const [mainOrgUnitId, setMainOrgUnitId] = useState<string>("");
  const [furtherOrgUnitIds, setFurtherOrgUnitIds] = useState<string[]>([]);
  const docOrgUnitIds = useMemo(
    () => [asset?.mainOrgUnitId, ...(asset?.orgUnitIds || [])].filter((it): it is string => !!it),
    [asset]
  );
  const [labelIds, setLabelIds] = useState<string[]>([]);

  useEffect(() => {
    if (asset) {
      setDescription(asset.description || "");
      setResourceAssetTypeId(asset.resourceAssetTypeId || "");
      setAmount(asset.amount || 0);
      setAssetOwnerId(asset.assetOwnerId || "");
      setRiskOwnerId(asset.riskOwnerId || "");
      setMainOrgUnitId(asset.mainOrgUnitId || auth?.orgUnitId || "");
      setFurtherOrgUnitIds(asset.orgUnitIds || []);
      setLabelIds(asset.labelIds || []);
    }
  }, [asset, auth?.orgUnitId]);

  const onDescriptionChange = useCallback(
    (description: string) => {
      setDescription(description);
      return queueUpdates({ description });
    },
    [queueUpdates]
  );

  const onResourceAssetTypeIdChange = useCallback(
    (resourceAssetTypeId: string) => {
      setResourceAssetTypeId(resourceAssetTypeId);
      return queueUpdates({ resourceAssetTypeId });
    },
    [queueUpdates]
  );

  const onAmountChange = useCallback(
    (amount: number) => {
      setAmount(amount);
      return queueUpdates({ amount });
    },
    [queueUpdates]
  );

  const onAssetOwnerIdChange = useCallback(
    (assetOwnerId: string) => {
      setAssetOwnerId(assetOwnerId);
      return queueUpdates({ assetOwnerId });
    },
    [queueUpdates]
  );

  const onRiskOwnerIdChange = useCallback(
    (riskOwnerId: string) => {
      setRiskOwnerId(riskOwnerId);
      return queueUpdates({ riskOwnerId });
    },
    [queueUpdates]
  );

  const onMainOrgUnitIdChange = useCallback(
    (mainOrgUnitId: string) => {
      setMainOrgUnitId(mainOrgUnitId);
      return queueUpdates({ mainOrgUnitId });
    },
    [queueUpdates]
  );

  const onFurtherOrgUnitIdsChange = useCallback(
    (furtherOrgUnitIds: string[]) => {
      setFurtherOrgUnitIds(furtherOrgUnitIds);
      return queueUpdates({ orgUnitIds: furtherOrgUnitIds });
    },
    [queueUpdates]
  );

  const onLabelIdsChange = useCallback(
    (labelIds: string[]) => {
      setLabelIds(labelIds);
      return queueUpdates({ labelIds });
    },
    [queueUpdates]
  );

  if (!asset) {
    return <></>;
  }

  return (
    <Box>
      <Box>
        <QuestionnaireSubHeader text={t("generalInformation")} />
      </Box>
      <Box>
        <Question
          qType={QUESTION_TYPE.TEXT_EDITOR}
          questionId={"asset-description"}
          translatable={description}
          questionName={t("description")}
          value={description}
          onChange={onDescriptionChange}
          disabled={readonly}
        />
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" gap={6}>
        <Box flex={1}>
          <Question
            qType={QUESTION_TYPE.RESOURCE}
            resourceType={RESOURCE_TYPES.ASSET_TYPE}
            value={resourceAssetTypeId}
            translationId={"resourceAssetTypeId"}
            questionId="resourceAssetTypeId"
            questionName={t("resourceAssetTypeId")}
            onChange={onResourceAssetTypeIdChange}
            multiSelect={false}
            allowAdd={false}
            disabled={readonly}
            orgUnitIds={docOrgUnitIds}
          />
        </Box>
        <Box flex={1}>
          <Question
            qType={QUESTION_TYPE.NUMBER}
            questionId={"amount"}
            questionName={t("amount")}
            value={amount}
            onChange={onAmountChange}
            disabled={readonly}
          />
        </Box>
      </Box>
      <Box>
        <Question
          qType={QUESTION_TYPE.RESPONSIBLE_ORG_UNIT}
          questionId={"mainOrgUnitId"}
          questionName={t("mainOrgUnitId")}
          value={mainOrgUnitId}
          onChange={onMainOrgUnitIdChange}
          disabled={readonly}
        />
      </Box>
      <Box>
        <Question
          qType={QUESTION_TYPE.ORG_UNITS_PICKER}
          questionId={"orgUnitIds"}
          questionName={t("orgUnitIds")}
          value={furtherOrgUnitIds}
          onChange={onFurtherOrgUnitIdsChange}
          disabled={readonly}
        />
      </Box>
      <Question
        qType={QUESTION_TYPE.LABELS}
        questionId={"label"}
        questionName={t("labels:label")}
        value={labelIds}
        onChange={onLabelIdsChange}
        disabled={readonly}
        orgUnitIds={docOrgUnitIds}
      />
      <Box display="flex" justifyContent="space-between" alignItems="center" gap={6}>
        <Box flex={1}>
          <Question
            qType={QUESTION_TYPE.OWNER}
            questionId={"assetOwnerId"}
            questionName={t("assetOwnerId")}
            value={assetOwnerId}
            onChange={onAssetOwnerIdChange}
            disabled={readonly}
          />
        </Box>
        <Box flex={1}>
          <Question
            qType={QUESTION_TYPE.OWNER}
            questionId={"riskOwnerId"}
            questionName={t("riskOwnerId")}
            value={riskOwnerId}
            onChange={onRiskOwnerIdChange}
            disabled={readonly}
          />
        </Box>
      </Box>
      <Question>
        <QuestionnaireSubHeader text={t("common:attachments")} />
        <AttachmentsOverviewOBS docId={asset.id} category={"assets"} disabled={readonly} />
      </Question>
    </Box>
  );
};

export default AssetsDetails;
