import { Box, CircularProgress } from "@material-ui/core";
import { getProcessPageData, patchProcessGeneralPageData } from "app/api/paApi";
import { FEATURES } from "app/features";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import Question from "components/Question/Question";
import { QUESTION_TYPE } from "components/Question/QuestionTypes";
import QuestionnaireSubHeader from "components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import { useIsFeaturePresent } from "hook/useIsFeaturePresent";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useProcessPage } from "app/contexts/process-page-context";
import { sendNotificationApi } from "../../../api/userNotificationApi";
import { useSWRConfig } from "swr";
import { isPAPermissionsKey } from "../usePAPermission";
import { useEnteringInfoCard } from "hook/useEnteringInfoCard";

export interface ProcessGeneralPageModel {
  readonly title: string;
  readonly responsibleOrgUnitId: string | null;
  readonly furtherOrgUnitIds: string[];
  readonly assignedToUserIds: string[];
  readonly dueDate?: string | null;
  readonly implementationDate?: string | null;
  readonly endDate?: string | null;
  readonly responsibleExpertUserIds: string[];
  readonly dpoUID?: string | null;
  readonly labelIds: string[];
}

const ProcessGeneralPage = (props: { readonly readonly?: boolean }) => {
  const { t } = useTranslation("pa_general");
  const { auth } = useAuthentication();
  const { id } = useParams();
  const { onBeforeProcessUpdate, setProcessMeta } = useProcessPage();
  const isImplementationDateAllowed = useIsFeaturePresent(FEATURES.IMPLEMENTATION_DATE);
  const isEndDateAllowed = useIsFeaturePresent(FEATURES.END_DATE);
  const dpoFieldFeature = useIsFeaturePresent(FEATURES.DATA_PROTECTION_OFFICER_FIELD);
  const { mutate: globalMutate } = useSWRConfig();
  useEnteringInfoCard({ pathName: `/processes/${id}/general`, infoId: "infocard.pa.page1" });

  const [model, setModel] = useState<ProcessGeneralPageModel | null>(null);

  useEffect(() => {
    const get = async () => {
      const { processPage, processMeta } = await getProcessPageData({ processId: id || "", page: "general" });
      setModel(processPage);
      setProcessMeta(processMeta);
    };

    if (id) {
      get();
    }
  }, [auth, id, setProcessMeta]);

  const isDirty = useCallback(
    (data: Partial<ProcessGeneralPageModel>): boolean => {
      return Object.keys(data).some(
        (key: string) =>
          model && model[key as keyof ProcessGeneralPageModel] !== data[key as keyof ProcessGeneralPageModel]
      );
    },
    [model]
  );

  const patch = useCallback(
    async (data: Partial<ProcessGeneralPageModel>) => {
      if (id && model && isDirty(data)) {
        await onBeforeProcessUpdate(async () => {
          setModel(model => {
            return model ? { ...model, ...data } : null;
          });
          await patchProcessGeneralPageData({ processId: id, payload: data });
          globalMutate(isPAPermissionsKey);
        });
      }
    },
    [id, isDirty, model, globalMutate, onBeforeProcessUpdate]
  );

  const setTitle = useCallback((title: string) => patch({ title }), [patch]);
  const setResponsibleOrgUnitId = useCallback(
    (responsibleOrgUnitId: string) => patch({ responsibleOrgUnitId: responsibleOrgUnitId }),
    [patch]
  );
  const setFurtherOrgUnitIds = useCallback((furtherOrgUnitIds: string[]) => patch({ furtherOrgUnitIds }), [patch]);
  const setAssignToIds = useCallback(
    async (assignedToUserIds: string[]) => {
      patch({ assignedToUserIds: assignedToUserIds });
      // send notification
      // since we can't do it on server right now
      const notificationObj = {
        title: "process_assignment",
        receivers: assignedToUserIds,
        pageId: "general",
        collection: "processes",
        docId: id || "",
        docName: model?.title || "",
        message: ""
      };
      await sendNotificationApi(notificationObj);
    },
    [id, model?.title, patch]
  );
  const setResponsibleExpertIds = useCallback(
    responsibleExpertUserIds => patch({ responsibleExpertUserIds: responsibleExpertUserIds }),
    [patch]
  );
  const setDpoUID = useCallback(dpoUIDs => patch({ dpoUID: dpoUIDs[0] }), [patch]);
  const setDueDate = useCallback(dueDate => patch({ dueDate }), [patch]);
  const setImplementationDate = useCallback(implementationDate => patch({ implementationDate }), [patch]);
  const setEndDate = useCallback(endDate => patch({ endDate }), [patch]);

  const setLabelIds = useCallback(labelIds => patch({ labelIds }), [patch]);
  const orgUnitIds = useMemo(
    () => (model?.responsibleOrgUnitId ? [model.responsibleOrgUnitId] : []),
    [model?.responsibleOrgUnitId]
  );

  if (!model) {
    return (
      <Box textAlign={"center"} mt={8}>
        <CircularProgress />
      </Box>
    );
  }

  const privacyExpertEl = (
    <Question
      orgUnitIds={orgUnitIds}
      qType={QUESTION_TYPE.PRIVACY_EXPERT_USER}
      questionId={"responsibleExpert"}
      questionName={t("responsible_expert")}
      infoId={"infocard.pa.page1.responsibleExpert"}
      value={model?.responsibleExpertUserIds?.[0] || ""}
      onChange={setResponsibleExpertIds}
      disabled={props.readonly}
    />
  );
  const dpoEl = (
    <Question
      orgUnitIds={orgUnitIds}
      qType={QUESTION_TYPE.DPO_USER}
      questionId={"dpo"}
      infoId={"infocard.pa.page1.dpo"}
      value={model?.dpoUID || ""}
      onChange={setDpoUID}
      disabled={props.readonly}
    />
  );
  const labelsEl = (
    <Question
      qType={QUESTION_TYPE.LABELS}
      questionId={"label"}
      questionName={t("labels:label")}
      infoId={"infocard.pa.page1.labels"}
      value={model?.labelIds || []}
      onChange={setLabelIds}
      disabled={props.readonly}
    />
  );
  return (
    <>
      <QuestionnaireSubHeader text={t("page_title")} />
      <Question
        qType={QUESTION_TYPE.TEXT_AREA}
        questionId={"name"}
        questionName={t("name")}
        infoId={"infocard.pa.page1.title"}
        value={model?.title}
        onChange={setTitle}
        disabled={props.readonly}
      />
      <Question
        qType={QUESTION_TYPE.RESPONSIBLE_ORG_UNIT}
        questionId={"orgUnit"}
        questionName={t("responsible_department")}
        infoId={"infocard.pa.page1.responsibleDepartment"}
        value={model?.responsibleOrgUnitId || ""}
        onChange={setResponsibleOrgUnitId}
        disabled={props.readonly}
      />
      <Question
        qType={QUESTION_TYPE.FURTHER_ORG_UNITS}
        questionId={"assignedOrgUnits"}
        questionName={t("furtherDepartments")}
        infoId={"infocard.pa.page1.furtherDepartments"}
        value={model?.furtherOrgUnitIds}
        onChange={setFurtherOrgUnitIds}
        disabled={props.readonly}
      />
      <Box display="flex" width="100%">
        {isImplementationDateAllowed && (
          <Box mr={3} flex={1}>
            <Question
              qType={QUESTION_TYPE.DATE}
              questionId={"implementationDate"}
              questionName={t("implementationDate")}
              infoId={"infocard.pa.page1.implementationDate"}
              disablePast={false}
              value={model?.implementationDate || undefined}
              onChange={setImplementationDate}
              disabled={props.readonly}
            />
          </Box>
        )}
        {isEndDateAllowed && (
          <Box ml={3} flex={1}>
            <Question
              qType={QUESTION_TYPE.DATE}
              questionId={"endDate"}
              questionName={t("endDate", { optional: "(optional)" })}
              disablePast={false}
              infoId={"infocard.pa.page1.endDate"}
              value={model?.endDate || undefined}
              onChange={setEndDate}
              disabled={props.readonly}
            />
          </Box>
        )}
      </Box>
      <Box display="flex" width="100%">
        <Box mr={3} flex={1}>
          <Question
            orgUnitIds={orgUnitIds}
            qType={QUESTION_TYPE.ASSIGNED_TO_USER}
            questionId={"assignedUser"}
            questionName={t("assigned_to")}
            infoId={"infocard.pa.page1.assignedUser"}
            value={model?.assignedToUserIds?.[0] || ""}
            onChange={setAssignToIds}
            disabled={props.readonly}
          />
        </Box>
        <Box ml={3} flex={1}>
          <Question
            qType={QUESTION_TYPE.DATE}
            questionId={"date"}
            questionName={t("due_date", { optional: "(optional)" })}
            infoId={"infocard.pa.page1.dueDate"}
            value={model?.dueDate || undefined}
            onChange={setDueDate}
            disabled={props.readonly}
            disablePast={true}
          />
        </Box>
      </Box>
      {dpoFieldFeature ? (
        <>
          <Box display="flex" width="100%">
            <Box mr={3} flex={1}>
              {privacyExpertEl}
            </Box>
            <Box ml={3} flex={1}>
              {dpoEl}
            </Box>
          </Box>
          {labelsEl}
        </>
      ) : (
        <>
          <Box display="flex" width="100%">
            <Box mr={3} flex={1}>
              {privacyExpertEl}
            </Box>
            <Box ml={3} flex={1}>
              {labelsEl}
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default ProcessGeneralPage;
