import { Box, Chip, TextField } from "@material-ui/core";
import TextBody2 from "components/TextBody2/TextBody2";
import { useTranslation } from "react-i18next";
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import ConfirmationModal, { ConfirmationModalButtonProps } from "components/ConfirmationModal/ConfirmationModal";
import { DataAssetType, DataCategory, DataType, PersonGroup } from "app/api/generated/asset-service";
import { OverviewItem } from "components/Overview/controllers/overviewBaseController";
import MergeTypeSelector, { MERGE_TYPES, MergeType } from "../../components/MergeTypeSelector";
import { RESOURCE_TYPES } from "app/handlers/resourceHandler";
import { useDataTypeTreeManager } from "app/api/dataAssetApi";
import MultiAutocomplete from "components/MultiAutocomplete/MultiAutocomplete";
import { t } from "i18next";
import { isAxiosErrorWithCode } from "app/api/axios/axiosErrorHandler";
import { useSnackbar } from "notistack";
import { useIsFeaturePresent } from "hook/useIsFeaturePresent";
import { FEATURES } from "app/features";
import PersonGroupsMergeTranslations from "./PersonGroupsMergeTranslations";
import { useMultilingualEditor, useSupportedLanguages } from "app/api/multilingualApi";
import { Alert } from "@mui/material";
import { useTLng } from "components/Multilingual/MultilingualModal";
import { ExecutionMode } from "app/api/generated/multilingual-service";

export interface PersonGroupsMergeDataProps {
  readonly assetType: DataAssetType;
  readonly open: boolean;
  readonly selectedItems: OverviewItem[];
}

interface MergeChipProps {
  readonly item: OverviewItem;
  readonly onDelete?: (item: OverviewItem) => void;
}
const MergeChip = ({ item, onDelete }: MergeChipProps) => {
  const onDeleteCallback = useCallback(() => {
    onDelete?.(item);
  }, [item, onDelete]);
  const label = useMemo(
    () => `${t(`lists_data_types_categories_person_groups:${item.title}`, item.title)}`,
    [item.title]
  );
  return (
    <Box mr={1}>
      <Chip label={label} color={"primary"} onDelete={onDelete ? onDeleteCallback : undefined} />
    </Box>
  );
};

export interface PersonGroupsMergeModalProps {
  readonly mergeData: PersonGroupsMergeDataProps | null;
  readonly onCancel: () => void;
  readonly onMerge: () => void;
}

export const PersonGroupsOverviewMergeModal = ({
  mergeData,
  onCancel: onParentCancel,
  onMerge
}: PersonGroupsMergeModalProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation(`resources_overview`);
  const {
    data,
    dataById,
    actions: { mergeDataTypes, mergeDataCategories, mergePersonGroups }
  } = useDataTypeTreeManager(true);
  const isMultilingualEnabled = useIsFeaturePresent(FEATURES.MULTILINGUAL);
  const supportedLanguages = useSupportedLanguages();
  const [step, setStep] = useState<number>(0);
  const [mergedTranslations, setMergedTranslations] = useState<Record<string, string>>({});
  const isDefaultLanguageEmpty = useMemo(() => {
    return !supportedLanguages.data?.mainLanguage || !mergedTranslations[supportedLanguages.data?.mainLanguage];
  }, [mergedTranslations, supportedLanguages.data?.mainLanguage]);
  // a hack since onMergeCallback is blind to mergedTranslations
  const mergedTranslationsRef = useRef(mergedTranslations);
  useEffect(() => {
    mergedTranslationsRef.current = mergedTranslations;
  }, [mergedTranslations]);
  const tLng = useTLng();

  const [mergeType, setMergeType] = useState<MergeType>(MERGE_TYPES.createNew);
  const [name, setName] = useState<string>("");
  const [selectedExisting, setSelectedExisting] = useState<{ readonly id: string; readonly label: string } | null>(
    null
  );
  const [itemsToMerge, setItemsToMerge] = useState<OverviewItem[]>([]);
  const { originalNames, translationKeys } = useMemo(() => {
    const translationKeys: string[] = [];
    const originalNames: string[] = [];
    if (name && mergeType === MERGE_TYPES.createNew) {
      originalNames.push(name);
    }
    if (selectedExisting && mergeType === MERGE_TYPES.mergeIntoExisting) {
      translationKeys.push(selectedExisting.id);
      originalNames.push(selectedExisting.label);
    }
    itemsToMerge.forEach(it => {
      translationKeys.push(it.id);
      originalNames.push(it.title);
    });
    return { translationKeys, originalNames: Array.from(new Set(originalNames)) };
  }, [name, selectedExisting, itemsToMerge, mergeType]);

  const { updateTranslations } = useMultilingualEditor({
    translationKey: selectedExisting?.id || ""
  });

  const handleCleanup = useCallback(() => {
    setStep(0);
    setName("");
    setSelectedExisting(null);
    setMergeType(MERGE_TYPES.createNew);
    setMergedTranslations({});
  }, []);

  const onCancel = useCallback(() => {
    handleCleanup();
    onParentCancel();
  }, [onParentCancel, handleCleanup]);

  useEffect(() => {
    if (!mergeData?.open) {
      return;
    }

    setMergeType(MERGE_TYPES.createNew);
    setName("");
    setSelectedExisting(null);
    setItemsToMerge(mergeData?.selectedItems || []);
  }, [mergeData?.open, mergeData?.selectedItems]);

  /* NEW NAME */
  const onNewNameChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => setName(event.target.value), []);
  const createNewEl = mergeType === MERGE_TYPES.createNew && (
    <Box mt={3}>
      <TextField
        label={t("common:name")}
        fullWidth={true}
        variant="outlined"
        value={name}
        onChange={onNewNameChanged}
        required={true}
      />
    </Box>
  );

  /* EXISTING ONE */
  const filterOptionsBySelectedItems = useCallback(
    ({ id }: { readonly id?: string }) => {
      return !mergeData?.selectedItems.some(it => it.id === id);
    },
    [mergeData?.selectedItems]
  );
  const options = useMemo(() => {
    if (mergeData?.assetType === DataAssetType.PersonGroup) {
      return Object.values(dataById?.personGroups || {})
        .map(({ id, personGroupKey }) => ({
          id,
          label: personGroupKey
        }))
        .filter(filterOptionsBySelectedItems);
    } else if (mergeData?.assetType === DataAssetType.DataCategory) {
      const personGroupId = mergeData.selectedItems[0].parentId;
      return Object.values(dataById?.personGroups?.[personGroupId]?.dataCategories || {})
        .map(({ id, dataCategoryKey }) => ({
          id,
          label: dataCategoryKey
        }))
        .filter(filterOptionsBySelectedItems);
    } else if (mergeData?.assetType === DataAssetType.DataType) {
      const dataCategoryId = mergeData.selectedItems[0].parentId;
      return Object.values(dataById?.dataCategories?.[dataCategoryId]?.dataTypes || {})
        .map(({ id, dataTypeKey }) => ({
          id,
          label: dataTypeKey
        }))
        .filter(filterOptionsBySelectedItems);
    } else return [];
  }, [
    dataById?.dataCategories,
    dataById?.personGroups,
    filterOptionsBySelectedItems,
    mergeData?.assetType,
    mergeData?.selectedItems
  ]);
  const getOptionLabel = useCallback(
    option => `${t(`lists_data_types_categories_person_groups:${option.label}`, option.label)}`,
    [t]
  );
  const onChangeSelected = useCallback(option => {
    setSelectedExisting(option);
  }, []);

  const label = useMemo(() => {
    if (mergeData?.assetType === DataAssetType.PersonGroup) {
      return t("personGroup:inputLabel");
    } else if (mergeData?.assetType === DataAssetType.DataCategory) {
      return t("dataCategory:inputLabel");
    } else if (mergeData?.assetType === DataAssetType.DataType) {
      return t("dataType:inputLabel");
    }
    return "";
  }, [mergeData?.assetType, t]);
  const existingEl = mergeType === MERGE_TYPES.mergeIntoExisting && (
    <Box mt={3}>
      <MultiAutocomplete label={label} options={options} getOptionLabel={getOptionLabel} onChange={onChangeSelected} />
    </Box>
  );

  const onErrorCallback = useCallback(
    (error: unknown) => {
      onCancel();
      if (isAxiosErrorWithCode(error, 409)) {
        enqueueSnackbar(`${t("error_messages:generic_already_exists")}`, { variant: "error" });
        return;
      }
    },
    [enqueueSnackbar, onCancel, t]
  );

  const onMergeCallback = useCallback(async () => {
    const newName = mergeType === MERGE_TYPES.createNew ? name : selectedExisting?.label || "";
    let mergedIntoId: string | undefined;
    if (mergeData?.assetType === DataAssetType.PersonGroup) {
      const personGroups = itemsToMerge.reduce<PersonGroup[]>(
        (acc, next) => (dataById?.personGroups[next.id] ? [...acc, dataById?.personGroups[next.id]] : acc),
        []
      );
      try {
        mergedIntoId = await mergePersonGroups({ personGroups, newName });
      } catch (error) {
        onErrorCallback(error);
      }
    } else if (mergeData?.assetType === DataAssetType.DataCategory) {
      const personGroupId = itemsToMerge[0].parentId;
      const dataCategories = itemsToMerge.reduce<DataCategory[]>(
        (acc, next) => (dataById?.dataCategories[next.id] ? [...acc, dataById?.dataCategories[next.id]] : acc),
        []
      );
      try {
        mergedIntoId = await mergeDataCategories({ personGroupId, dataCategories, newName });
      } catch (error) {
        onErrorCallback(error);
      }
    } else if (mergeData?.assetType === DataAssetType.DataType) {
      const dataCategoryId = itemsToMerge[0].parentId;
      const personGroup = data?.find(({ dataCategories }) => dataCategories.some(({ id }) => dataCategoryId === id));

      if (personGroup) {
        const personGroupId = personGroup?.id || "";
        const dataTypes = itemsToMerge.reduce<DataType[]>(
          (acc, next) => (dataById?.dataTypes[next.id] ? [...acc, dataById?.dataTypes[next.id]] : acc),
          []
        );

        try {
          mergedIntoId = await mergeDataTypes({ personGroupId, dataCategoryId, dataTypes, newName });
        } catch (erromergeDataTypesr) {
          onErrorCallback(erromergeDataTypesr);
        }
      }
    }
    if (mergedIntoId && isMultilingualEnabled) {
      await updateTranslations(mergedTranslations, mergedIntoId, ExecutionMode.ExecuteOnly);
    }

    handleCleanup();
    onMerge();
  }, [
    data,
    dataById?.dataCategories,
    dataById?.dataTypes,
    dataById?.personGroups,
    itemsToMerge,
    mergeData?.assetType,
    mergeDataCategories,
    mergeDataTypes,
    mergePersonGroups,
    mergeType,
    name,
    onErrorCallback,
    onMerge,
    selectedExisting?.label,
    isMultilingualEnabled,
    mergedTranslations,
    updateTranslations,
    handleCleanup
  ]);

  const confirmText = useMemo(() => {
    if (mergeData?.assetType === DataAssetType.PersonGroup) {
      return t("resources_lists_data_types_categories_person_groups:merge_confirmation_person_group");
    } else if (mergeData?.assetType === DataAssetType.DataCategory) {
      return t("resources_lists_data_types_categories_person_groups:merge_confirmation_data_category");
    } else if (mergeData?.assetType === DataAssetType.DataType) {
      return t("resources_lists_data_types_categories_person_groups:merge_confirmation_data_type");
    }
    return "";
  }, [mergeData?.assetType, t]);

  const onDeleteChipCallback = useCallback((item: OverviewItem) => {
    setItemsToMerge(current => current.filter(i => i !== item));
  }, []);
  const modalBody =
    step === 0 ? (
      <Box mt={3} mb={4}>
        <Box>
          <TextBody2 text={confirmText} />
          <Box mt={2} display={"flex"}>
            {itemsToMerge.map(item => {
              return (
                <MergeChip
                  key={item.id}
                  item={item}
                  onDelete={itemsToMerge.length > 1 ? onDeleteChipCallback : undefined}
                />
              );
            })}
          </Box>
        </Box>
        <Box mt={3}>
          <TextBody2 text={t(`mergeModal:merge_name_input_title`, t("common:name"))} />
        </Box>
        <MergeTypeSelector
          mergeDecision={mergeType}
          onMergeDecisionChanged={setMergeType}
          resourceType={RESOURCE_TYPES.DATA_ASSETS}
        />
        {createNewEl}
        {existingEl}
      </Box>
    ) : (
      <>
        {isDefaultLanguageEmpty && (
          <Alert severity="warning" sx={{ my: 2 }}>
            {t("multilingual:defaultLanguageEmpty", {
              language: tLng(supportedLanguages.data?.mainLanguage ?? "")
            })}
          </Alert>
        )}
        <PersonGroupsMergeTranslations
          translationKeys={translationKeys}
          originalNames={originalNames}
          onChange={setMergedTranslations}
        />
      </>
    );

  const originalButtons: ConfirmationModalButtonProps[] = useMemo(
    () => [
      {
        confirmButton: false,
        title: t("common:cancel"),
        variant: "outlined",
        color: "primary",
        size: "medium",
        onClick: onCancel
      },
      {
        confirmButton: true,
        title: t("common:merge"),
        variant: "contained",
        color: "primary",
        size: "medium",
        disabled:
          !(mergeType === MERGE_TYPES.createNew && name) &&
          !(mergeType === MERGE_TYPES.mergeIntoExisting && selectedExisting),
        onClick: onMergeCallback
      }
    ],
    [t, onCancel, mergeType, name, selectedExisting, onMergeCallback]
  );

  const multilingualButtons: ConfirmationModalButtonProps[] = useMemo(
    () => [
      {
        confirmButton: false,
        title: t("common:cancel"),
        variant: "outlined",
        color: "primary",
        size: "medium",
        onClick: step > 0 ? () => setStep(step => step - 1) : onCancel
      },
      step === 0
        ? {
            confirmButton: true,
            title: t("common:next"),
            variant: "contained",
            color: "primary",
            size: "medium",
            onClick: () => setStep(1)
          }
        : {
            confirmButton: true,
            title: t("common:merge"),
            variant: "contained",
            color: "primary",
            size: "medium",
            disabled: isDefaultLanguageEmpty,
            onClick: onMergeCallback
          }
    ],
    [t, onCancel, onMergeCallback, step, isDefaultLanguageEmpty]
  );

  const buttons = isMultilingualEnabled ? multilingualButtons : originalButtons;

  const modalText = useMemo(() => {
    if (mergeData?.assetType === DataAssetType.PersonGroup) {
      return t("resources_lists_data_types_categories_person_groups:merge_text_person_group");
    } else if (mergeData?.assetType === DataAssetType.DataCategory) {
      return t("resources_lists_data_types_categories_person_groups:merge_text_data_category");
    } else if (mergeData?.assetType === DataAssetType.DataType) {
      return t("resources_lists_data_types_categories_person_groups:merge_text_data_type");
    }
    return "";
  }, [mergeData?.assetType, t]);
  return (
    <ConfirmationModal
      modalOpen={Boolean(mergeData?.open)}
      onClose={onCancel}
      modalTitle={t(`resources_lists_data_types_categories_person_groups:merge_title`)}
      modalText={modalText}
      buttons={buttons}
      modalBody={modalBody}
      key={step}
    />
  );
};
