import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMetaView } from "../../../../../contexts/meta-view-context";
import { Box } from "@mui/material";
import { AuditQuestionsRender } from "app/pages/audits/designer/AuditQuestionsRender";
import { getSingleAnswerSetApi, postAnswerForAnswerSet } from "app/api/assessmentApi";
import { AuditQuestionWithAnswerAndRemarkDTO } from "app/api/auditApi";

const AnswersetQuestionnaire = ({ auditid, id }: { readonly auditid: string; readonly id: string }) => {
  const { t } = useTranslation("assessment_answerset");
  const { setInfo } = useMetaView();
  const [questions, setQuestions] = useState<AuditQuestionWithAnswerAndRemarkDTO[]>([]);
  const [selectedAuditQuestionId, setSelectedAuditQuestionId] = useState<string | null>(null);
  const [answerSetStatus, setAnswerSetStatus] = useState<string>("");
  const [auditStatus, setAuditStatus] = useState<string>("");

  const infoCard = useMemo(
    () => ({
      entering: {
        title: t("enteringInfoCardTitleQuestionnaire"),
        text: t("enteringInfoCardTextQuestionnaire")
      }
    }),
    [t]
  );
  useEffect(() => {
    setInfo(infoCard.entering);
  }, [infoCard, setInfo]);

  const loadQuestionnaire = useCallback(async () => {
    const data = await getSingleAnswerSetApi({ auditId: auditid || "", id: id || "" });
    if (data) {
      setAnswerSetStatus(data.status);
      setAuditStatus(data.audit.status);
      setQuestions(data.questions);
    }
  }, [id, auditid]);

  useEffect(() => {
    loadQuestionnaire();
  }, [loadQuestionnaire]);

  const onAnswerCallback = useCallback(
    async (questionId, payload) => {
      if (auditid && id) {
        await postAnswerForAnswerSet({ auditId: auditid, answerSetId: id, questionId, payload });
        loadQuestionnaire();
      }
    },
    [loadQuestionnaire, auditid, id]
  );

  const auditQuestionsRenderEl = useMemo(
    () => (
      <AuditQuestionsRender
        allowAnswer={answerSetStatus === "completed" ? false : true}
        templateStatus={"active"}
        selectedId={selectedAuditQuestionId}
        questions={questions}
        onSelectQuestion={undefined}
        auditStatus={auditStatus}
        onAnswer={onAnswerCallback}
      />
    ),
    [questions, selectedAuditQuestionId, onAnswerCallback, answerSetStatus, auditStatus]
  );

  return <Box>{auditQuestionsRenderEl}</Box>;
};

export default AnswersetQuestionnaire;
