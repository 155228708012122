import { Box, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import TextBody2 from "components/TextBody2/TextBody2";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import ConfirmationModal, { ConfirmationModalButtonProps } from "components/ConfirmationModal/ConfirmationModal";
import { mergeResourceApi } from "../../../../api/resourceApi";
import { useUserAndTenantData } from "../../../../handlers/userAndTenant/user-tenant-context";
import { COLLECTIONS } from "app/collections";
import { RESOURCE_TYPE } from "app/handlers/resourceHandler";
import { ResourceField } from "components/ResourceField";
import { useResources } from "../../../../contexts/resource-context";
import MergeTypeSelector, { MERGE_TYPES, MergeType } from "../../components/MergeTypeSelector";

export interface ResourceMergeItem {
  readonly id: string;
  readonly defaultResource: boolean;
}

export interface ResourceMergeModalProps {
  readonly resourceType: RESOURCE_TYPE;
  readonly selectedResources: Pick<ResourceMergeItem, "id">[];
  readonly onClose: () => void;
  readonly onCancel: () => void;
}

export const ResourceMergeModal = ({ resourceType, selectedResources, onClose, onCancel }: ResourceMergeModalProps) => {
  const { t } = useTranslation(`resources_${resourceType}_overview`);
  const { addToSeenItemsOfUserHook } = useUserAndTenantData();

  const [toMergeResourceIds, setToMergeResourceIds] = useState<string[]>([]);
  const [newName, setNewName] = useState("");
  const [mergeIntoResourceId, setMergeIntoResourceId] = useState<string>("");
  const [mergeType, setMergeType] = useState<MergeType>(MERGE_TYPES.createNew);

  useEffect(() => {
    setToMergeResourceIds(selectedResources.map(dl => dl.id));
    setNewName("");
    setMergeIntoResourceId("");
  }, [selectedResources]);

  const onNewNameChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => setNewName(event.target.value), []);

  const mergeResources = useCallback(async () => {
    const mergeIntoNewResourceAndMarkAsRead = async () => {
      const createdMergedIntoResourceId = await mergeResourceApi(resourceType, {
        toMergeIDs: toMergeResourceIds,
        nameKey: newName
      });
      if (createdMergedIntoResourceId) {
        await addToSeenItemsOfUserHook(COLLECTIONS.RESOURCES, createdMergedIntoResourceId);
      }
    };

    switch (mergeType) {
      case MERGE_TYPES.createNew:
        await mergeIntoNewResourceAndMarkAsRead();
        break;
      case MERGE_TYPES.mergeIntoExisting:
        await mergeResourceApi(resourceType, {
          toMergeIDs: toMergeResourceIds,
          mergeIntoId: mergeIntoResourceId
        });
        break;
      default:
        throw new Error(`Unknown merge type: ${mergeType}`);
    }

    onClose();
  }, [mergeType, onClose, resourceType, toMergeResourceIds, newName, addToSeenItemsOfUserHook, mergeIntoResourceId]);

  const buttons: ConfirmationModalButtonProps[] = useMemo(
    () => [
      {
        confirmButton: false,
        title: t("common:cancel"),
        variant: "outlined",
        color: "primary",
        size: "medium",
        onClick: onCancel
      },
      {
        confirmButton: true,
        title: t("common:merge"),
        variant: "contained",
        color: "primary",
        size: "medium",
        disabled:
          !(mergeType === MERGE_TYPES.createNew && newName) &&
          !(mergeType === MERGE_TYPES.mergeIntoExisting && mergeIntoResourceId),
        onClick: mergeResources
      }
    ],
    [onCancel, t, mergeResources, mergeIntoResourceId, mergeType, newName]
  );

  const onResourceIdsChanged = useCallback(value => {
    setToMergeResourceIds(value);
  }, []);

  const onMergeIntoIdChanged = useCallback(value => {
    setMergeIntoResourceId(value);
  }, []);

  const alwaysNotDeletable = useCallback(() => {
    return true;
  }, []);

  const modalBody = (
    <Box mt={3} mb={4}>
      <Box>
        <Box mt={2}>
          <ResourceField
            id="request_type"
            value={toMergeResourceIds}
            onChange={onResourceIdsChanged}
            resourceType={resourceType}
            label={""}
            multiSelect={true}
            isNotDeletable={alwaysNotDeletable}
            disabled={true}
          />
        </Box>
      </Box>
      <Box mt={3}>
        <TextBody2 text={t(`mergeModal:merge_name_input_title`, t("common:name"))} />
      </Box>
      <MergeTypeSelector mergeDecision={mergeType} onMergeDecisionChanged={setMergeType} resourceType={resourceType} />
      {mergeType === MERGE_TYPES.createNew && (
        <Box mt={2}>
          <TextField
            id={"new-datatype-name"}
            label={t("name", t("common:name"))}
            fullWidth={true}
            variant="outlined"
            value={newName}
            onChange={onNewNameChanged}
            required={true}
          />
        </Box>
      )}
      {mergeType === MERGE_TYPES.mergeIntoExisting && (
        <Box mt={2}>
          <ResourceToMergeInto
            mergeIntoResourceId={mergeIntoResourceId}
            resourceType={resourceType}
            onMergeIntoIdChanged={onMergeIntoIdChanged}
            toMergeResourceIds={toMergeResourceIds}
          />
        </Box>
      )}
    </Box>
  );

  return (
    <ConfirmationModal
      modalOpen={!!selectedResources.length}
      onClose={onCancel}
      modalTitle={t(`merge_title`)}
      modalText={t(`merge_text`)}
      buttons={buttons}
      modalBody={modalBody}
    />
  );
};

const ResourceToMergeInto = ({
  resourceType,
  toMergeResourceIds,
  mergeIntoResourceId,
  onMergeIntoIdChanged
}: {
  readonly resourceType: RESOURCE_TYPE;
  readonly toMergeResourceIds: string[];
  readonly mergeIntoResourceId: string;
  readonly onMergeIntoIdChanged: (value: string | null) => void;
}) => {
  const { t } = useTranslation(`resources_${resourceType}_overview`);
  const { resources } = useResources();
  const resourceOfTypes = resources[resourceType];
  const [whitelistedIDs, setWhitelistedIds] = useState<string[]>([]);
  useEffect(() => {
    const allowedIDsToUse = (resourceOfTypes || []).map(it => it.id).filter(it => !toMergeResourceIds.includes(it));
    setWhitelistedIds(allowedIDsToUse);
  }, [resourceOfTypes, toMergeResourceIds]);

  const onChangeCallback = useCallback(
    (values: string | string[] | null) => {
      onMergeIntoIdChanged(Array.isArray(values) ? values[0] : values);
    },
    [onMergeIntoIdChanged]
  );

  return (
    <ResourceField
      id="request_type"
      value={mergeIntoResourceId}
      onChange={onChangeCallback}
      resourceType={resourceType}
      label={t("merge_into_placeholder", t("mergeModal:merge_into_placeholder", ""))}
      multiSelect={false}
      allowAdd={false}
      whitelistedIDs={whitelistedIDs}
    />
  );
};
