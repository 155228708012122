import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, IconButton, InputAdornment, makeStyles, TextField, Tooltip, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { OverviewSort } from "./OverviewSort";
import { OverviewFilter } from "./OverviewFilter";
import Search from "@material-ui/icons/Search";
import { OverviewSelectAction } from "./OverviewSelectActions";
import { ExportButton, OverviewExport, OverviewPdfXlsExport } from "./OverviewExport";
import OverviewMarkAllAsReadButton from "./OverviewMarkAllAsReadButton";
import { OverviewToolbarProps } from "./OverviewPagesToolbar";
import OverviewAddButton from "./OverviewAddButton";
import CancelIcon from "@material-ui/icons/Cancel";
import OverviewIconButton from "./OverviewIconButton";
import Tabs from "components/TabsLayout/Tabs";
import { OverviewSelectAllCheckbox } from "./OverviewSelectAllCheckbox";

const useStyles = makeStyles(theme => ({
  searchInput: {
    width: "120px",
    margin: "0 8px 0 0",
    background: theme.palette.grey[100],
    borderRadius: "4px",
    marginLeft: "4px",
    "& input": {
      background: theme.palette.grey[100],
      border: 0,
      outline: 0,
      padding: "12px 10px",
      borderRadius: "4px"
    },
    "& fieldset": { border: "none" },
    "& .MuiInputAdornment-root": {
      margin: 0,
      padding: 0
    },
    "& .MuiOutlinedInput-adornedEnd": {
      padding: 0
    },
    "& .MuiIconButton-root": {
      "&:hover": { backgroundColor: "transparent" }
    }
  },
  "& .MuiIconButton-root": {
    color: theme.palette.grey[600],
    width: "32px",
    height: "32px",
    margin: "0 2px"
  },
  tabs: {
    display: "flex",
    minWidth: 0
  },
  tab: {
    textTransform: "none",
    minWidth: 0,
    fontFamily: "SuisseIntl",
    fontWeight: 600,
    flex: 1,
    "& .MuiBox-root": {
      width: "100%",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap"
    }
  },
  xlTab: {
    textTransform: "none",
    minWidth: 100,
    fontFamily: "SuisseIntl",
    fontWeight: 600
  },
  countText: {
    color: theme.palette.grey[500],
    fontSize: "12px"
  },
  clearSearchIcon: {
    padding: "0 8px 0 0",
    "& svg": {
      width: "16px",
      height: "16px"
    }
  },
  searchButton: {
    padding: "2.5px 0"
  }
}));
export const OverviewTabsToolbar = ({
  collection,
  searchTerm,
  sort,
  sortings,
  filter,
  filters,
  actions,
  addActions,
  selectionActions,
  pages,
  itemsCount,
  checkedItems,
  hideSearch,
  hideCount,
  toolbarHeader,
  toolbarAddControl,
  showSelectAll,
  onAdd,
  onSearch,
  onSort,
  onFilter,
  onReset,
  onPageChange,
  onResetChecked,
  onDuplicate,
  onDelete,
  onExport,
  onExportAll,
  onMarkAllAsRead,
  onSelectAllCheckbox,
  onToolbarSelectAllHover
}: OverviewToolbarProps) => {
  const { t } = useTranslation("overview");
  const { t: tTaskDetails } = useTranslation("task_details");
  const cls = useStyles();

  const [searchtext, setSearchText] = useState(searchTerm || "");
  const onChangeSearchText = useCallback(event => {
    setSearchText(event.target.value);
  }, []);
  useEffect(
    function triggerSearch() {
      const timeoutID = setTimeout(() => {
        onSearch(searchtext);
      }, 300);
      return () => clearTimeout(timeoutID);
    },
    [searchtext, onSearch]
  );
  const clearSearch = useCallback(() => {
    setSearchText("");
    onSearch("");
  }, [onSearch]);
  const [searchEnabled, setSearchEnabled] = useState(false);
  const searchButtonClicked = useCallback(() => {
    setSearchEnabled(lastValue => !lastValue);
  }, []);
  useEffect(() => {
    if (searchtext) {
      setSearchEnabled(true);
    }
  }, [searchtext]);
  const onSearchBlur = useCallback(() => {
    if (!searchtext) {
      setSearchEnabled(false);
    }
  }, [searchtext]);

  const actionsEl = actions
    ?.filter(a => a.action !== "add")
    .map(a => {
      if (a.action === "sort") {
        return (
          <React.Fragment key={a.action}>
            <OverviewSort sortings={sortings} sort={sort} onSort={onSort} onReset={onReset} />
          </React.Fragment>
        );
      } else if (a.action === "filter") {
        return (
          <React.Fragment key={a.action}>
            <OverviewFilter filters={filters} filter={filter} onFilter={onFilter} onReset={onReset} />
          </React.Fragment>
        );
      } else if (a.action === "export-all") {
        return (
          <React.Fragment key={a.action}>
            <OverviewExport onExport={onExportAll} />
          </React.Fragment>
        );
      } else if (a.action === "export-pdf-xlsx") {
        return (
          <React.Fragment key={a.action}>
            <OverviewPdfXlsExport onExport={onExportAll} />
          </React.Fragment>
        );
      } else if (a.action === "export-xlsx") {
        return (
          <React.Fragment key={a.action}>
            <ExportButton handleClick={onExportAll} />
          </React.Fragment>
        );
      } else if (a.action === "mark-all-as-read") {
        return (
          <React.Fragment key={a.action}>
            <OverviewMarkAllAsReadButton collection={collection} onMarkAllAsRead={onMarkAllAsRead} />
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment key={a.action}>
            <OverviewIconButton onClick={a.onHandle} disabled={a.disabled} tooltip={a.tooltip}>
              {a.icon}
            </OverviewIconButton>
          </React.Fragment>
        );
      }
    });

  const addButtonEl = useMemo(() => {
    if (checkedItems.length) {
      return <></>;
    }
    if (addActions?.length) {
      return (
        <Box ml={2}>
          <OverviewAddButton onAdd={onAdd} actions={addActions} />
        </Box>
      );
    }
    if (toolbarAddControl) {
      return toolbarAddControl;
    }
  }, [addActions, checkedItems.length, onAdd, toolbarAddControl]);

  const selectedButtonEl = useMemo(
    () =>
      checkedItems.length > 0 ? (
        <Box ml={1} mt={-1}>
          <OverviewSelectAction
            collection={collection}
            onCancel={onResetChecked}
            onDuplicate={onDuplicate}
            onDelete={onDelete}
            onExport={onExport}
            checkedItems={checkedItems}
            actions={selectionActions}
          />
        </Box>
      ) : (
        <></>
      ),
    [checkedItems, collection, onDelete, onDuplicate, onExport, onResetChecked, selectionActions]
  );
  const getCountTranslation = (count: number) => t(count === 1 ? "item_single" : "items_number", { number: count });
  const allCountText = itemsCount && typeof itemsCount.allCount === "number" && itemsCount.allCount.toString();
  const currentCountText =
    itemsCount && typeof itemsCount?.currentCount === "number"
      ? t("items_number_partial", { number: itemsCount.currentCount })
      : (itemsCount &&
          typeof itemsCount?.allCount === "number" &&
          t("items_number_partial", { number: itemsCount.allCount })) ||
        "";
  const checkedCountText = checkedItems.length > 0 ? getCountTranslation(checkedItems.length) : "";
  const countText = checkedCountText ? checkedCountText : `${currentCountText || ""} ${allCountText || ""}`;
  const itemsNumberEl = useMemo(
    () =>
      hideCount ? (
        <></>
      ) : (
        <Box px={0.5}>
          <Tooltip title={countText}>
            <Typography variant="caption" noWrap className={cls.countText}>
              {countText}
            </Typography>
          </Tooltip>
        </Box>
      ),
    [cls.countText, countText, hideCount]
  );

  const checkAllEl = (
    <Box pl={0.4}>
      {showSelectAll ? (
        <OverviewSelectAllCheckbox
          collection={collection}
          onChange={onSelectAllCheckbox}
          checkedItemsNumber={checkedItems.length}
          currentItemsNumber={itemsCount?.currentCount || 0}
          onHover={onToolbarSelectAllHover}
        />
      ) : (
        <></>
      )}
    </Box>
  );

  const onTabChange = useCallback(
    (index: number) => {
      const clickedPage = pages?.[index];
      if (clickedPage) {
        onPageChange?.(clickedPage);
      }
    },
    [onPageChange, pages]
  );

  const tabs = pages?.map((p, index) => {
    return {
      label: p.title,
      id: index.toString(),
      selected: p.current,
      unseenCount: p.unseenCount
    };
  });
  const tabsEl = useMemo(() => <Tabs tabs={tabs || []} onTabChange={onTabChange} />, [onTabChange, tabs]);

  const searchEl = useMemo(
    () =>
      hideSearch ? (
        <></>
      ) : (
        <>
          <Box className={cls.searchButton}>
            <OverviewIconButton onClick={searchButtonClicked} tooltip={t("search")}>
              <Search />
            </OverviewIconButton>
          </Box>
          {searchEnabled && (
            <TextField
              fullWidth
              id="overview-search-input"
              variant="outlined"
              value={searchtext}
              onBlur={onSearchBlur}
              onChange={onChangeSearchText}
              autoFocus={true}
              placeholder={t("search_placeholder")}
              className={cls.searchInput}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title={tTaskDetails("clear")}>
                      <IconButton onClick={clearSearch} className={cls.clearSearchIcon}>
                        <CancelIcon />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                )
              }}
            />
          )}
        </>
      ),
    [
      clearSearch,
      cls.clearSearchIcon,
      cls.searchInput,
      cls.searchButton,
      hideSearch,
      onChangeSearchText,
      onSearchBlur,
      searchButtonClicked,
      searchEnabled,
      searchtext,
      t,
      tTaskDetails
    ]
  );

  const inlineHeaderEl = useMemo(
    () => (toolbarHeader ? <Typography variant="subtitle1">{toolbarHeader}</Typography> : <></>),
    [toolbarHeader]
  );

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-end" pb={pages?.length ? 0 : 0.75}>
      {checkAllEl}
      {inlineHeaderEl}
      {tabsEl}
      {itemsNumberEl}
      {searchEl}
      {actionsEl}
      {addButtonEl}
      {selectedButtonEl}
    </Box>
  );
};
