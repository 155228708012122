import React, { useCallback, useEffect, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Box } from "@mui/material";

export interface OverviewSelectAllCheckboxProps {
  readonly collection: string;
  readonly onChange: (checked: boolean) => void;
  readonly onHover: (hovered: boolean) => void;
  readonly checkedItemsNumber: number;
  readonly currentItemsNumber: number;
}

export const OverviewSelectAllCheckbox = ({
  collection,
  onChange,
  checkedItemsNumber,
  currentItemsNumber,
  onHover
}: OverviewSelectAllCheckboxProps) => {
  useEffect(() => {
    if (checkedItemsNumber === 0) {
      setChecked(false);
    }
  }, [checkedItemsNumber]);

  const [checked, setChecked] = useState<boolean>(false);

  const handleCheck = useCallback(() => {
    const newChecked = !checked;
    setChecked(newChecked);
    onChange(newChecked);
  }, [checked, onChange]);

  const onMouseEnter = useCallback(() => {
    onHover(true);
  }, [onHover]);
  const onMouseLeave = useCallback(() => {
    onHover(false);
  }, [onHover]);

  return (
    <Box mt={0.25} mr={-1.9}>
      <FormControlLabel
        key={"select-all-" + collection}
        control={
          <Checkbox
            checked={checked}
            onChange={handleCheck}
            indeterminate={checkedItemsNumber > 0 && checkedItemsNumber < currentItemsNumber}
            color="primary"
            name={"select-all-" + collection}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          />
        }
        label={""}
      />
    </Box>
  );
};
