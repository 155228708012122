import { DefaultApi, PatchProcessorPADTO } from "./generated/process-service";
import { apiEndpoints } from "./apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "./axios/loggedInAxiosProvider";
import useSWR from "swr";
import { useCallback } from "react";
import i18n from "../i18n";
import { TDocumentDefinitions } from "pdfmake/interfaces";
import { ExportInfo } from "./paApi";
import { isAxiosErrorWithCode } from "./axios/axiosErrorHandler";

const processClient = new DefaultApi(undefined, apiEndpoints.paUrl, defaultOTCAuthenticatedAxios());
export const useGetProcessorApi = (args: { documentId: string }) => {
  return useSWR(args.documentId ? ["processor", args.documentId] : null, () => {
    return processClient.getProcessorPA(args.documentId).then(resp => resp.data);
  });
};

export const useGetProcessorsApi = () => {
  return useSWR("processors", () => {
    return processClient.getProcessorsPA().then(resp => resp.data);
  });
};

export const useUpdateProcessorApi = (args: { documentId: string }) => {
  const { mutate, isValidating } = useGetProcessorApi({ documentId: args.documentId });

  return {
    updateProcessor: useCallback(
      async (payload: PatchProcessorPADTO) => {
        await mutate(
          async data => {
            await processClient.updateProcessorPA(args.documentId, payload);
            return data;
          },
          {
            populateCache: false,
            revalidate: true
          }
        );
      },
      [args.documentId, mutate]
    ),
    isValidating
  };
};

export const useGetPAProcessorsApi = (args: { paId: string }) => {
  return useSWR(args.paId ? ["pa", args.paId, "processors"] : null, () => {
    return processClient.getDPIAProcessorInfo(args.paId).then(resp => resp.data);
  });
};

export const usePAProcessorApproveMissingFieldsApi = (args: { documentId: string }) => {
  return useSWR(args.documentId ? ["processor", args.documentId, "missing-fields"] : null, async ([, paId]) => {
    try {
      await processClient.updateProcessorPA(paId, { status: "approved" }, "true"); // string since it's query param
      return [];
    } catch (err: unknown) {
      if (!isAxiosErrorWithCode(err, 400, "SubmissionError")) {
        throw err;
      }
      const responseBody = err.response?.data;
      return (
        responseBody &&
        typeof responseBody === "object" &&
        "missingFields" in responseBody &&
        Array.isArray(responseBody.missingFields)
          ? responseBody.missingFields
          : []
      ).filter((it): it is string => typeof it === "string" && !!it);
    }
  });
};

export const createPAProcessorApi = async (input: { payload: PatchProcessorPADTO }) => {
  const axios = defaultOTCAuthenticatedAxios();
  const response = await axios.post(`${apiEndpoints.paUrl}/api/v1/processor-pa/overview`, input.payload);
  return response.headers["x-resource-id"] || "";
};

export const exportPAProcessorAsExcelApi = async (params: { readonly processorPAIds: string[] }): Promise<Blob> => {
  const axios = defaultOTCAuthenticatedAxios();

  const response = await axios.post(
    `${apiEndpoints.paUrl}/api/v1/processor-pa/overview/exports/excel`,
    {
      processorPAIds: params.processorPAIds
    },
    {
      headers: {
        "Accept-Language": i18n.language || "en-US"
      },
      responseType: "blob",
      timeout: 300000 // 5 minutes
    }
  );
  return new Blob([response.data]);
};

export const exportPAProcessorAsPdfMarkupApi = async (params: {
  readonly processorPAIds: string[];
  readonly exportInfo?: ExportInfo;
}) => {
  const axios = defaultOTCAuthenticatedAxios();
  return await axios.post<TDocumentDefinitions>(
    `${apiEndpoints.paUrl}/api/v1/processor-pa/overview/exports/pdf`,
    params,
    {
      headers: {
        "Accept-Language": i18n.language || "en-US"
      },
      timeout: 300000 // 5 minutes
    }
  );
};
