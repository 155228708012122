import {
  Alert,
  Box,
  Button,
  Typography,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  TextField,
  Modal,
  Paper,
  Stack,
  Dialog
} from "@mui/material";
import { useMultilingualEditor, useSupportedLanguages } from "app/api/multilingualApi";
import { isAxiosError } from "axios";
import { usePromisedCallback } from "components/ListViewItem/usePromisedCallback";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export type MultilingualEditorModalProps = {
  translationKey: string;
  onClose: () => void;
  onAdd: (mainTranslation: string) => Promise<string>;
  title?: string;
  untranslatedText?: string;
};

export const NEW_TRANSLATION_KEY = "new-translation-key";

export const useTLng = () => {
  const [t] = useTranslation();
  const tLng = (lng: string) => t(`sidebar:${lng}`);
  return tLng;
};

export default function MultilingualEditorModal({
  onClose,
  translationKey,
  onAdd,
  title,
  untranslatedText
}: MultilingualEditorModalProps) {
  const [t, i18n] = useTranslation();
  const supportedLanguages = useSupportedLanguages();
  const {
    translations,
    updateTranslations,
    isLoading: isTranslationLoading
  } = useMultilingualEditor({ translationKey });
  const [state, setState] = useState({
    translationForm: translations,
    currentLanguage: i18n.language
  });
  useEffect(
    function updateTranslations() {
      setState(state => ({ ...state, translationForm: translations }));
    },
    [translations]
  );
  useEffect(
    function setCurrentLanguageIfDefaultEmpty() {
      if (supportedLanguages.data?.mainLanguage && !isTranslationLoading) {
        setState(state => ({
          ...state,
          currentLanguage: state.translationForm[state.currentLanguage]
            ? state.currentLanguage
            : supportedLanguages.data?.mainLanguage || state.currentLanguage
        }));
      }
    },
    [supportedLanguages.data?.mainLanguage, isTranslationLoading]
  );
  useEffect(
    function prefillUntranslatedText() {
      if (untranslatedText && !isTranslationLoading) {
        setState(state => ({
          ...state,
          translationForm: {
            ...state.translationForm,
            [state.currentLanguage]: state.translationForm[state.currentLanguage] || untranslatedText
          },
          currentLanguage: state.translationForm[state.currentLanguage]
            ? state.currentLanguage
            : supportedLanguages.data?.mainLanguage || state.currentLanguage
        }));
      }
    },
    [untranslatedText, isTranslationLoading, supportedLanguages.data, state.currentLanguage]
  );
  const handleLanguageChange = useCallback((event: SelectChangeEvent<string>) => {
    setState(state => ({ ...state, currentLanguage: event.target.value }));
  }, []);
  const handleTranslationChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setState(state => ({
      ...state,
      translationForm: state.currentLanguage
        ? { ...state.translationForm, [state.currentLanguage]: event.target.value }
        : state.translationForm
    }));
  }, []);
  const handleSubmission = usePromisedCallback(
    useCallback(async () => {
      try {
        let id: string | undefined;
        if (translationKey === NEW_TRANSLATION_KEY) {
          id = await onAdd(state.translationForm[supportedLanguages.data?.mainLanguage || ""]);
        }

        await updateTranslations(state.translationForm, id);
        onClose();
      } catch (err) {
        if (
          isAxiosError(err) &&
          (err?.response?.status === 409 || err?.response?.data?.message === "Translation duplicate")
        ) {
          throw new Error(
            t("multilingual:translationConflict", {
              title: title ?? ""
            })
          );
        }
        throw err;
      }
    }, [state.translationForm, updateTranslations, onClose, onAdd, translationKey, supportedLanguages.data, t, title])
  );
  const tLng = useTLng();
  const languagesDropdown = useMemo(() => {
    const lngs: Record<string, string> = {};
    if (supportedLanguages.data?.mainLanguage) {
      lngs[supportedLanguages.data.mainLanguage] =
        `${tLng(supportedLanguages.data.mainLanguage)} (${t("common:default")})`;
    }
    if (supportedLanguages.data?.supportedLanguages) {
      supportedLanguages.data.supportedLanguages.forEach(lng => {
        lngs[lng] = tLng(lng);
      });
    }
    return lngs;
  }, [t, supportedLanguages.data, tLng]);
  const isLoading = supportedLanguages.isLoading || isTranslationLoading;
  const isDefaultLanguageEmpty = !state.translationForm[supportedLanguages.data?.mainLanguage || ""];
  return (
    <Dialog open={true} onClose={onClose}>
      <Box p={2} width="480px">
        {isLoading ? (
          <Skeleton />
        ) : (
          <div>
            <Stack spacing={2}>
              <Typography variant="h5">
                {t(translationKey === NEW_TRANSLATION_KEY ? "common:new" : "common:modify", {
                  title: title ?? ""
                })}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 2,
                  gap: 2
                }}
              >
                <Typography>{t("sidebar:language")}</Typography>
                <Select value={state.currentLanguage} onChange={handleLanguageChange} fullWidth>
                  {Object.entries(languagesDropdown).map(([languageCode, lng]) => (
                    <MenuItem key={languageCode} value={languageCode}>
                      {lng}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box>
                <TextField
                  label={title}
                  key={state.currentLanguage}
                  value={state.translationForm[state.currentLanguage || ""]}
                  onChange={handleTranslationChange}
                  fullWidth
                  error={handleSubmission.error instanceof Error}
                  helperText={handleSubmission.error?.message}
                />
                {isDefaultLanguageEmpty && (
                  <Alert severity="warning" sx={{ mt: 2 }}>
                    {t("multilingual:defaultLanguageEmpty", {
                      language: t(`sidebar:${supportedLanguages.data?.mainLanguage}`)
                    })}
                  </Alert>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 2
                }}
              >
                <Button onClick={onClose}>{t("common:cancel")}</Button>
                <Button
                  onClick={handleSubmission.trigger}
                  disabled={handleSubmission.pending || isDefaultLanguageEmpty}
                  variant="contained"
                >
                  {t("common:save")}
                </Button>
              </Box>
            </Stack>
          </div>
        )}
      </Box>
    </Dialog>
  );
}
