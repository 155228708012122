import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  AuditDetailDTO,
  AuditQuestionDTO,
  AuditQuestionPayloadActionDTO,
  AuditQuestionPayloadDTO,
  AuditQuestionWithAnswerAndRemarkDTO,
  AuditTemplateDetailDTO,
  copyAuditInlineTemplate,
  copyAuditTemplateQuestion,
  createInlineTemplate,
  deleteAuditTemplateQuestion,
  getAllAuditTemplates,
  getAuditDetail,
  getAuditTemplate,
  patchAuditDetail,
  patchAuditTemplate,
  patchAuditTemplateQuestion,
  postAuditTemplateQuestion
} from "app/api/auditApi";
import { useTranslation } from "react-i18next";
import { useMetaView } from "app/contexts/meta-view-context";
import { useErrorSnackbar } from "hook/errorSnackbar";
import { AssessmentAddQuestionButton } from "../../designer/AssessmentAddQuestionButton";
import { ClickAwayListener } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import CustomAlert from "../../../../../components/CustomAlert/CustomAlert";
import MultiAutocomplete from "components/MultiAutocomplete/MultiAutocomplete";
import { AuditQuestionsRender } from "../../designer/AuditQuestionsRender";
import DocumentNotFound from "../../../shared/DocumentNotFound/DocumentNotFound";
import AuditQuestionSelector from "../../designer/AuditQuestionSelector";
import { createOverviewItemDefaultName } from "app/utils/create-overview-item-default-name";
import { AuditQuestionSettings } from "../../metaview/AuditQuestionSettings";
import { COLLECTIONS } from "app/collections";
import { AUDIT_REPORT_PERMISSION, AUDIT_REPORT_PERMISSION_TYPES } from "../../audit/AuditTypes";
import { Box, Button, Typography } from "@mui/material";
import { buildAuditQuestionPayloadDTO, buildNewAuditQuestionPayloadDTO } from "../../designer/AuditTemplateDesigner";

export const AssessmentQuestionnaire = ({
  auditId,
  updateQuestionnaireMetaView,
  onClickNext,
  onUpdate
}: {
  readonly auditId: string;
  readonly updateQuestionnaireMetaView: (value: React.ReactNode) => void;
  readonly onClickNext: () => void;
  readonly onUpdate: () => void;
}) => {
  const { t } = useTranslation("audit_details");
  const { setInfo } = useMetaView();
  const { catchAsSnackbar } = useErrorSnackbar();
  const [blockSelector, setBlockSelector] = useState<boolean>(false);
  const [auditData, setAuditData] = useState<Partial<AuditDetailDTO> | null>(null);
  const [template, setTemplate] = useState<Partial<AuditTemplateDetailDTO> | null>(null);
  const [templateId, setTemplateId] = useState<string>("");
  const [templateIds, setTemplateIds] = useState<string[]>([]);
  const [templates, setTemplates] = useState<AuditTemplateDetailDTO[]>([]);
  const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);
  const [temporaryTemplateId, setTemporaryTemplateId] = useState<string | null>(null);
  const [templateConfirmationOpen, setTemplateConfirmationOpen] = useState<boolean>(false);
  const [templateConfirmationAlreadyShowed, setTemplateConfirmationAlreadyShowed] = useState<boolean>(false);
  const [notFound, setNotFound] = useState(false);
  const [conditionPickerQuestion, setConditionPickerQuestion] = useState<AuditQuestionWithAnswerAndRemarkDTO | null>(
    null
  );
  const [initialized, setInitialized] = useState<boolean>(false);
  const [selectedAuditQuestion, setSelectedAuditQuestion] = useState<AuditQuestionWithAnswerAndRemarkDTO | null>(null);
  const [questions, setQuestions] = useState<AuditQuestionWithAnswerAndRemarkDTO[]>([]);
  const [selectedAuditQuestionId, setSelectedAuditQuestionId] = useState<string | null>(null);
  const [reportPermission, setReportPermission] = useState<AUDIT_REPORT_PERMISSION_TYPES | null>(null);
  const [beforeConfirmationQuestion, setBeforeConfirmationQuestion] =
    useState<AuditQuestionWithAnswerAndRemarkDTO | null>(null);

  const closeConfirmation = useCallback(() => {
    setConfirmationOpen(false);
    setTemporaryTemplateId(null);
    setBeforeConfirmationQuestion(null);
  }, []);
  const openConfirmation = useCallback(() => setConfirmationOpen(true), []);

  useEffect(() => {
    if (selectedAuditQuestionId) {
      setSelectedAuditQuestion(questions.find(({ id }) => id === selectedAuditQuestionId) || null);
    } else setSelectedAuditQuestion(null);
  }, [questions, selectedAuditQuestionId]);

  /* UPDATE AUDIT */
  const updateData = useCallback(
    async data => {
      if (auditData && auditData.id) {
        await patchAuditDetail({ id: auditData.id, payload: data, wait: data.template === undefined }).catch(
          catchAsSnackbar()
        );
      }
    },
    [auditData, catchAsSnackbar]
  );

  const closeTemplateConfirmation = useCallback(() => {
    setTemplateConfirmationOpen(false);
  }, []);

  const confirmTemplateDialog = useCallback(async () => {
    setTemplateConfirmationOpen(false);
    setTemplateConfirmationAlreadyShowed(true);
    if (beforeConfirmationQuestion) {
      await patchAuditTemplateQuestion({
        templateId: templateId,
        id: beforeConfirmationQuestion.id,
        payload: buildAuditQuestionPayloadDTO(beforeConfirmationQuestion)
      });

      setQuestions(currentQuestions =>
        currentQuestions.map(currentQuestion =>
          currentQuestion.id === beforeConfirmationQuestion.id ? beforeConfirmationQuestion : currentQuestion
        )
      );
      setBeforeConfirmationQuestion(null);
    }
  }, [beforeConfirmationQuestion, templateId]);

  const createNewTemplate = useCallback(async () => {
    const newEmptyTemplateId = await createInlineTemplate({
      payload: { title: createOverviewItemDefaultName(`${COLLECTIONS.AUDITS}_templates`), inlineTemplate: true }
    });
    if (newEmptyTemplateId) {
      await patchAuditTemplate({
        id: newEmptyTemplateId,
        payload: { status: "active" }
      });
      setTemplateId(newEmptyTemplateId);
      await updateData({
        template: { id: newEmptyTemplateId, version: 0 }
      });
    }
  }, [updateData]);

  const getAuditData = useCallback(async () => {
    const auditData = await getAuditDetail({ id: auditId });
    setAuditData(auditData);
    if (auditData?.pagesWithWrite?.includes("report")) {
      setReportPermission(AUDIT_REPORT_PERMISSION.WRITE);
    } else if (auditData?.pages?.includes("report")) {
      setReportPermission(AUDIT_REPORT_PERMISSION.WRITE);
    }
    setTemplateId(auditData?.template?.id || "");
  }, [auditId]);

  useEffect(() => {
    getAuditData();
  }, [getAuditData]);

  const reloadTemplateCallback = useCallback(async () => {
    if (!templateId) {
      return;
    }
    const auditTemplate = await getAuditTemplate({ id: templateId });
    if (!auditTemplate) {
      setNotFound(true);
      return;
    }

    setTemplate(auditTemplate);
    setQuestions(auditTemplate?.questions || []);
  }, [templateId]);

  /* reload templates */
  useEffect(() => {
    reloadTemplateCallback();
  }, [reloadTemplateCallback]);

  /* get templates */
  useEffect(() => {
    const fetchTemplates = async () => {
      if (auditId) {
        const allTemplates: AuditTemplateDetailDTO[] = await getAllAuditTemplates({});
        const publishedTemplates: AuditTemplateDetailDTO[] = allTemplates.filter(
          ({ status, inlineTemplate }) => status === "active" && inlineTemplate !== true
        );
        setTemplates(publishedTemplates);
        setTemplateIds((publishedTemplates || []).map(({ id }) => id));
      }
    };
    fetchTemplates();
  }, [auditId]);

  useEffect(() => {
    if (auditData && !auditData?.template?.id) {
      createNewTemplate();
    }
  }, [auditData, createNewTemplate]);

  const onChangeAuditQuestion = useCallback(
    async (q: AuditQuestionWithAnswerAndRemarkDTO) => {
      if (!templateConfirmationAlreadyShowed) {
        setTemplateConfirmationOpen(true);
        setBeforeConfirmationQuestion(q);
        return;
      }
      await patchAuditTemplateQuestion({
        templateId: templateId,
        id: q.id,
        payload: buildAuditQuestionPayloadDTO(q)
      });

      setQuestions(currentQuestions =>
        currentQuestions.map(currentQuestion => (currentQuestion.id === q.id ? q : currentQuestion))
      );
    },
    [templateId, templateConfirmationAlreadyShowed]
  );

  const onSelectAuditQuestion = useCallback(
    async (questionId: string | null) => {
      if (conditionPickerQuestion && questionId) {
        const newConditions = conditionPickerQuestion.conditions || {};
        newConditions[questionId] = "";
        const question = {
          ...conditionPickerQuestion,
          conditions: newConditions
        };
        await onChangeAuditQuestion(question);
        setSelectedAuditQuestion(question);
        setConditionPickerQuestion(null);
      } else {
        setSelectedAuditQuestionId(questionId || null);
      }
    },
    [conditionPickerQuestion, onChangeAuditQuestion]
  );

  const onCopyAuditQuestion = useCallback(
    async (question: AuditQuestionDTO) => {
      const newQuestionId = await copyAuditTemplateQuestion({ templateId: templateId, questionId: question.id });
      await reloadTemplateCallback();
      setSelectedAuditQuestionId(newQuestionId || null);
    },
    [templateId, reloadTemplateCallback]
  );

  const onReorderQuestion = useCallback(
    async (questionId, payload) => {
      await patchAuditTemplateQuestion({
        templateId: templateId,
        id: questionId,
        payload
      });

      await reloadTemplateCallback();
      setSelectedAuditQuestionId(questionId || null);
    },
    [templateId, reloadTemplateCallback]
  );

  const onAddQuestionToGroup = useCallback(
    async (questionType, groupId) => {
      const questionsInGroup = questions.filter(question => question.groupId === groupId);
      const newQuestion: AuditQuestionPayloadDTO = {
        ...buildNewAuditQuestionPayloadDTO(questionType, t),
        order: { afterQuestionId: questionsInGroup.length ? questionsInGroup[questionsInGroup.length - 1].id : null },
        groupId
      };
      const newQuestionId = await postAuditTemplateQuestion({ templateId: templateId, payload: newQuestion });
      await reloadTemplateCallback();
      setSelectedAuditQuestionId(newQuestionId || null);
    },
    [templateId, questions, reloadTemplateCallback, t]
  );

  const onDropQuestionToGroup = useCallback(
    async (questionId, payload) => {
      await patchAuditTemplateQuestion({
        templateId: templateId,
        id: questionId,
        payload
      });
      await reloadTemplateCallback();
      setSelectedAuditQuestionId(questionId || null);
    },
    [templateId, reloadTemplateCallback]
  );

  const onDropQuestionToRoot = useCallback(
    async (questionId, payload) => {
      await patchAuditTemplateQuestion({
        templateId: templateId,
        id: questionId,
        payload
      });
      await reloadTemplateCallback();
      setSelectedAuditQuestionId(questionId || null);
    },
    [templateId, reloadTemplateCallback]
  );

  const onRemoveAuditQuestion = useCallback(
    async (question: AuditQuestionDTO) => {
      if (!templateId) {
        return;
      }
      if (!templateConfirmationAlreadyShowed) {
        setTemplateConfirmationOpen(true);
        return;
      }
      await deleteAuditTemplateQuestion({ templateId: templateId, id: question.id });
      await updateData({
        status: "draft"
      });
      setQuestions(currentQuestions => currentQuestions.filter(({ id }) => id !== question.id));
      onUpdate();
    },
    [templateId, updateData, templateConfirmationAlreadyShowed, onUpdate]
  );

  const noWritePermission = useMemo(
    () => !auditData?.pagesWithWrite?.includes?.("template"),
    [auditData?.pagesWithWrite]
  );

  const auditQuestionsRenderEl = useMemo(
    () =>
      template && (
        <AuditQuestionsRender
          reportPermission={reportPermission}
          allowAnswer={false}
          conditionPickerQuestion={conditionPickerQuestion}
          templateStatus={noWritePermission ? "active" : "inactive"}
          selectedId={selectedAuditQuestionId}
          questions={questions}
          onSelectQuestion={onSelectAuditQuestion}
          onCopyQuestion={onCopyAuditQuestion}
          auditStatus={auditData?.status}
          onRemoveQuestion={onRemoveAuditQuestion}
          onReorderQuestion={onReorderQuestion}
          onAddQuestionToGroup={onAddQuestionToGroup}
          onDropQuestionToGroup={onDropQuestionToGroup}
          onDropQuestionToRoot={onDropQuestionToRoot}
        />
      ),
    [
      template,
      noWritePermission,
      conditionPickerQuestion,
      questions,
      selectedAuditQuestionId,
      onSelectAuditQuestion,
      auditData?.status,
      onCopyAuditQuestion,
      onRemoveAuditQuestion,
      onReorderQuestion,
      onAddQuestionToGroup,
      onDropQuestionToGroup,
      onDropQuestionToRoot,
      reportPermission
    ]
  );

  const confirmationTemplateDialogEl = useMemo(
    () => (
      <ConfirmationModal
        variant="info"
        modalOpen={templateConfirmationOpen && !templateConfirmationAlreadyShowed}
        onClose={closeTemplateConfirmation}
        modalTitle={t("common:information")}
        modalText={t("changeQuestionnareConfirmation")}
        buttons={[
          {
            title: t("common:cancel"),
            variant: "outlined",
            color: "primary",
            size: "medium",
            onClick: closeTemplateConfirmation
          },
          {
            confirmButton: true,
            title: t("common:save"),
            variant: "contained",
            color: "primary",
            size: "medium",
            onClick: confirmTemplateDialog
          }
        ]}
      />
    ),
    [templateConfirmationAlreadyShowed, templateConfirmationOpen, closeTemplateConfirmation, confirmTemplateDialog, t]
  );

  const infoCard: Record<string, { readonly title: string; readonly text: string }> = useMemo(
    () => ({
      entering: {
        title: t("assessment_details:enteringInfoCardTitle"),
        text: t("assessment_details:enteringInfoCardContent")
      }
    }),
    [t]
  );
  const showQuestionSelectorCallback = useCallback(() => setBlockSelector(true), []);
  const hideQuestionSelectorCallback = useCallback(() => {
    setBlockSelector(false);
    // setSelectedAuditBlock(null);
  }, []);

  const onShowQuestionAction = useCallback((action: AuditQuestionPayloadActionDTO) =>
    // need to handle add task too,
    {}, []);

  useEffect(() => {
    if (selectedAuditQuestion) {
      updateQuestionnaireMetaView(
        <Box p={3}>
          <AuditQuestionSettings
            allQuestions={questions}
            selectedQuestion={selectedAuditQuestion}
            onChangeQuestion={onChangeAuditQuestion}
            onSelectQuestion={onSelectAuditQuestion}
            onSetConditionPickerQuestion={setConditionPickerQuestion}
            onShowQuestionAction={onShowQuestionAction}
          />
        </Box>
      );
    }
  }, [
    onChangeAuditQuestion,
    onSelectAuditQuestion,
    questions,
    selectedAuditQuestion,
    onShowQuestionAction,
    updateQuestionnaireMetaView
  ]);

  const onSaveTemplate = useCallback(
    async templateId => {
      const selectedTemplate = templates.find(({ id }) => id === temporaryTemplateId);
      if (selectedTemplate) {
        // we have to switch status to DRAFT if user change template
        await updateData({
          template: { id: templateId, version: selectedTemplate.version }
        });
        await updateData({
          status: "draft"
        });
      }
      const data = await getAuditDetail({ id: auditId || "" });
      setAuditData(data);
      onUpdate();
    },
    [auditId, templates, updateData, temporaryTemplateId, onUpdate]
  );

  useEffect(() => {
    setInfo(infoCard.entering);
  }, [infoCard, setInfo]);

  // to support old audit data
  const copyOldTemplate = useCallback(async () => {
    if (!initialized && template && !template?.inlineTemplate && templateId) {
      const newTemplateId = await copyAuditInlineTemplate({ templateId });
      if (newTemplateId) {
        setTemporaryTemplateId(templateId);
        setTemplateId(newTemplateId);
        setInitialized(true);
        await onSaveTemplate(newTemplateId);
      }
    }
  }, [template, templateId, initialized, onSaveTemplate]);
  // ================================== //

  useEffect(() => {
    if (!noWritePermission) copyOldTemplate();
  }, [copyOldTemplate, noWritePermission]);

  const onChangeTemplate = useCallback((templateId: string | null) => {
    setTemporaryTemplateId(templateId);
  }, []);

  const onUpdateTemplate = useCallback(async () => {
    const newTemplateId = await copyAuditInlineTemplate({ templateId: temporaryTemplateId || "" });
    setTemplateId(newTemplateId);
    await onSaveTemplate(newTemplateId);
    setConfirmationOpen(false);
  }, [onSaveTemplate, temporaryTemplateId]);

  const getTemplateOptionLabel = useCallback(
    optionId => templates.find(({ id }) => id === optionId)?.title || "",
    [templates]
  );

  const modalBodyEl = useMemo(
    () => (
      <>
        <MultiAutocomplete
          id="template"
          label={t("assessment_details:selected_audit_template")}
          hasMultiSelect={false}
          selected={temporaryTemplateId}
          updateSelected={onChangeTemplate}
          options={templateIds}
          getOptionLabel={getTemplateOptionLabel}
        />
        <CustomAlert
          severity={"warning"}
          style={{ fontWeight: "bold", marginTop: "20px", marginBottom: "20px" }}
          icon={<ErrorOutline style={{ color: "theme.palette.alert.info" }} />}
        >
          {t("assessment_details:modal_body")}
        </CustomAlert>
      </>
    ),
    [getTemplateOptionLabel, onChangeTemplate, t, temporaryTemplateId, templateIds]
  );

  const onAddAuditQuestion = useCallback(
    async (type: string) => {
      hideQuestionSelectorCallback();
      if (!templateId) {
        return;
      }
      const lastQuestion: AuditQuestionDTO | null = questions.length ? questions[questions.length - 1] : null;
      const newQuestion: AuditQuestionPayloadDTO = {
        ...buildNewAuditQuestionPayloadDTO(type, t),
        order: { afterQuestionId: lastQuestion ? lastQuestion.id : null }
      };
      const newQuestionId = await postAuditTemplateQuestion({
        templateId: templateId,
        payload: newQuestion
      });
      await updateData({
        status: "draft"
      });
      await reloadTemplateCallback();
      setSelectedAuditQuestionId(newQuestionId || null);
      onUpdate();
    },
    [hideQuestionSelectorCallback, questions, t, templateId, reloadTemplateCallback, updateData, onUpdate]
  );

  const confirmationDialogEl = useMemo(
    () => (
      <ConfirmationModal
        variant="info"
        modalOpen={confirmationOpen}
        onClose={closeConfirmation}
        modalText={t("assessment_details:modal_text")}
        modalBody={modalBodyEl}
        buttons={[
          {
            title: t("common:cancel"),
            variant: "outlined",
            color: "primary",
            size: "medium",
            onClick: closeConfirmation
          },
          {
            confirmButton: true,
            title: t("questionnaireImport:import_button"),
            variant: "contained",
            color: "primary",
            size: "medium",
            onClick: onUpdateTemplate,
            disabled: temporaryTemplateId ? false : true
          }
        ]}
      />
    ),
    [confirmationOpen, t, modalBodyEl, temporaryTemplateId, closeConfirmation, onUpdateTemplate]
  );

  const questionSelectorEl = useMemo(
    () =>
      blockSelector && (
        <ClickAwayListener onClickAway={hideQuestionSelectorCallback}>
          <AuditQuestionSelector onSelect={onAddAuditQuestion} onClose={hideQuestionSelectorCallback} />
        </ClickAwayListener>
      ),
    [blockSelector, hideQuestionSelectorCallback, onAddAuditQuestion]
  );

  if (notFound) {
    return <DocumentNotFound collection={"auditTemplates"} />;
  }

  return (
    <>
      <Box sx={{ marginBottom: "1rem", marginTop: "1rem", display: "flex", alignItems: "center" }}>
        <Box flex={1}>
          <Typography variant="subtitle1">{t("audit_details:questionnaire")}</Typography>
        </Box>
        <Box>
          <Button
            startIcon={<FileCopyIcon />}
            variant="outlined"
            color="primary"
            onClick={openConfirmation}
            disabled={noWritePermission}
            sx={{ marginRight: "1rem" }}
          >
            {t("assessment_details:fromTemplate")}
          </Button>
          <Button variant="contained" color="primary" onClick={onClickNext}>
            {t("common:nextContinue")}
          </Button>
        </Box>
      </Box>
      {confirmationDialogEl}
      {confirmationTemplateDialogEl}
      <Box display="flex">
        <Box
          flex={1}
          border={1}
          style={{ borderStyle: "dotted", borderRadius: "5px", borderColor: "rgba(0, 0, 0, 0.24)" }}
        >
          <Box mr={2} ml={2}>
            {auditQuestionsRenderEl}
            {questionSelectorEl}
            {!blockSelector && (
              <AssessmentAddQuestionButton onClick={showQuestionSelectorCallback} disabled={noWritePermission} />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};
