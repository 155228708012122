import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import overviewBaseController, {
  OverviewController,
  OverviewSetup
} from "components/Overview/controllers/overviewBaseController";
import { COLLECTIONS } from "app/collections";
import { dataLocationsDecorator } from "../../../../components/Overview/controllers/decorator/dataLocationsDecorator";
import { AxiosInstance } from "axios";
import { resourcesDecorator } from "components/Overview/controllers/decorator/resourcesDecorator";
import { tDeletedEntry } from "app/handlers/dataTypeTranslatorHandler";
import { departmentsDecorator } from "../../../../components/Overview/controllers/decorator/departmentsDecorator";
import i18n from "../../../i18n";

const RiskMatrixOverviewController = (
  axiosInstance: AxiosInstance,
  collectionParams: {
    filter: {
      riskIds: string[];
    };
  }
): OverviewController => {
  const { t } = useTranslation("risks_overview");
  const translateItem = useCallback(
    obj => ({ ...obj, subTitle: obj.subTitle === "deleted" ? tDeletedEntry({ t }) : obj.subTitle }),
    [t]
  );
  const baseController = overviewBaseController(axiosInstance, COLLECTIONS.RISK_MATRIX, translateItem, [
    dataLocationsDecorator,
    resourcesDecorator,
    departmentsDecorator
  ]);

  const getOverview = async (setup: OverviewSetup) => {
    const data = await baseController.getOverview(
      setup,
      "risks",
      collectionParams
        ? {
            params: {
              riskIds: collectionParams?.filter?.riskIds
            },
            headers: { "Accept-Language": i18n.language }
          }
        : { headers: { "Accept-Language": i18n.language } }
    );
    if (!data) {
      return null;
    }

    return {
      ...data
    };
  };

  const goToItem = (id: string) => {
    window.open(`/risks/${id}/general`);
  };

  return {
    ...baseController,
    goToItem,
    getOverview
  };
};

export default RiskMatrixOverviewController;
