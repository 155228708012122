import { useTranslation } from "react-i18next";
import { MouseEventHandler, useCallback, useMemo } from "react";
import ListViewItem, { ItemStatus, RowAction } from "components/ListViewItem/ListViewItem";
import { LazySvgIcon } from "../../../LazySvgIcon/LazySvgIcon";
import { AssetStatusEnum } from "app/api/generated/asset-service";
import { OverviewItem } from "components/Overview/controllers/overviewBaseController";
import { CustomRowComponentProps } from "components/Overview/controls/OverviewRow";
import { Box, Chip } from "@mui/material";
import { updateAsset } from "app/pages/assets/handler/assetHandler";
import { ASSET_STATUS, ASSET_STATUS_TYPES } from "app/pages/assets/types/AssetTypes";
import { useOverviewState } from "app/contexts/overview-context";
import { COLLECTIONS } from "app/collections";
import { SxProps } from "@mui/system/styleFunctionSx";

export default function AssetsOverviewRow({
  checkable,
  checked,
  deletable,
  forceDisplayCheckbox,
  item,
  rowActions,
  onChecked,
  onClick,
  onDelete
}: CustomRowComponentProps<OverviewItem>) {
  const { t } = useTranslation();
  const overviewSetup = useOverviewState()[COLLECTIONS.ASSETS];

  const deleteItem = useCallback(async () => {
    await onDelete(item.id);
  }, [item.id, onDelete]);

  const assetsStatuses: ItemStatus[] = useMemo(
    () => [
      {
        icon: <LazySvgIcon name="Planned" />,
        label: t(`assets_overview:${AssetStatusEnum.Planned.toLocaleLowerCase()}`),
        status: AssetStatusEnum.Planned
      },
      {
        icon: <LazySvgIcon name="Testing" />,
        label: t(`assets_overview:${AssetStatusEnum.Testing.toLocaleLowerCase()}`),
        status: AssetStatusEnum.Testing
      },
      {
        icon: <LazySvgIcon name="Completed" />,
        label: t(`assets_overview:${AssetStatusEnum.Active.toLocaleLowerCase()}`),
        status: AssetStatusEnum.Active
      },
      {
        icon: <LazySvgIcon name="On_Halt" />,
        label: t(`assets_overview:${AssetStatusEnum.Inactive.toLocaleLowerCase()}`),
        status: AssetStatusEnum.Inactive
      }
    ],
    [t]
  );

  const deleteDisabled = useMemo(
    () => deletable === false || item.disableActions?.find(({ action }) => action === "remove"),
    [deletable, item.disableActions]
  );

  const editDisabled = useMemo(
    () => item.disableActions?.find(({ action }) => action === "edit"),
    [item.disableActions]
  );

  const handleStatusChange = useCallback(
    async (status: string) => {
      if (!status) {
        return;
      }
      if (status === item.status) {
        return;
      }
      if (!Object.values(ASSET_STATUS).includes(status as ASSET_STATUS_TYPES)) {
        return;
      }
      await updateAsset(item.id, { status: status as ASSET_STATUS_TYPES });
    },
    [item.id, item.status]
  );

  const handleClick = useCallback<MouseEventHandler>(
    event => {
      onClick(item, event);
    },
    [item, onClick]
  );

  return (
    <Box onClick={handleClick} sx={cursorPointer}>
      <ListViewItem
        allowedStatuses={assetsStatuses}
        deletable={!deleteDisabled}
        forceDisplayCheckbox={forceDisplayCheckbox}
        hasCheckbox={checkable}
        icon={item.icon}
        isNew={!item.seen}
        onDelete={deleteItem}
        onSelect={checkable ? onChecked : undefined}
        onStatusChange={editDisabled ? undefined : handleStatusChange}
        rowActions={rowActions}
        searchText={overviewSetup.search}
        selected={checked}
        status={item.status}
        subTitle={item.subTitle}
        title={item.title}
      >
        {item?.resourceAssetTypeId && <Chip label={item.resourceAssetTypeId?.toLocaleUpperCase()} sx={chipSx} />}
        {item?.permission === "read" && <Chip label={t("common:readOnly")} sx={chipSx} />}
      </ListViewItem>
    </Box>
  );
}

const cursorPointer: SxProps = {
  cursor: "pointer"
};

export const chipSx: SxProps = {
  background: "#21212114",
  borderRadius: "40px",
  color: "#6C6C6C",
  fontFamily: "SuisseIntl",
  fontSize: "10px",
  fontWeight: 600,
  height: "24px",
  letterSpacing: "0.5px",
  lineHeight: "12px",
  padding: "6px, 10px, 6px, 10px",
  textAlign: "left"
};
