import React, { useCallback, useEffect, useMemo } from "react";
import { QuestionProps } from "../../Question";
import { useDataTypeTree } from "app/api/dataAssetApi";
import MultiAutocomplete from "components/MultiAutocomplete/MultiAutocomplete";
import { useTranslation } from "react-i18next";
import { PersonGroup } from "app/api/generated/asset-service";

const PersonGroupsQuestion = ({
  questionName,
  value,
  disabled,
  multiSelect,
  multiSelectHiddenIds,
  error,
  helperText,
  onChange,
  onFocus
}: QuestionProps) => {
  const { t } = useTranslation("personGroup");
  const { data } = useDataTypeTree();
  const _value = multiSelect ? (value as string[]) : (value as string);

  const personGroupMap = useMemo(
    () => data?.reduce<Record<string, PersonGroup>>((acc, next) => ({ ...acc, [next.id || ""]: next }), {}),
    [data]
  );
  const personGroupOptionIds = useMemo(
    () => data?.map(({ id }) => id).filter(id => id && !(multiSelectHiddenIds || []).includes(id)) as string[],
    [data, multiSelectHiddenIds]
  );

  const getPersonGroupOptionsLabel = useCallback(
    id =>
      `${t(
        `lists_data_types_categories_person_groups:${personGroupMap?.[id].personGroupKey || ""}`,
        personGroupMap?.[id].personGroupKey
      )}`,
    [personGroupMap, t]
  );

  return (
    <MultiAutocomplete
      label={questionName || t("personGroup:inputLabel")}
      options={personGroupOptionIds || []}
      getOptionLabel={getPersonGroupOptionsLabel}
      hasMultiSelect={multiSelect}
      onChange={onChange}
      disabled={disabled}
      selected={_value}
      error={error}
      helperText={helperText}
      onFocus={onFocus}
    />
  );
};

export default PersonGroupsQuestion;
