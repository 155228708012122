import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useAuthentication } from "../handlers/authentication/authentication-context";
import { useErrorSnackbar } from "../../hook/errorSnackbar";
import { getAllActiveUserRelatedTasks, getAllTasksByDocumentId, markTaskAsDone } from "../handlers/tasksHandler";
import { useParams } from "react-router-dom";

export const TasksContext = createContext({
  loading: true,
  initialized: false,
  reloadUserRelatedTasks: ({ includeDone } = {}) => [],
  reloadDocRelatedTasks: async () => [],
  userTasks: [],
  docTasks: [],
  markAsDoneHook: async () => {}
});

export const TasksProvider = ({ children }) => {
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userTasks, setUserTasks] = useState([]);
  const [docTasks, setDocTasks] = useState([]);
  const { auth } = useAuthentication();

  const params = useParams();
  const documentId = params?.id;
  const { catchAsSnackbar } = useErrorSnackbar();

  const reloadUserRelatedTasks = useCallback(
    ({ includeDone } = {}) => {
      const init = async () => {
        if (auth?.uid) {
          const tasks = await getAllActiveUserRelatedTasks({ includeDone });
          setUserTasks(tasks);
          setLoading(false);
          setInitialized(true);
          return tasks;
        }
      };
      setLoading(true);
      return init();
    },
    [auth?.uid]
  );
  const reloadDocRelatedTasks = useCallback(() => {
    console.log("reloadDocRelatedTasks ", documentId, params);
    const documentIdWithPrefix = documentId.includes("/answerset/") ? documentId : undefined;
    const init = async () => {
      if (auth?.uid) {
        const documentTasks = await getAllTasksByDocumentId(documentId, documentIdWithPrefix);
        setDocTasks(documentTasks);
        setLoading(false);
        setInitialized(true);
      }
    };
    setLoading(true);
    return init();
  }, [auth?.uid, documentId]);

  useEffect(() => {
    if (documentId) {
      reloadDocRelatedTasks().catch(catchAsSnackbar("Failed to load tasks"));
    }
  }, [documentId, catchAsSnackbar, auth, reloadDocRelatedTasks]);

  const markAsDoneHook = useCallback(
    async taskId => {
      setLoading(true);
      await markTaskAsDone(auth.tenantId, auth.uid, taskId);
      await reloadDocRelatedTasks();
    },
    [auth?.tenantId, auth?.uid, reloadDocRelatedTasks]
  );

  return (
    <TasksContext.Provider
      value={{
        loading,
        initialized,
        reloadUserRelatedTasks,
        reloadDocRelatedTasks,
        userTasks,
        docTasks,
        markAsDoneHook
      }}
    >
      {children}
    </TasksContext.Provider>
  );
};

export const useTasks = () => useContext(TasksContext);
