import overviewBaseController, {
  OverviewController,
  OverviewNewItem,
  OverviewResult,
  OverviewSetup
} from "components/Overview/controllers/overviewBaseController";
import { createOverviewItemDefaultName } from "app/utils/create-overview-item-default-name";
import { COLLECTIONS } from "app/collections";
import { useTranslation } from "react-i18next";
import { getOrganizationFilter } from "app/pages/shared/Filters/filters";
import { AxiosInstance } from "axios";
import { getStandardFilter } from "../../shared/Filters/standardFilter";

export interface ProcessorPACollectionParams {
  readonly exportToXLSX?: (ids: string[]) => Promise<void>;
  readonly exportToPdfDialog?: (ids: string[]) => void;
  readonly exportToAttachments?: (ids: string[], idToName: Map<string, string>) => Promise<void>;
}

const ProcessorPAOverviewController = (
  axiosInstance: AxiosInstance,
  collectionParams: ProcessorPACollectionParams
): OverviewController => {
  const { t } = useTranslation();

  const baseController = overviewBaseController(axiosInstance, COLLECTIONS.PROCESSOR_PAS, undefined, []);

  const getFilters = (overviewResult: OverviewResult) => {
    return [
      getStandardFilter({
        filterField: "status",
        standardFilters: [
          { id: "edit", name: t("filter_criteria:status_edit") },
          { id: "approved", name: t("filter_criteria:status_approved") }
        ],
        name: t("processes_overview:status")
      }),
      getOrganizationFilter(
        "processorOrgUnitId",
        overviewResult.orgUnits,
        t,
        t("processor_pa_page:processorOrgUnitId")
      ),
      getStandardFilter({
        filterField: "paIds",
        standardFilters: overviewResult.pas,
        name: t("collection:processes_cap")
      }),
      getStandardFilter({
        filterField: "controllerExternalRecipientIds",
        standardFilters: overviewResult.ers,
        name: t("processor_pa_page:controllerExternalRecipientIds")
      }),
      getOrganizationFilter(
        "controllerOrgUnitIds",
        overviewResult.orgUnits,
        t,
        t("processor_pa_page:controllerOrgUnitIds")
      ),
      getStandardFilter({
        filterField: "processingCategoryIds",
        standardFilters: overviewResult.pcs,
        name: t("processor_pa_page:processingCategoryIds")
      }),
      getStandardFilter({
        filterField: "thirdWorldDataStorageIds",
        standardFilters: overviewResult.ers,
        name: t("processor_pa_page:thirdWorldDataStorageIds")
      }),
      getStandardFilter({
        filterField: "thirdWorldExternalRecipientIds",
        standardFilters: overviewResult.ers,
        name: t("processor_pa_page:thirdWorldExternalRecipientIds")
      }),
      getOrganizationFilter(
        "thirdWorldOrgUnitIds",
        overviewResult.orgUnits,
        t,
        t("processor_pa_page:thirdWorldOrgUnitIds")
      ),
      getStandardFilter({
        filterField: "labelIds",
        standardFilters: overviewResult.pls,
        name: t("filter_criteria:label")
      })
    ];
  };

  const getSortings = () => [
    {
      field: "title",
      type: "asc",
      label: t("filter_criteria:aToZ")
    },
    {
      field: "title",
      type: "desc",
      label: t("filter_criteria:zToA")
    },
    {
      field: "createdAt",
      type: "desc",
      label: t("filter_criteria:newFirst")
    },
    {
      field: "createdAt",
      type: "asc",
      label: t("filter_criteria:oldFirst")
    }
  ];

  const getOverview = async (setup: OverviewSetup) => {
    const data = await baseController.getOverview(setup, "/");

    if (!data) {
      return null;
    }

    return {
      ...data,
      filters: getFilters(data),
      sortings: getSortings()
    };
  };

  const goToItem = (id: string) => {
    const url = `/processor-pas/${id}/general`;
    baseController.goToItem(url);
  };

  const addItem = async (data: OverviewNewItem) => {
    const payload = {
      name: data?.title || createOverviewItemDefaultName(COLLECTIONS.PROCESSOR_PAS)
    };
    return baseController.addItem(payload, `/`);
  };

  const patchItem = async (id: string, data: object) => {
    const title = ("title" in data && (data.title as string)) || "";
    await baseController.patchItem(id, { name: title });
  };

  const deleteItem = async (id: string) => {
    return await baseController.deleteItem(id);
  };

  const addItemAndGo = async (data: OverviewNewItem) => {
    const response = await addItem(data);
    goToItem(response.headers["x-resource-id"]);
  };

  const exportItems = async (format: string, ids: string[], setup: OverviewSetup) => {
    if (format === "pdf") {
      await collectionParams.exportToPdfDialog?.(ids);
    } else if (format === "xlsx") {
      await collectionParams.exportToXLSX?.(ids);
    } else if (format === "attachments") {
      const data = await baseController.getOverview(setup);
      const idToName = (data?.allItems || []).reduce<Map<string, string>>((acc, item) => {
        acc.set(item.id, item.title);
        return acc;
      }, new Map<string, string>());
      await collectionParams.exportToAttachments?.(ids, idToName);
    }
  };

  const exportAllItems = async (format: string, setup: OverviewSetup) => {
    const data = await baseController.getOverview(setup);
    const ids = (data?.allItems || []).map(({ id }) => id);
    if (format === "pdf") {
      await collectionParams.exportToPdfDialog?.(ids);
    } else if (format === "xlsx") {
      await collectionParams.exportToXLSX?.(ids);
    } else if (format === "attachments") {
      await exportItems(format, ids, setup);
    }
  };

  return {
    ...baseController,
    getOverview,
    goToItem,
    addItem,
    patchItem,
    deleteItem,
    addItemAndGo,
    exportItems,
    exportAllItems
  };
};

export default ProcessorPAOverviewController;
