import { useTranslation } from "react-i18next";
import { useTom } from "../../app/contexts/tom-context";
import React, { useCallback, useEffect, useState } from "react";
import { v4 } from "uuid";
import { isEqual } from "lodash-es";
import { CircularProgress } from "@material-ui/core";
import MeasureAccordions from "./MeasureAccordions";
import { tDeletedEntry } from "../../app/handlers/dataTypeTranslatorHandler";
import { MeasureAccordionInput } from "./MeasureAccordion";

export interface MeasureIDAccordionsProps {
  measureIDs: string[];
  onMeasureIDChange: (measureIDs: string[]) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  additionalAction?: React.ReactNode;
  whitelistedTOMIds?: string[];
  whitelistTooltip?: string;
  optionalRiskIdForNewTOM?: string;
  customMeasureGroupingFn?: (tomId: string) => string;
  customMeasureGroupsOrder?: string[];
  disabled?: boolean;
}

export const MeasureIDAccordions = ({
  measureIDs,
  onMeasureIDChange,
  onFocus,
  onBlur,
  additionalAction,
  whitelistedTOMIds,
  whitelistTooltip,
  optionalRiskIdForNewTOM,
  customMeasureGroupingFn,
  customMeasureGroupsOrder,
  disabled
}: MeasureIDAccordionsProps) => {
  const { t } = useTranslation();
  const { tomInitiated, toms } = useTom();

  const [initiated, setInitiated] = useState(false);
  const [measureAccordions, setMeasureAccordions] = useState<MeasureAccordionInput[]>([]);
  useEffect(() => {
    if (!tomInitiated) {
      return;
    }
    setMeasureAccordions(measureAccordions =>
      measureIDs.map((measureId, index) => {
        const sameIndexMeasureAccordion = measureAccordions[index];
        if (sameIndexMeasureAccordion?.tomId === measureId) {
          return sameIndexMeasureAccordion;
        }

        const measure = toms.find(tom => tom.id === measureId);
        return {
          id: v4(),
          tomId: measureId,
          measureName: measure?.name || tDeletedEntry({ t }),
          measureDescription: measure?.description || "",
          protectionObjective: measure?.protectionObjectiveIds || []
        };
      })
    );
    setInitiated(true);
  }, [measureIDs, toms, tomInitiated, t]);

  useEffect(() => {
    if (!initiated) {
      return;
    }

    const measureIDsFromAccordions = measureAccordions
      .map(measure => measure.tomId)
      .filter((measureId): measureId is string => !!measureId);
    if (isEqual(new Set(measureIDs), new Set(measureIDsFromAccordions))) {
      return;
    }

    onMeasureIDChange([...measureIDsFromAccordions]);
  }, [initiated, measureIDs, measureAccordions, onMeasureIDChange]);

  const onSave = useCallback(savedMeasure => {
    setMeasureAccordions(measures => {
      if (!measures.some(measure => measure.id === savedMeasure.id)) {
        return [...measures, savedMeasure];
      }
      return measures.map(measure => (measure.id === savedMeasure.id ? savedMeasure : measure));
    });
  }, []);

  const onDelete = useCallback(deletedMeasure => {
    setMeasureAccordions(measures => measures.filter(measure => measure.id !== deletedMeasure.id));
  }, []);

  if (!initiated) {
    return <CircularProgress />;
  }

  return (
    <MeasureAccordions
      inputMeasures={measureAccordions}
      onSave={onSave}
      onDelete={onDelete}
      addNewText={t("pa_data_flow_measures_risks:add_measure")}
      autoSave={true}
      additionalAction={additionalAction}
      whitelistedTOMIds={whitelistedTOMIds}
      whitelistTooltip={whitelistTooltip}
      optionalRiskIdForNewTOM={optionalRiskIdForNewTOM}
      customMeasureGroupingFn={customMeasureGroupingFn}
      customMeasureGroupsOrder={customMeasureGroupsOrder}
      disabled={disabled}
    />
  );
};
