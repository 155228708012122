import React from "react";
import { useTranslation } from "react-i18next";
import theme from "../../../theme/theme";
import { usePathName } from "../../../app/router/router-custom-hooks";
import { Box, Link, Typography } from "@mui/material";

interface OverviewSelectAllProps {
  readonly numberTotalItems: number;
  readonly numberCheckedItems: number;
  readonly numberCurrentItems?: number | null;
  readonly numberTotalSelectableItems?: number;

  readonly onSelectAll: () => void;
  readonly onClearAll: () => void;
}
export const OverviewSelectAll = ({
  numberTotalItems,
  numberCurrentItems,
  numberCheckedItems,
  numberTotalSelectableItems,

  onSelectAll,
  onClearAll
}: OverviewSelectAllProps) => {
  const sx = {
    root: {
      width: "110%",
      minHeight: "58px",
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      backgroundColor: theme.palette.grey[100],
      marginLeft: "-6.5%"
    },
    link: {
      cursor: "pointer"
    },
    linkText: {
      fontSize: "14px",
      fontWeight: "bold",
      marginLeft: "16px"
    }
  };
  const path = usePathName();
  const onResourcesOverview = path.includes("resources");
  const { t } = useTranslation("overviewSelectAll");

  const currentlySelectedText = t("currentlySelected", {
    numberSelected: numberCheckedItems
  });
  const selectAllText = t("selectAll", {
    numberTotal: onResourcesOverview ? numberTotalSelectableItems : numberTotalItems
  });
  const uncheckAllText = t("clearSelection");

  // for resources only custom items are selectable, so we have different translations
  const currentlySelectedResources = t("currentlySelectedResources", {
    numberSelected: numberCheckedItems,
    numberPage: numberTotalSelectableItems
  });
  const resourcesSelectAllText = selectAllText + t("customEntriesOnly");
  const numberDisplayItems = numberCurrentItems ? numberCurrentItems : numberTotalSelectableItems; // for some reason numberCurrentItems does not always exist
  const showComponent =
    (numberCheckedItems === numberDisplayItems || numberCheckedItems === numberTotalSelectableItems) &&
    numberTotalItems >= 50;

  const showCheckAllText = numberCheckedItems != (onResourcesOverview ? numberTotalSelectableItems : numberTotalItems); // don't show select all text when all are already checked

  const checkAllTextEl = (
    <Box mt={2.2} display={"flex"}>
      <Typography align={"center"}>
        {onResourcesOverview ? currentlySelectedResources : currentlySelectedText}
      </Typography>
      <Link underline={"none"} sx={sx.link} onClick={showCheckAllText ? onSelectAll : onClearAll}>
        <Typography noWrap sx={sx.linkText} color={"primary"}>
          {showCheckAllText ? (onResourcesOverview ? resourcesSelectAllText : selectAllText) : uncheckAllText}
        </Typography>
      </Link>
    </Box>
  );

  if (showComponent) {
    return (
      <Box sx={sx.root}>
        <Box>
          <Box> </Box>
          <Box mx={2} flex={1} display="flex" minWidth={"0"} justifyContent={"center"}>
            {checkAllTextEl}
          </Box>
        </Box>
      </Box>
    );
  } else {
    return <></>;
  }
};
