import { PaletteOptions } from "@material-ui/core/styles/createPalette";
import { mode1 as designColorTokens } from "./designColorTokens.json" assert { type: "object" };

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    green: Partial<Palette["grey"]>;
    blue: Partial<Palette["grey"]>;
    red: Partial<Palette["grey"]>;
    yellow: Partial<Palette["grey"]>;
    orange: Partial<Palette["grey"]>;
    lila: Partial<Palette["grey"]>;
    indigo: Partial<Palette["grey"]>;
    blueGrey: Partial<Palette["grey"]>;
    alert: {
      readonly warning: string;
      readonly warningBackground: string;
      readonly error: string;
      readonly errorBackground: string;
      readonly info: string;
      readonly infoBackground: string;
      readonly success: string;
      readonly successBackground: string;
    };
    logo: {
      readonly logo500: "#f0C197F";
    };
    primary: {
      light: string;
      main: string;
      dark: string;
      contrastText: string;
    };
  }

  interface PaletteOptions {
    green: Partial<Palette["grey"]>;
    blue: Partial<Palette["grey"]>;
    red: Partial<Palette["grey"]>;
    yellow: Partial<Palette["grey"]>;
    orange: Partial<Palette["grey"]>;
    lila: Partial<Palette["grey"]>;
    indigo: Partial<Palette["grey"]>;
    blueGrey: Partial<Palette["grey"]>;
    alert: {
      readonly warning: string;
      readonly warningBackground: string;
      readonly error: string;
      readonly errorBackground: string;
      readonly info: string;
      readonly infoBackground: string;
      readonly success: string;
      readonly successBackground: string;
    };
    logo: {
      readonly logo500: string;
    };
  }
}

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    green: Partial<Palette["grey"]>;
    blue: Partial<Palette["grey"]>;
    red: Partial<Palette["grey"]>;
    yellow: Partial<Palette["grey"]>;
    orange: Partial<Palette["grey"]>;
    lila: Partial<Palette["grey"]>;
    blueGrey: Partial<Palette["grey"]>;
    alert: {
      readonly warning: string;
      readonly warningBackground: string;
      readonly error: string;
      readonly errorBackground: string;
      readonly info: string;
      readonly infoBackground: string;
      readonly success: string;
      readonly successBackground: string;
    };
    logo: {
      readonly logo500: "#f0C197F";
    };
    primary: {
      light: string;
      main: string;
      dark: string;
      contrastText: string;
    };
  }

  interface PaletteOptions {
    green: Partial<Palette["grey"]>;
    blue: Partial<Palette["grey"]>;
    red: Partial<Palette["grey"]>;
    yellow: Partial<Palette["grey"]>;
    orange: Partial<Palette["grey"]>;
    lila: Partial<Palette["grey"]>;
    indigo: Partial<Palette["grey"]>;
    blueGrey: Partial<Palette["grey"]>;
    alert: {
      readonly warning: string;
      readonly warningBackground: string;
      readonly error: string;
      readonly errorBackground: string;
      readonly info: string;
      readonly infoBackground: string;
      readonly success: string;
      readonly successBackground: string;
    };
    logo: {
      readonly logo500: string;
    };
  }
}

const common = {
  black: "#000000de",
  white: "#ffffff"
};

const palette: PaletteOptions = {
  common,
  type: "light",
  primary: {
    light: designColorTokens.indigo400,
    main: designColorTokens.indigo500,
    dark: designColorTokens.indigo600,
    contrastText: common.white
  },
  secondary: {
    light: designColorTokens.purple300,
    main: designColorTokens.purple500,
    dark: designColorTokens.purple700,
    contrastText: common.white
  },
  info: {
    light: designColorTokens.blue50,
    main: designColorTokens.blue500,
    dark: designColorTokens.blue600,
    contrastText: common.white
  },
  error: {
    light: designColorTokens.red50,
    main: designColorTokens.red500,
    dark: designColorTokens.red600,
    contrastText: common.white
  },
  warning: {
    light: designColorTokens.deepOrange300,
    main: designColorTokens.deepOrange500,
    dark: designColorTokens.deepOrange700,
    contrastText: common.white
  },
  success: {
    light: designColorTokens.green50,
    main: designColorTokens.green500,
    dark: designColorTokens.green600,
    contrastText: common.white
  },
  text: {
    primary: designColorTokens.grey800,
    secondary: designColorTokens.grey500,
    disabled: designColorTokens.grey300
  },
  alert: {
    warning: designColorTokens.deepOrange900,
    warningBackground: designColorTokens.deepOrange100,
    error: designColorTokens.red900,
    errorBackground: designColorTokens.red100,
    info: designColorTokens.blue900,
    infoBackground: designColorTokens.blue200,
    success: designColorTokens.green900,
    successBackground: designColorTokens.green100
  },
  logo: {
    logo500: "#0C197F"
  },
  grey: {
    50: designColorTokens.grey50,
    100: designColorTokens.grey100,
    200: designColorTokens.grey200,
    300: designColorTokens.grey300,
    400: designColorTokens.grey400,
    500: designColorTokens.grey500,
    600: designColorTokens.grey600,
    700: designColorTokens.grey700,
    800: designColorTokens.grey800,
    900: designColorTokens.grey900
  },
  green: {
    50: designColorTokens.green50,
    100: designColorTokens.green100,
    200: designColorTokens.green200,
    300: designColorTokens.green300,
    400: designColorTokens.green400,
    500: designColorTokens.green500,
    600: designColorTokens.green600,
    700: designColorTokens.green700
  },
  blue: {
    50: designColorTokens.blue50,
    100: designColorTokens.blue100,
    200: designColorTokens.blue200,
    300: designColorTokens.blue300,
    400: designColorTokens.blue400,
    500: designColorTokens.blue500,
    600: designColorTokens.blue600,
    700: designColorTokens.blue700
  },
  red: {
    50: designColorTokens.red50,
    100: designColorTokens.red100,
    200: designColorTokens.red200,
    300: designColorTokens.red300,
    400: designColorTokens.red400,
    500: designColorTokens.red500,
    600: designColorTokens.red600,
    700: designColorTokens.red700
  },
  yellow: {
    50: designColorTokens.yellow50,
    100: designColorTokens.yellow100,
    200: designColorTokens.yellow200,
    300: designColorTokens.yellow300,
    400: designColorTokens.yellow400,
    500: designColorTokens.yellow500,
    600: designColorTokens.yellow600,
    700: designColorTokens.yellow700
  },
  orange: {
    50: designColorTokens.orange50,
    100: designColorTokens.orange100,
    200: designColorTokens.orange200,
    300: designColorTokens.orange300,
    400: designColorTokens.orange400,
    500: designColorTokens.orange500,
    600: designColorTokens.orange600,
    700: designColorTokens.orange700
  },
  lila: {
    50: designColorTokens.deepPurple50,
    100: designColorTokens.deepPurple100,
    200: designColorTokens.deepPurple200,
    300: designColorTokens.deepPurple300,
    400: designColorTokens.deepPurple400,
    500: designColorTokens.deepPurple500,
    600: designColorTokens.deepPurple600,
    700: designColorTokens.deepPurple700
  },
  indigo: {
    50: designColorTokens.indigo50,
    100: designColorTokens.indigo100,
    200: designColorTokens.indigo200,
    300: designColorTokens.indigo300,
    400: designColorTokens.indigo400,
    500: designColorTokens.indigo500,
    600: designColorTokens.indigo600,
    700: designColorTokens.indigo700
  },
  blueGrey: {
    50: designColorTokens.blueGrey50,
    100: designColorTokens.blueGrey100,
    200: designColorTokens.blueGrey200,
    300: designColorTokens.blueGrey300,
    400: designColorTokens.blueGrey400,
    500: designColorTokens.blueGrey500,
    600: designColorTokens.blueGrey600,
    700: designColorTokens.blueGrey700
  }
};

export default palette;
