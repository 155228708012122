import { TextField } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { QuestionProps } from "../../Question";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash-es";

const TextAreaQuestion = ({
  questionName,
  value,
  disabled = false,
  error,
  helperText,
  required,
  onFocus,
  onBlur,
  onChange
}: QuestionProps) => {
  const { t } = useTranslation("");

  const [_value, _setValue] = useState<string | undefined>("");

  useEffect(() => {
    _setValue((Array.isArray(value) ? value[0] : value) as string);
  }, [value]);

  const onChangeCallback = useCallback(
    text => {
      onChange?.(text);
    },
    [onChange]
  );

  const debouncedOnChange = useMemo(() => debounce(onChangeCallback, 600), [onChangeCallback]);

  useEffect(() => {
    debouncedOnChange(_value);
  }, [_value, debouncedOnChange, onChangeCallback]);

  const updateValueCallback = useCallback((e: any) => {
    _setValue(e.target.value);
  }, []);

  const onBlurCallback = useCallback(() => {
    onBlur?.();
  }, [onBlur]);

  return (
    <TextField
      fullWidth
      required={required}
      maxRows={1000}
      minRows={1}
      multiline
      value={_value}
      onChange={updateValueCallback}
      variant="outlined"
      onFocus={onFocus}
      onBlur={onBlurCallback}
      label={questionName}
      InputLabelProps={inputLabelProps}
      disabled={disabled}
      error={error || (required && _value === "")}
      helperText={helperText ?? (required && _value === "" ? t("fields:required") : "")}
    />
  );
};

const inputLabelProps = {};

export default React.memo(TextAreaQuestion);
