import { Department } from "./departmentHandler";
import { groupBy } from "lodash-es";

export const orgUnitAndChildren = (orgUnitId: string, orgUnits: Department[]) => {
  if (!orgUnitId || orgUnits.length <= 0) {
    return orgUnits;
  }

  const parentIdToOrgUnits = groupBy(orgUnits, "parentId");

  const root = orgUnits.find(orgUnit => {
    return orgUnit.id === orgUnitId;
  });

  if (root) {
    const childrenOrgUnits = [];
    childrenOrgUnits.push(root);

    // recurse data, starting with root node; pass in empty array
    const children = recurseTreeMapData(parentIdToOrgUnits, root, []);
    childrenOrgUnits.push(...children);

    return childrenOrgUnits;
  } else return [];
};

export const expandOrgUnitIds = (orgUnitIds: string[], parentIdToOrgUnits: Map<string, Department[]>): Set<string> => {
  const result = new Set<string>();

  const orgUnitIdsToCheck = [...orgUnitIds];
  const recursedOrgUnitIds = new Set<string>();
  while (orgUnitIdsToCheck.length) {
    const orgUnitId = orgUnitIdsToCheck.pop();
    if (!orgUnitId) {
      break;
    }

    if (recursedOrgUnitIds.has(orgUnitId)) {
      continue;
    }

    result.add(orgUnitId);
    recursedOrgUnitIds.add(orgUnitId);

    const children = parentIdToOrgUnits.get(orgUnitId) || [];
    for (const newOrgUnit of children) {
      orgUnitIdsToCheck.push(newOrgUnit.id);
    }
  }

  return result;
};

function recurseTreeMapData(data: Record<string, Department[]>, root: Department, newData: Department[]) {
  // passing in the root node, get children, recurse until leaf is reached.
  const child = data[root.id] || [];

  if (child.length > 0) {
    if (!newData) {
      newData = [];
    }

    for (let i = 0; i < child.length; i++) {
      newData.push(child[i]);
      // recursve with current child record
      recurseTreeMapData(data, child[i], newData);
    }
  }
  return newData;
}

export const orgUnitsToIdMap = (orgUnits: Department[]): Map<string, Department> => {
  return new Map(orgUnits.map(orgUnit => [orgUnit.id, orgUnit]));
};

export const orgUnitsToParentIdMap = (orgUnits: Department[]): Map<string, Department[]> => {
  return orgUnits.reduce((acc, orgUnit) => {
    const parentId = orgUnit.parentId || "";
    const orgUnits = acc.get(parentId) || [];
    orgUnits.push(orgUnit);
    acc.set(parentId, orgUnits);
    return acc;
  }, new Map<string, Department[]>());
};

export const filterOrgUnits = (
  startOrgUnits: Department[],
  idToOrgUnit: Map<string, Department>,
  parentIdsToOrgUnits: Map<string, Department[]>,
  searchText: string
): {
  readonly foundDepartmentIds: Set<string>;
  readonly fromTopToFoundDepartmentIds: Set<string>;
  readonly fromTopToFoundToLeafDepartmentIds: Set<string>;
  readonly fromTopToFoundToLeafDepartments: Department[];
} => {
  const normalizedSearchText = searchText.toLowerCase();

  const foundDepartmentIds = new Set<string>();
  const fromTopToFoundDepartmentIds = new Set<string>();
  const fromTopToFoundToLeafDepartmentIds = new Set<string>();

  const traverseOrgUnitTillTheEnd = (
    orgUnitId: string,
    prevOrgUnits: Department[],
    callback: (orgUnits: Department[]) => void
  ) => {
    const childOrgUnits = parentIdsToOrgUnits.get(orgUnitId) || [];
    if (!childOrgUnits.length) {
      callback(prevOrgUnits);
      return;
    }

    for (const childOrgUnit of childOrgUnits) {
      traverseOrgUnitTillTheEnd(childOrgUnit.id, [...prevOrgUnits, childOrgUnit], callback);
    }
  };

  const addToFilterSet = (orgUnits: Department[]) => {
    let searchTextAlreadyFound = false;
    const reversedOrgUnits = [...orgUnits].reverse();
    for (const orgUnit of reversedOrgUnits) {
      const matchingText = orgUnit.name.toLowerCase().includes(normalizedSearchText);
      if (matchingText) {
        searchTextAlreadyFound = true;
        foundDepartmentIds.add(orgUnit.id);
        fromTopToFoundDepartmentIds.add(orgUnit.id);
        fromTopToFoundToLeafDepartmentIds.add(orgUnit.id);
        for (const leafOrgUnit of expandOrgUnitIds([orgUnit.id], parentIdsToOrgUnits)) {
          fromTopToFoundToLeafDepartmentIds.add(leafOrgUnit);
        }
        continue;
      }

      if (searchTextAlreadyFound) {
        fromTopToFoundDepartmentIds.add(orgUnit.id);
        fromTopToFoundToLeafDepartmentIds.add(orgUnit.id);
      }
    }
  };

  for (const startOrgUnit of startOrgUnits) {
    traverseOrgUnitTillTheEnd(startOrgUnit.id, [startOrgUnit], addToFilterSet);
  }

  return {
    foundDepartmentIds,
    fromTopToFoundDepartmentIds,
    fromTopToFoundToLeafDepartmentIds,
    fromTopToFoundToLeafDepartments: [...fromTopToFoundToLeafDepartmentIds]
      .map(departmentId => idToOrgUnit.get(departmentId))
      .filter((it): it is Department => !!it)
  };
};
