import React, { useCallback, useMemo, useRef, useState } from "react";
import { Box, Collapse, IconButton, LinearProgress, makeStyles, SvgIcon, Tooltip, Typography } from "@material-ui/core";
import { Delete, Edit, Folder, GetApp, Link, Publish } from "@material-ui/icons";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import DriveFileMoveIcon from "assets/images/icons/driveFileMove.svg";
import { useTranslation } from "react-i18next";
import { getFileIcon } from "app/pages/shared/Attachments/fileAttachmentUtils";
import Uploader from "app/pages/documents/Uploader";
import { OverviewItem, OverviewNewItem } from "components/Overview/controllers/overviewBaseController";
import {
  downloadDocumentFileApi,
  FolderMetaData,
  getDocumentFolderMetaDataApi,
  setDocumentFolderMetaDataApi
} from "app/api/file-storage/documentApi";
import OverviewHighlight from "components/Overview/controls/OverviewHighlight";
import stopEvent from "tool/stopEvent";
import OverviewAdd from "components/Overview/controls/OverviewAdd";
import { OVERVIEW_ACTIONS, useOverviewDispatch } from "app/contexts/overview-context";
import { COLLECTIONS } from "app/collections";
import OrgUnitPickerModal from "app/pages/shared/OrgUnitPickerModal/OrgUnitPickerModal";
import { useSnackbar } from "notistack";
import { useDocumentPermissions } from "hook/useDocumentPermissions";
import { useDocumentUpload } from "hook/useDocumentUpload";
import { useTheme } from "@mui/material";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.primary.main
  },
  iconContainer: {
    display: "flex",
    "& svg": {
      width: 24,
      height: 24
    }
  },
  row: {
    display: "flex",
    alignItems: "center",
    width: "100%"
  },
  actions: {
    display: "flex",
    alignItems: "center",
    visibility: "hidden"
  },
  visible: {
    visibility: "visible"
  },
  disabledIcon: {
    "& svg": {
      opacity: 0.5
    }
  }
}));

interface DocumentOverviewRowProps {
  readonly item: OverviewItem;
  readonly searchTerm?: string;
  readonly setItemToMove?: (item: OverviewItem | null) => void;
  readonly setOpenMoveModal?: (value: boolean) => void;

  readonly onAdd: (data: OverviewNewItem) => void;
  readonly onDelete: () => void;
  readonly onDownload: (item: OverviewItem) => void;
  readonly onRename: (item: OverviewItem) => void;
}

const DocumentOverviewRow = ({
  item,
  searchTerm,
  setItemToMove,
  setOpenMoveModal,

  onAdd,
  onDelete,
  onRename
}: DocumentOverviewRowProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const cls = useStyles();
  const dispatch = useOverviewDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [isAddFolderModeActive, setIsAddFolderModeActive] = useState(false);
  const [hovered, setHovered] = useState(false);

  const reloadOverview = useCallback(async () => {
    const reload = {
      shadowLoading: true,
      reloadOverview: Date.now(),
      reloadMetaview: Date.now()
    };
    dispatch({ type: OVERVIEW_ACTIONS.RELOAD, collection: COLLECTIONS.DOCUMENTS, reload });
  }, [dispatch]);

  const { loading, onUploadStart, onUploadEnd } = useDocumentUpload(reloadOverview);

  const isFolderItem = !item.fileType;

  const [folderToOrgUnits, setFolderToOrgUnits] = useState<{
    readonly selectedOrgUnitIds: string[];
    readonly authorizedOrgUnitIds?: string[];
    readonly availableOrgUnitIds?: string[];
    readonly path: string;
  } | null>(null);

  const handleRenameClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      stopEvent(event);
      onRename(item);
    },
    [item, onRename]
  );

  const handleDeleteClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      stopEvent(event);
      onDelete();
    },
    [onDelete]
  );

  const uploaderRef = useRef<{ triggerFileInput: () => void } | null>(null);

  const handleUploadToFolderClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    stopEvent(event);
    uploaderRef.current?.triggerFileInput();
  }, []);

  const {
    canEditCurrentFolder,
    userFolderPermissionsAll,
    userHasUploadPermission,
    userOrgUnitIds,
    canMoveFileOrFolder,
    canCreateFolder,
    canUploadFile,
    canRenameFileOrFolder,
    canDeleteFileOrFolder
  } = useDocumentPermissions(item);

  const handleOrgUnitsLinkClick = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      stopEvent(event);
      if (item.path.includes("/")) {
        const metadata: FolderMetaData = await getDocumentFolderMetaDataApi(item.path);
        setFolderToOrgUnits({
          authorizedOrgUnitIds: userFolderPermissionsAll ? ["*"] : userOrgUnitIds,
          availableOrgUnitIds: metadata.orgUnitIds,
          selectedOrgUnitIds: item.orgUnitIds,
          path: item.path
        });
      } else {
        setFolderToOrgUnits({
          selectedOrgUnitIds: item.orgUnitIds,
          authorizedOrgUnitIds: userFolderPermissionsAll ? ["*"] : userOrgUnitIds,
          availableOrgUnitIds: userFolderPermissionsAll ? undefined : userOrgUnitIds,
          path: item.path
        });
      }
    },
    [item.orgUnitIds, item.path, userFolderPermissionsAll, userOrgUnitIds]
  );

  const handleConfirmOrgUnitPicker = useCallback(
    async (orgUnitIds: string[]) => {
      try {
        await setDocumentFolderMetaDataApi(folderToOrgUnits?.path || "", orgUnitIds);
      } catch (e) {
        enqueueSnackbar("Can not update folder metadata", { variant: "error" });
      }
      reloadOverview();
    },
    [enqueueSnackbar, folderToOrgUnits?.path, reloadOverview]
  );

  const handleCloseOrgUnitPicker = useCallback(() => setFolderToOrgUnits(null), []);

  const handleOpenCreateFolderInput = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    stopEvent(event);
    setIsAddFolderModeActive(true);
  }, []);

  const handleMoveClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      stopEvent(event);
      setItemToMove?.(item);
      setOpenMoveModal?.(true);
    },
    [item, setItemToMove, setOpenMoveModal]
  );

  const handleCreateFolder = useCallback(
    async ({ title }: OverviewNewItem) => {
      if (title) {
        onAdd({ title: `${item.path}/${title}` });
        setIsAddFolderModeActive(false);
      }
    },
    [item.path, onAdd]
  );

  const handleCloseNewFolderEnter = useCallback(() => {
    setIsAddFolderModeActive(false);
  }, []);

  const handleDownloadClick = useCallback(async () => {
    if (!item.path || !item.fileType) {
      enqueueSnackbar("Invalid item for download", { variant: "error" });
      return;
    }
    try {
      await downloadDocumentFileApi({ filePath: item.path });
    } catch (error) {
      enqueueSnackbar("Failed to download file", { variant: "error" });
    }
  }, [enqueueSnackbar, item.fileType, item.path]);

  const handleHoverStart = useCallback(() => setHovered(true), []);
  const handleHoverEnd = useCallback(() => setHovered(false), []);

  const titleTextElement = useMemo(
    () => (searchTerm ? <OverviewHighlight text={item.title} searchTerm={searchTerm} /> : item.title),
    [searchTerm, item.title]
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      onMouseEnter={handleHoverStart}
      onMouseLeave={handleHoverEnd}
    >
      <Collapse in={loading}>
        <Box mt={4}>
          <LinearProgress />
        </Box>
      </Collapse>
      <Box className={cls.row}>
        <Box marginRight={2} className={cls.iconContainer}>
          {isFolderItem ? <Folder style={{ fill: theme.palette.grey[500] }} /> : getFileIcon(item.title, cls)}
        </Box>
        <Typography noWrap style={{ flex: 1 }}>
          {titleTextElement}
        </Typography>
        <Box className={hovered ? `${cls.actions} ${cls.visible}` : cls.actions}>
          {isFolderItem ? (
            <>
              <Tooltip
                title={canEditCurrentFolder ? t("assets_overview:linkOrganizationText") : t("common:missingPermission")}
              >
                <span>
                  <IconButton onClick={handleOrgUnitsLinkClick} disabled={!canEditCurrentFolder}>
                    <Link />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={canUploadFile ? t("documentCenter:upload") : t("common:missingPermission")}>
                <span>
                  <IconButton onClick={handleUploadToFolderClick} disabled={!canUploadFile}>
                    <Publish />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={canCreateFolder ? t("documentCenter:createFolder") : t("common:missingPermission")}>
                <span>
                  <IconButton onClick={handleOpenCreateFolderInput} disabled={!canCreateFolder}>
                    <CreateNewFolderIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </>
          ) : (
            <>
              <Tooltip title={t("common:download")}>
                <IconButton onClick={handleDownloadClick}>
                  <GetApp />
                </IconButton>
              </Tooltip>
            </>
          )}
          <Tooltip title={canMoveFileOrFolder ? t("documentCenter:move") : t("common:missingPermission")}>
            <span>
              <IconButton onClick={handleMoveClick} disabled={!canMoveFileOrFolder}>
                <SvgIcon className={!canMoveFileOrFolder ? cls.disabledIcon : ""}>
                  <DriveFileMoveIcon />
                </SvgIcon>
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={canRenameFileOrFolder ? t("processes_overview:rename") : t("common:missingPermission")}>
            <span>
              <IconButton onClick={handleRenameClick} disabled={!canRenameFileOrFolder}>
                <Edit />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={canDeleteFileOrFolder ? t("processes_overview:delete") : t("common:missingPermission")}>
            <span>
              <IconButton onClick={handleDeleteClick} disabled={!canDeleteFileOrFolder}>
                <Delete />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      </Box>
      {isFolderItem && (
        <>
          <Collapse in={isAddFolderModeActive}>
            <OverviewAdd
              onAdd={handleCreateFolder}
              onClose={handleCloseNewFolderEnter}
              placeholder={t("documentCenter:enterFolderName")}
            />
            <Box mb={2} />
          </Collapse>
          <Uploader
            disabled={!userHasUploadPermission}
            id={`file-upload-input-${item.title}`}
            onUploadEnd={onUploadEnd}
            onUploadStart={onUploadStart}
            path={item.path}
            ref={uploaderRef}
          />
          <OrgUnitPickerModal
            authorizedOrgUnitIds={folderToOrgUnits?.authorizedOrgUnitIds}
            availableOrgUnitIds={folderToOrgUnits?.availableOrgUnitIds}
            onClose={handleCloseOrgUnitPicker}
            onConfirm={handleConfirmOrgUnitPicker}
            open={Boolean(folderToOrgUnits)}
            selectedDepartmentIds={folderToOrgUnits?.selectedOrgUnitIds || []}
            required={true}
          />
        </>
      )}
    </Box>
  );
};

export default DocumentOverviewRow;
