import { useCallback } from "react";
import ListViewItem from "components/ListViewItem/ListViewItem";
import { OverviewItem } from "components/Overview/controllers/overviewBaseController";
import { CustomRowComponentProps } from "components/Overview/controls/OverviewRow";
import { Box } from "@mui/material";
import { updateAsset } from "app/pages/assets/handler/assetHandler";
import { ASSET_STATUS, ASSET_STATUS_TYPES } from "app/pages/assets/types/AssetTypes";
import Edit from "../../../../assets/images/icons/edit2.svg";
import { useOverviewState } from "app/contexts/overview-context";
import { COLLECTIONS } from "app/collections";
import { SxProps } from "@mui/system/styleFunctionSx";

export default function ProcessesOverviewReadOnlyRow({ item }: CustomRowComponentProps<OverviewItem>) {
  const overviewSetup = useOverviewState()[COLLECTIONS.PROCESSES];

  const handleStatusChange = useCallback(
    async (status: string) => {
      if (!status) return;
      if (status === item.status) return;
      if (!Object.values(ASSET_STATUS).includes(status as ASSET_STATUS_TYPES)) return;
      await updateAsset(item.id, { status: status as ASSET_STATUS_TYPES });
    },
    [item.id, item.status]
  );

  const handleClick = useCallback(() => {
    window.open(`/processes/${item.id}/general`, "_blank");
  }, [item.id]);

  return (
    <Box onClick={handleClick} sx={pointer}>
      <ListViewItem
        deletable={false}
        icon={item.icon}
        leadingIcon={<Edit />}
        onStatusChange={handleStatusChange}
        reserveCheckboxSpace={true}
        searchText={overviewSetup.search}
        hasCheckbox={false}
        status={item.status}
        subTitle={item.subTitle}
        title={item.title}
      />
    </Box>
  );
}

const pointer: SxProps = {
  cursor: "pointer"
};
