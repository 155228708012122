import { AxiosInstance } from "axios";
import { getOrganizationFilter } from "app/pages/shared/Filters/filters";
import overviewBaseController, {
  OverviewItem,
  OverviewNewItem,
  OverviewResult,
  OverviewSetup
} from "components/Overview/controllers/overviewBaseController";
import { COLLECTIONS } from "app/collections";
import i18n from "app/i18n";
import { useUserAndTenantData } from "../../../../handlers/userAndTenant/user-tenant-context";
import { departmentsDecorator } from "../../../../../components/Overview/controllers/decorator/departmentsDecorator";
import { useDataTypeTreeManager } from "app/api/dataAssetApi";
import { OVERVIEW_ACTIONS, useOverviewDispatch } from "app/contexts/overview-context";
import { DataAssetType } from "app/api/generated/asset-service";
import { useTranslation } from "react-i18next";
import { RESOURCE_TYPES } from "app/handlers/resourceHandler";
import { resourcesDecorator } from "components/Overview/controllers/decorator/resourcesDecorator";
import { ResourceDTO } from "app/api/resourceApi";
import { useCallback } from "react";
import { useSnackbar } from "notistack";
import { isAxiosErrorWithCode } from "app/api/axios/axiosErrorHandler";

const PersonGroupsOverviewController = (axiosInstance: AxiosInstance) => {
  const { t } = useTranslation();
  const dispatch = useOverviewDispatch();
  const { actions, mutate } = useDataTypeTreeManager(true);
  const { addToSeenItemsOfUserHook } = useUserAndTenantData();
  const { enqueueSnackbar } = useSnackbar();

  const sortings = [
    {
      field: "title",
      type: "asc",
      label: i18n.t("filter_criteria:aToZ")
    },
    {
      field: "title",
      type: "desc",
      label: i18n.t("filter_criteria:zToA")
    },
    {
      field: "updatedAt",
      type: "desc",
      label: t("filter_criteria:newFirst")
    },
    {
      field: "updatedAt",
      type: "asc",
      label: t("filter_criteria:oldFirst")
    }
  ];

  const translateItem = useCallback(
    (item: OverviewItem) => ({
      ...item,
      title: `${t(`lists_data_types_categories_person_groups:${item.title}`, item.title)}`
    }),
    [t]
  );

  const baseController = overviewBaseController(axiosInstance, COLLECTIONS.PERSON_GROUPS, translateItem, [
    resourcesDecorator,
    departmentsDecorator
  ]);

  const _reload = (input?: { readonly shadowLoading?: boolean }) => {
    const reload = {
      reloadOverview: Date.now(),
      reloadMetaview: Date.now(),
      shadowLoading: input?.shadowLoading
    };
    dispatch({ type: OVERVIEW_ACTIONS.RELOAD, collection: COLLECTIONS.PERSON_GROUPS, reload });
  };

  const _normalize = (items: OverviewItem[], dataClassificationMap: Record<string, ResourceDTO>): OverviewItem[] => {
    return items.map(item => {
      if (!item.dataClassificationId) {
        return {
          ...item,
          title: `${t(`lists_data_types_categories_person_groups:${item.title}`, item.title)}`,
          children: item.children !== undefined ? _normalize(item.children, dataClassificationMap) : item.children
        };
      } else {
        const dataClassificationString =
          item.dataClassificationId === "mixed"
            ? t("resources_data-classification:mixed")
            : t(
                `resources_data-classification:${dataClassificationMap[item.dataClassificationId]?.nameKey}`,
                dataClassificationMap[item.dataClassificationId]?.nameKey
              );

        return {
          ...item,
          subTitle: dataClassificationString,
          children: item.children !== undefined ? _normalize(item.children, dataClassificationMap) : item.children
        };
      }
    });
  };

  const getFilters = (data: OverviewResult) => [getOrganizationFilter("orgUnitIds", data._departments, i18n.t)];

  const getOverview = async (setup: OverviewSetup): Promise<OverviewResult | null> => {
    const data = await baseController.getOverview(setup, "/person-groups/overview");
    if (!data) {
      return null;
    }

    const dataClassificationMap: Record<string, ResourceDTO> = (
      (data._resources[RESOURCE_TYPES.DATA_CLASSIFICATION] as ResourceDTO[]) || []
    ).reduce<Record<string, ResourceDTO>>((acc, next) => ({ ...acc, [next.id]: next }), {});

    return {
      ...data,
      items: _normalize(data?.items || [], dataClassificationMap),
      sortings: sortings,
      filters: getFilters(data)
    };
  };

  const addItem = async (item: OverviewItem) => {
    if (item.parentId) {
      const parent = baseController.getById(item.parentId);
      if (parent) {
        if (parent.assetType === DataAssetType.PersonGroup) {
          await actions.addDataCategory({
            dataCategory: item.title,
            personGroupId: item.parentId
          });
        } else {
          await actions.addDataType({
            dataType: item.title,
            dataCategoryId: item.parentId,
            personGroupId: parent.parentId
          });
        }
      }
    } else {
      await actions.addPersonGroup(item.title);
    }
    await _reload({ shadowLoading: true });
  };

  const patchItem = async (id: string, data: object) => {
    const title = ("title" in data && (data.title as string)) || "";
    if (!title) {
      return;
    }

    const item = baseController.getById(id);
    if (item) {
      if (item.assetType === DataAssetType.PersonGroup) {
        await actions.updatePersonGroup({
          personGroupId: item.id,
          updates: { name: title }
        });
      } else if (item.assetType === DataAssetType.DataCategory) {
        const personGroup = baseController.getById(item.parentId);
        if (personGroup) {
          await actions.updateDataCategory({
            personGroupId: personGroup.id,
            dataCategoryId: item.id,
            updates: { name: title }
          });
        }
      } else if (item.assetType === DataAssetType.DataType) {
        const dataCategory = baseController.getById(item.parentId);
        const personGroup = baseController.getById(dataCategory?.parentId || "");

        if (personGroup && dataCategory) {
          await actions.updateDataType({
            personGroupId: personGroup.id,
            dataCategoryId: dataCategory.id,
            dataTypeId: item.id,
            updates: { name: title }
          });
        }
      }
    }
    await _reload({ shadowLoading: true });
  };

  const deleteItem = async (id: string) => {
    const item = baseController.getById(id);
    if (item) {
      if (item.assetType === DataAssetType.PersonGroup) {
        await actions.deletePersonGroup({ personGroupId: item.id });
      } else if (item.assetType === DataAssetType.DataCategory) {
        const personGroup = baseController.getById(item.parentId);
        if (personGroup) {
          await actions.deleteDataCategory({ personGroupId: personGroup.id, dataCategoryId: item.id });
        }
      } else if (item.assetType === DataAssetType.DataType) {
        const dataCategory = baseController.getById(item.parentId);
        const personGroup = baseController.getById(dataCategory?.parentId || "");

        if (personGroup && dataCategory) {
          await actions.deleteDataType({
            personGroupId: personGroup.id,
            dataCategoryId: dataCategory.id,
            dataTypeId: item.id
          });
        }
      }
    }
    await _reload();
  };

  const goToItem = async () => {
    // do nothing
  };

  const addItemAndGo = async ({ title }: OverviewNewItem): Promise<string> => {
    const id = await actions.addPersonGroup(title || "");
    await addToSeenItemsOfUserHook(COLLECTIONS.RESOURCES, id);
    return id;
  };

  const validateItem = (data: object) => {
    const title = ("title" in data && (data.title as string)) || "";
    if (!title) {
      return "Invalid";
    }
    return null;
  };

  const onDragOver = (draggableItem: OverviewItem, droppableItem: OverviewItem) => {
    if (draggableItem.assetType === DataAssetType.DataCategory) {
      return droppableItem.assetType === DataAssetType.PersonGroup && droppableItem.id !== draggableItem.parentId;
    } else if (draggableItem.assetType === DataAssetType.DataType) {
      return droppableItem.assetType === DataAssetType.DataCategory && droppableItem.id !== draggableItem.parentId;
    }
  };

  const onDragEnd = async (draggableItem: OverviewItem, droppableItem: OverviewItem) => {
    if (draggableItem.assetType === DataAssetType.DataCategory) {
      try {
        await actions.moveDataCategory({
          formerPersonGroupId: draggableItem.id,
          dataCategoryId: draggableItem.id,
          newPersonGroupId: droppableItem.id
        });
        await _reload();
      } catch (error) {
        if (isAxiosErrorWithCode(error, 409)) {
          enqueueSnackbar(`${t("error_messages:generic_already_exists")}`, { variant: "error" });
        }
      }
    } else if (draggableItem.assetType === DataAssetType.DataType) {
      const personGroupId = baseController.getById(draggableItem.parentId)?.id;
      if (personGroupId) {
        try {
          await actions.moveDataType({
            personGroupId: personGroupId,
            formerDataCategoryId: draggableItem.parentId,
            dataTypeId: draggableItem.id,
            newDataCategoryId: droppableItem.id
          });
          await _reload();
        } catch (error) {
          if (isAxiosErrorWithCode(error, 409)) {
            enqueueSnackbar(`${t("error_messages:generic_already_exists")}`, { variant: "error" });
          }
        }
      }
    }
  };

  const markAllAsRead = async (): Promise<void> => {
    await baseController.markAllAsRead?.();
    mutate();
  };

  return {
    ...baseController,
    addItem,
    patchItem,
    deleteItem,
    getOverview,
    goToItem,
    addItemAndGo,
    validateItem,
    onDragOver,
    onDragEnd,
    markAllAsRead
  };
};

export default PersonGroupsOverviewController;
