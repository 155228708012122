import { createApi, deleteApi, getApi, updateApi } from "../../../api/assetApi";
import { AssetCreateDTO, AssetUpdateDTO } from "../types/AssetTypes";
import { isAxiosErrorWithCode } from "../../../api/axios/axiosErrorHandler";

export type AssetFilterProps = {
  readonly assetIds?: string[];
  readonly orgUnitIds?: string[];
  readonly statuses?: string[];
  readonly resourceAssetTypeIds?: string[];
  readonly dataLocationIds?: string[];
};

export interface AssetShortProps {
  readonly id: string;
  readonly name: string;
  readonly status: string;
  readonly mainOrgUnitId: string;
}

export const createAsset = async (payload: AssetCreateDTO): Promise<string> => {
  return await createApi(payload);
};

export const getAsset = async (id: string) => {
  try {
    return await getApi(id);
  } catch (error) {
    if (isAxiosErrorWithCode(error, 404)) {
      return null;
    }
    throw error;
  }
};

export const getAssets = async (filter: AssetFilterProps = {}) => {
  try {
    return await getApi(null, filter);
  } catch (error) {
    if (isAxiosErrorWithCode(error, 404)) {
      return null;
    }
    throw error;
  }
};

export const updateAsset = async (id: string, asset: AssetUpdateDTO) => {
  try {
    return await updateApi(id, asset);
  } catch (error) {
    if (isAxiosErrorWithCode(error, 404)) {
      return null;
    }
    throw error;
  }
};

export const deleteAsset = async (id: string) => {
  try {
    await deleteApi(id);
  } catch (error) {
    if (isAxiosErrorWithCode(error, 404)) {
      return null;
    }
    throw error;
  }
};
