import React, { useCallback, useEffect, useMemo, useState } from "react";
import TreeItem from "@material-ui/lab/TreeItem";
import TreeView from "@material-ui/lab/TreeView";
import Checkbox from "@material-ui/core/Checkbox";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { alpha, withStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { Department } from "../../../handlers/departmentHandler";
import { createNaturalSorter } from "../../../utils/naturalSort";
import { TextField } from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import { SxProps } from "@mui/system/styleFunctionSx";
import { OutlinedInputProps } from "@mui/material/OutlinedInput";
import { useTranslation } from "react-i18next";
import { expandOrgUnitIds, filterOrgUnits, orgUnitsToParentIdMap } from "../../../handlers/orgUnitHandler";
import TextBody2 from "../../../../components/TextBody2/TextBody2";

const StyledTreeItemWithCheckbox = withStyles((theme: any) => ({
  group: {
    marginLeft: -22,
    borderLeft: `1px solid ${alpha(theme.palette.text.primary, 0.15)}`
  }
}))(TreeItem);

function TreeNodeLabel({ node, searchText }: { readonly node: Department; readonly searchText?: string }) {
  if (searchText && searchText.length) {
    const index = node.name.toLowerCase().indexOf(searchText.toLowerCase());
    if (index !== -1) {
      return (
        <span>
          {node.name.substring(0, index)}
          <strong>{node.name.substring(index, index + searchText.length)}</strong>
          {node.name.substring(index + searchText.length)}
        </span>
      );
    }
  }
  return <span>{node.name}</span>;
}

function TreeNode({
  node,
  parentToChildDepartments,
  availableDepartmentIds,
  checkedDepartmentIds,
  onChecked,
  searchText
}: {
  readonly node: Department;
  readonly parentToChildDepartments: Map<string, Department[]>;
  readonly availableDepartmentIds?: Set<string>;
  readonly checkedDepartmentIds: Set<string>;
  readonly onChecked: (department: Department, checked: boolean) => void;
  readonly searchText?: string;
}) {
  const disabled = availableDepartmentIds && !availableDepartmentIds.has(node.id);
  const children = parentToChildDepartments.get(node.id) || [];
  const naturalSorter = createNaturalSorter();
  const checked = checkedDepartmentIds.has(node.id);
  const handleCheckboxChange = useCallback(() => {
    onChecked(node, !checked);
  }, [node, onChecked, checked]);

  if (!node?.id) {
    return <div>An error occurred, no data was found.</div>;
  }

  return (
    <Box display="flex" data-testid={`org-unit-tree-node-${node.id}${disabled ? "-disabled" : ""}`}>
      <Box>
        <Checkbox
          color="primary"
          checked={checked}
          onChange={handleCheckboxChange}
          disabled={disabled}
          data-testid={`org-unit-tree-node-${node.id}-checkbox-${checked ? "checked" : "unchecked"}`}
        />
      </Box>
      <Box pt={1.125}>
        <StyledTreeItemWithCheckbox nodeId={node.id} label={<TreeNodeLabel node={node} searchText={searchText} />}>
          {children
            .sort((a, b) => naturalSorter(a.name, b.name))
            .map(child => (
              <TreeNode
                key={child.id}
                node={child}
                parentToChildDepartments={parentToChildDepartments}
                availableDepartmentIds={availableDepartmentIds}
                checkedDepartmentIds={checkedDepartmentIds}
                onChecked={onChecked}
                searchText={searchText}
              />
            ))}
        </StyledTreeItemWithCheckbox>
      </Box>
    </Box>
  );
}

export default function OrgUnitTree({
  allDepartments,
  parentToChildDepartments,
  availableDepartmentIds,
  checkedDepartmentIds,
  onCheckedChanged
}: {
  readonly allDepartments: Map<string, Department>;
  readonly parentToChildDepartments: Map<string, Department[]>;
  readonly availableDepartmentIds: Set<string>;
  readonly checkedDepartmentIds: Set<string>;
  readonly onCheckedChanged: (input: Set<string>) => void;
}) {
  const { t } = useTranslation("");

  const topLevelDepartments = useMemo<Department[]>(() => {
    const rootDepartments = parentToChildDepartments.get("");
    if (rootDepartments) {
      return rootDepartments;
    } else {
      return [...allDepartments.values()].filter(({ parentId }) => !allDepartments.get(parentId || ""));
    }
  }, [parentToChildDepartments, allDepartments]);

  const [searchText, setSearchText] = useState("");
  const onSearchTextChanged = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  }, []);
  const [filteredDepartments, setFilteredDepartments] = useState<Department[]>([]);
  const [filteredDepartmentIds, setFilteredDepartmentIds] = useState<Set<string>>(new Set());
  const [defaultExpandedIds, setDefaultExpandedIds] = useState<string[]>([]);
  const [forceReRenderKey, setForceReRenderKey] = useState(0);
  const filteredParentToChildDepartments = useMemo(
    () => orgUnitsToParentIdMap(filteredDepartments),
    [filteredDepartments]
  );

  useEffect(() => {
    if (!searchText) {
      setFilteredDepartments([...allDepartments.values()]);
      const departmentIds = [...allDepartments.values()].map(({ id }) => id);
      setFilteredDepartmentIds(new Set(departmentIds));
      setDefaultExpandedIds(topLevelDepartments.map(({ id }) => id));
      setForceReRenderKey(it => it + 1);
      return;
    }

    const timeoutId = setTimeout(() => {
      const {
        foundDepartmentIds,
        fromTopToFoundDepartmentIds,
        fromTopToFoundToLeafDepartmentIds,
        fromTopToFoundToLeafDepartments
      } = filterOrgUnits(topLevelDepartments, allDepartments, parentToChildDepartments, searchText);
      setFilteredDepartments(fromTopToFoundToLeafDepartments);
      setFilteredDepartmentIds(fromTopToFoundToLeafDepartmentIds);
      const fromTopToFoundExclusive = [...fromTopToFoundDepartmentIds].filter(
        departmentId => !foundDepartmentIds.has(departmentId)
      );
      setDefaultExpandedIds(fromTopToFoundExclusive);
      setForceReRenderKey(it => it + 1);
    }, 300);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [allDepartments, parentToChildDepartments, searchText, topLevelDepartments]);

  const onChecked = useCallback(
    (department, checked) => {
      const impactedDepartmentIDs = new Set(
        [...expandOrgUnitIds([department.id], parentToChildDepartments)].filter(departmentId =>
          filteredDepartmentIds.has(departmentId)
        )
      );
      if (checked) {
        onCheckedChanged(new Set([...checkedDepartmentIds, ...impactedDepartmentIDs]));
      } else {
        onCheckedChanged(
          new Set([...checkedDepartmentIds].filter(departmentId => !impactedDepartmentIDs.has(departmentId)))
        );
      }
    },
    [parentToChildDepartments, filteredDepartmentIds, onCheckedChanged, checkedDepartmentIds]
  );

  return (
    <Box>
      <Box width="100%">
        <TextField
          value={searchText}
          onChange={onSearchTextChanged}
          size="small"
          sx={textFieldSx}
          InputProps={textFieldInputProps}
          placeholder={t("common:search")}
          fullWidth={true}
        />
      </Box>
      <Box m={3} width="100%">
        {filteredDepartmentIds?.size ? (
          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            key={`treeview-${forceReRenderKey}`}
            defaultExpanded={defaultExpandedIds}
          >
            {topLevelDepartments.map(node => (
              <TreeNode
                key={node.id}
                node={node}
                parentToChildDepartments={filteredParentToChildDepartments}
                availableDepartmentIds={availableDepartmentIds}
                checkedDepartmentIds={checkedDepartmentIds}
                onChecked={onChecked}
                searchText={searchText}
              />
            ))}
          </TreeView>
        ) : (
          <TextBody2 text={t("overview:no_entries_found")} />
        )}
      </Box>
    </Box>
  );
}

const textFieldSx: SxProps = {};

const textFieldInputProps: Partial<OutlinedInputProps> = {
  startAdornment: (
    <SearchOutlined
      sx={{
        color: "#23252980",
        marginRight: "8px"
      }}
    />
  )
};
