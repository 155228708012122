import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState } from "react";
import {
  AnswersetOverviewItem,
  patchAnswerSetApi,
  postContributorExternalUserAPi
} from "../../../../app/api/assessmentApi";
import ListViewItem, { ItemStatus } from "components/ListViewItem/ListViewItem";
import { CustomRowComponentProps } from "components/Overview/controls/OverviewRow";
import { ANSWERSET_STATUS, AUDIT_METHODOLOGY } from "../../../../app/pages/audits/audit/AuditTypes";
import colors from "theme/palette/colors";
import { OVERVIEW_ACTIONS, useOverviewDispatch } from "app/contexts/overview-context";
import { COLLECTIONS } from "../../../../app/collections";
import { Chip, Tooltip } from "@mui/material";
import { UserAvatarProps } from "components/UserAvatar/UserAvatar";
import { AddContributorDialog } from "app/pages/audits/assessment/dialog/AddContributorDialog";
import { LazySvgIcon } from "components/LazySvgIcon/LazySvgIcon";
import UserAvatarList from "components/UserAvatar/UserAvatarList";
import { ExternalUserDTO } from "app/api/user/userApi";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import { isAxiosErrorWithCode } from "app/api/axios/axiosErrorHandler";
import { sendNotificationApi } from "app/api/userNotificationApi";

const sx = {
  chipStyle: {
    padding: "6px 10px",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    height: "24px",
    color: "#6C6C6C",
    backgroundColor: "#eeeeee",
    "& .MuiChip-label": {
      fontSize: "11px",
      lineHeight: "12px",
      textTransform: "uppercase",
      fontWeight: 600,
      color: "#6C6C6C",
      backgroundColor: "#eeeeee",
      padding: 0,
      fontFamily: "SuisseIntl"
    }
  },
  addButton: {
    borderRadius: "50%",
    border: `1px dashed ${colors.grey.grey500}`,
    width: "24px",
    height: "24px",
    alignItems: "center",
    justifyContent: "center",
    padding: 1
  }
};

export default function AssessmentResponseOverviewRow({
  item,
  checkable,
  checked,
  onCustomRowClick,
  forceDisplayCheckbox,
  onDelete,
  onChecked
}: CustomRowComponentProps<AnswersetOverviewItem>) {
  const { t } = useTranslation();
  const dispatch = useOverviewDispatch();
  const { reloadTenantUsers } = useUserAndTenantData();

  const [openAddContributorDialog, setOpenAddContributorDialog] = useState<boolean>(false);
  const [addExternalUserErrors, setAddExternalUserErrors] = useState<Record<string, string>>({});

  const reloadOverview = useCallback(async () => {
    const reload = {
      shadowLoading: true,
      reloadOverview: Date.now(),
      reloadMetaview: Date.now()
    };
    dispatch({ type: OVERVIEW_ACTIONS.RELOAD, collection: COLLECTIONS.ASSESSMENT_RESPONSE, reload });
  }, [dispatch]);
  const answerSetStatuses: ItemStatus[] = useMemo(
    () => [
      {
        icon: <LazySvgIcon name="Draft" />,
        label: t(`assessment_details:${ANSWERSET_STATUS.PENDING}`),
        status: ANSWERSET_STATUS.PENDING
      },
      {
        icon: <LazySvgIcon name="In_Progress" />,
        label: t(`dashboardCharts:${ANSWERSET_STATUS.INPROGRESS}`),
        status: ANSWERSET_STATUS.INPROGRESS
      },
      {
        icon: <LazySvgIcon name="Completed" />,
        label: t(`audit_status:${ANSWERSET_STATUS.COMPLETED}`),
        status: ANSWERSET_STATUS.COMPLETED
      }
    ],
    [t]
  );

  const onDeleteItem = useCallback(async () => {
    await onDelete(item.id);
  }, [onDelete, item.id]);

  const deleteDisabled = useMemo(
    () => item.disableActions?.find(({ action }) => action === "remove"),
    [item.disableActions]
  );
  const editDisabled = useMemo(
    () => item.disableActions?.find(({ action }) => action === "edit"),
    [item.disableActions]
  );
  const onRowClick = useCallback(() => {
    if (onCustomRowClick) onCustomRowClick();
  }, [onCustomRowClick]);

  /* CONTRIBUTOR DIALOG */
  const onAddContributorOpen = useCallback(() => {
    setOpenAddContributorDialog(true);
  }, []);
  const onConfirmAddInternalContributor = useCallback(
    async (id: string) => {
      setAddExternalUserErrors({});
      if (item.participantsUIDs.includes(id) || item.assigneeUID === id) {
        setAddExternalUserErrors(current => ({ ...current, email: t("common:duplicateEmail") }));
        return;
      }
      try {
        const payload = {
          participantsUIDs: [...item.participantsUIDs, id]
        };
        await patchAnswerSetApi({ auditId: item.auditId, id: item.id || "", payload });
        if (item.auditMethodology !== AUDIT_METHODOLOGY.INTERVIEW) {
          const notificationObj = {
            title: "audit_assignment",
            receivers: [id],
            pageId: "general",
            collection: COLLECTIONS.ASSESSMENT_RESPONSE,
            docId: `${item.auditId}/answerset/${item.id}`, // this for generic link notification email
            docName: item.title || "",
            message: ""
          };
          await sendNotificationApi(notificationObj);
        }
        reloadOverview();
        setOpenAddContributorDialog(false);
      } catch (e) {
        if (isAxiosErrorWithCode(e, 409)) {
          setAddExternalUserErrors(current => ({ ...current, email: t("common:duplicateEmail") }));
        }
      }
    },
    [
      item.auditId,
      item.id,
      item.participantsUIDs,
      reloadOverview,
      item.title,
      item.auditMethodology,
      t,
      item.assigneeUID
    ]
  );
  const onConfirmAddExternalContributor = useCallback(
    async (externalUser: ExternalUserDTO) => {
      setAddExternalUserErrors({});
      try {
        await postContributorExternalUserAPi({
          auditId: item.auditId,
          answersetId: item.id || "",
          payload: externalUser
        });
        await reloadTenantUsers();
        reloadOverview();
        setOpenAddContributorDialog(false);
      } catch (e) {
        if (isAxiosErrorWithCode(e, 409)) {
          setAddExternalUserErrors(current => ({ ...current, email: t("common:duplicateEmail") }));
        }
      }
    },
    [item.auditId, item.id, reloadOverview, reloadTenantUsers, t]
  );
  const onCancelAddContributor = useCallback(() => {
    setOpenAddContributorDialog(false);
    setAddExternalUserErrors({});
  }, []);

  const onRemoveContributor = useCallback(
    async (id: string) => {
      const payload = {
        participantsUIDs: item.participantsUIDs.filter((_id: string) => _id !== id)
      };
      await patchAnswerSetApi({ auditId: item.auditId, id: item.id || "", payload });
      reloadOverview();
    },
    [item.auditId, item.id, item.participantsUIDs, reloadOverview]
  );

  /* CONTRIBUTORS EL */
  const avatarListEl = useMemo(() => {
    if (item.auditMethodology === AUDIT_METHODOLOGY.INTERVIEW) {
      return <></>;
    }
    const avatars = item.participantsUIDs.map(
      (contributorId: string) =>
        ({
          userId: contributorId,
          size: "small",
          color: colors.blue.blue400,
          allowRemove: true
        }) as UserAvatarProps
    );
    return <UserAvatarList avatars={avatars} onRemove={onRemoveContributor} onAdd={onAddContributorOpen} />;
  }, [item.auditMethodology, item.participantsUIDs, onAddContributorOpen, onRemoveContributor]);

  const updatedAtEl = useMemo(() => {
    if (item.updatedAt) {
      return (
        <Tooltip title={t("common:updated")}>
          <Chip sx={sx.chipStyle} label={new Date(item.updatedAt).toLocaleDateString()} />
        </Tooltip>
      );
    }
    return <></>;
  }, [item.updatedAt, t]);

  return (
    <>
      <ListViewItem
        title={item.title}
        onClick={onRowClick}
        icon={item.icon}
        status={item.status}
        allowedStatuses={answerSetStatuses}
        onDelete={onDeleteItem}
        deletable={!deleteDisabled}
        onSelect={checkable ? onChecked : undefined}
        selected={checked}
        forceDisplayCheckbox={forceDisplayCheckbox}
      >
        {avatarListEl}
        {updatedAtEl}
        {editDisabled && <Chip title={t("common:readOnly")} sx={sx.chipStyle} label={t("common:readOnly")} />}
      </ListViewItem>
      <AddContributorDialog
        auditMethodology={item.auditMethodology}
        open={openAddContributorDialog}
        errors={addExternalUserErrors}
        onCancel={onCancelAddContributor}
        onConfirmInternal={onConfirmAddInternalContributor}
        onConfirmExternal={onConfirmAddExternalContributor}
      />
    </>
  );
}
