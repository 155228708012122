import { Box, Typography, useTheme } from "@mui/material";
import {
  AssessmentAnswersetDTO,
  getAnswerSetsApi,
  patchAnswerSetApi,
  postContributorExternalUserAPi,
  getSingleAnswerSetApi,
  postAnswerForAnswerSet
} from "app/api/assessmentApi";
import MetaView, { META_VIEW_TABS, META_VIEW_TABS_TYPES } from "components/MetaView/MetaView";
import ItemPagination from "components/ViewerModal/ItemPagination";
import ViewerModal from "components/ViewerModal/ViewerModal";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import AssignToIcon from "assets/images/icons/assignment-ind.svg";
import GroupAddIcon from "assets/images/icons/group-add.svg";
import ProgressIcon from "assets/images/icons/slow-motion-video.svg";
import InProgressStatusIcon from "assets/images/icons/In_Progress.svg";
import colors from "theme/palette/colors";
import UserAvatar, { UserAvatarProps } from "components/UserAvatar/UserAvatar";
import { AddContributorDialog } from "../dialog/AddContributorDialog";
import { LazySvgIcon } from "components/LazySvgIcon/LazySvgIcon";
import UserAvatarList from "components/UserAvatar/UserAvatarList";
import { COLLECTIONS } from "app/collections";
import { AUDIT_METHODOLOGY, AUDIT_METHODOLOGY_TYPES } from "../../audit/AuditTypes";
import { ExternalUserDTO } from "app/api/user/userApi";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import { AuditQuestionsRender } from "../../designer/AuditQuestionsRender";
import { AuditQuestionWithAnswerAndRemarkDTO } from "app/api/auditApi";
import { isAxiosErrorWithCode } from "app/api/axios/axiosErrorHandler";
import CustomAlert from "components/CustomAlert/CustomAlert";
import { Button } from "@material-ui/core";
import { sendNotificationApi } from "app/api/userNotificationApi";

const metaViewTabIds: META_VIEW_TABS_TYPES[] = [META_VIEW_TABS.TODOS, META_VIEW_TABS.COMMENTS];

interface ParticipantViewerProps {
  readonly open: boolean;
  readonly auditTitle: string;
  readonly auditId: string;
  readonly auditMethodology: AUDIT_METHODOLOGY_TYPES;
  readonly currentParticipantId: string;
  readonly currentParticipantIds: string[];

  readonly onClose: () => void;
  readonly onSubmit?: () => void;
}

const ParticipantViewer = ({
  open,
  auditTitle,
  auditId,
  auditMethodology,
  currentParticipantId,
  currentParticipantIds,
  onClose,
  onSubmit
}: ParticipantViewerProps) => {
  const theme = useTheme();
  const sx = {
    root: {
      padding: 1
    },
    row: {
      borderTop: `1px solid ${colors.divider}`,
      display: "flex",
      alignItems: "center",
      padding: "16px 16px 16px 0"
    },
    rowLeft: {
      alignItems: "center",
      width: "30%",
      display: "flex"
    },
    rowRight: {
      alignItems: "center",
      display: "flex"
    },
    contributors: {
      display: "flex",
      gap: 1
    },
    addContributorButton: {
      width: "24px",
      height: "24px",
      border: `1px dashed ${theme.palette.grey[500]}`
    },
    yourInformation: {
      marginTop: 2
    }
  };
  const { t } = useTranslation("audit_details");
  const { reloadTenantUsers } = useUserAndTenantData();

  /* STATE */
  const [data, setData] = useState<AssessmentAnswersetDTO[]>([]);
  const [currentAnswerIndex, setCurrentAnswerIndex] = useState<number>(1);
  const [openAddContributorDialog, setOpenAddContributorDialog] = useState<boolean>(false);
  const [currentAnswer, setCurrentAnswer] = useState<AssessmentAnswersetDTO | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [questions, setQuestions] = useState<AuditQuestionWithAnswerAndRemarkDTO[]>([]);
  const [addExternalUserErrors, setAddExternalUserErrors] = useState<Record<string, string>>({});

  /* FETCH DATA */
  const fetch = useCallback(async () => {
    const _data = await getAnswerSetsApi({ auditId });

    // sort list of answerSet depends on overview item sorder
    (_data || []).sort((a, b) => {
      return currentParticipantIds.indexOf(a.id) - currentParticipantIds.indexOf(b.id);
    });

    const index: number | undefined = _data?.map(({ id }) => id).indexOf(currentParticipantId);

    if (_data && index !== undefined && index > -1) {
      setCurrentAnswer(_data[index] || null);
      setCurrentAnswerIndex(index + 1);
    } else {
      setCurrentAnswer(_data?.[0] || null);
      setCurrentAnswerIndex(1);
    }
    setData(_data || []);
    setIsLoading(false);
  }, [auditId, currentParticipantId, currentParticipantIds]);

  useEffect(() => {
    if (open && auditId) {
      fetch();
    }
  }, [auditId, fetch, open]);

  const loadQuestionnaire = useCallback(async () => {
    if (currentAnswer) {
      const data = await getSingleAnswerSetApi({ auditId: auditId, id: currentAnswer.id });
      if (data) {
        setQuestions(data.questions);
      }
    }
  }, [currentAnswer, auditId]);

  useEffect(() => {
    if (open && auditId) {
      loadQuestionnaire();
    }
  }, [loadQuestionnaire, auditId, open]);

  const metaViewId = useMemo(() => `${auditId}/answerset/${currentAnswer?.id}`, [currentAnswer, auditId]);
  /* META VIEW */
  const metaviewEl = useMemo(
    () => (
      <MetaView
        tabs={metaViewTabIds}
        docId={metaViewId}
        collection={COLLECTIONS.ASSESSMENT_RESPONSE}
        pageId={"questionnaire"}
        docName={currentAnswer?.title}
      />
    ),
    [metaViewId, currentAnswer?.title]
  );

  /* PAGINATION */
  const changePageCallback = useCallback(
    (pageNumber: number) => {
      setCurrentAnswerIndex(pageNumber);
      setCurrentAnswer(data[pageNumber - 1]);
    },
    [data]
  );
  const itemPagination = useMemo(
    () => (
      <ItemPagination
        total={data.length}
        current={currentAnswerIndex}
        onChange={changePageCallback}
        itemName={`${t("assessment_answerset:responses")}`}
      />
    ),
    [changePageCallback, currentAnswerIndex, data.length, t]
  );

  /* PARTICIPANT */
  const participantEl = useMemo(() => {
    return (
      <Box sx={sx.row}>
        <Box sx={sx.rowLeft}>
          <AssignToIcon />
          <Box mr={2} />
          <Typography>{t("add_participant:participant")}</Typography>
        </Box>
        <Box sx={sx.rowRight}>
          <UserAvatar userId={currentAnswer?.assigneeUID || ""} size="small" />
          <Box mr={2} />
          <Typography variant="body2">{currentAnswer?.title}</Typography>
        </Box>
      </Box>
    );
  }, [currentAnswer?.assigneeUID, currentAnswer?.title, sx.row, sx.rowLeft, sx.rowRight, t]);

  /* ALERT */
  const customAlertEl = useMemo(() => {
    return (
      currentAnswer?.auditMethodology === AUDIT_METHODOLOGY.SELF_ASSESSMENT &&
      currentAnswer?.status !== "completed" && (
        <Box sx={sx.row}>
          <CustomAlert severity={"info"} style={{ width: "100%", justifyContent: "center" }}>
            <Typography align="center">{t("add_participant:awaitingResponse")}</Typography>
          </CustomAlert>
        </Box>
      )
    );
  }, [currentAnswer, t, sx.row]);

  const onSubmitCallback = useCallback(async () => {
    const payload = {
      status: "completed"
    };
    await patchAnswerSetApi({ auditId: currentAnswer?.auditId || "", id: currentAnswer?.id || "", payload });
    onSubmit?.();
  }, [currentAnswer, onSubmit]);

  /* SUBMIT BUTTON */
  const submitButtonEl = useMemo(() => {
    return (
      currentAnswer?.auditMethodology === AUDIT_METHODOLOGY.INTERVIEW && (
        <Box sx={sx.row} justifyContent="flex-end" mt={-4}>
          <Button
            style={{ marginRight: "8px", marginTop: "8px" }}
            variant="outlined"
            color="primary"
            onClick={onClose}
            disabled={currentAnswer?.status === "completed"}
          >
            {t("common:save_draft")}
          </Button>
          <Button
            style={{ marginTop: "8px" }}
            variant="contained"
            color="primary"
            onClick={onSubmitCallback}
            disabled={currentAnswer?.status === "completed"}
          >
            {t("assessment_answerset:complete")}
          </Button>
        </Box>
      )
    );
  }, [currentAnswer, t, onSubmitCallback, onClose, sx.row]);

  /* CONTRIBUTOR */
  const onShowContributorAddDialog = useCallback(() => setOpenAddContributorDialog(true), []);
  const onCloseContributorAddDialog = useCallback(() => {
    setOpenAddContributorDialog(false);
    setAddExternalUserErrors({});
  }, []);
  const onConfirmAddInternalContributor = useCallback(
    async (id: string) => {
      setAddExternalUserErrors({});
      if (currentAnswer?.participantsUIDs.includes(id) || currentAnswer?.assigneeUID === id) {
        setAddExternalUserErrors(current => ({ ...current, email: t("common:duplicateEmail") }));
        return;
      }
      try {
        onCloseContributorAddDialog();
        const payload = {
          participantsUIDs: [...(currentAnswer?.participantsUIDs || []), id]
        };
        await patchAnswerSetApi({ auditId, id: currentAnswer?.id || "", payload });
        if (currentAnswer?.auditMethodology !== AUDIT_METHODOLOGY.INTERVIEW) {
          const notificationObj = {
            title: "audit_assignment",
            receivers: [id],
            pageId: "general",
            collection: COLLECTIONS.ASSESSMENT_RESPONSE,
            docId: `${auditId}/answerset/${currentAnswer?.id}`, // this for generic link notification email
            docName: currentAnswer?.title || "",
            message: ""
          };
          await sendNotificationApi(notificationObj);
        }
        fetch();
      } catch (e) {
        if (isAxiosErrorWithCode(e, 409)) {
          setAddExternalUserErrors(current => ({ ...current, email: t("common:duplicateEmail") }));
        }
      }
    },
    [
      auditId,
      t,
      currentAnswer?.id,
      currentAnswer?.participantsUIDs,
      currentAnswer?.assigneeUID,
      fetch,
      onCloseContributorAddDialog,
      currentAnswer?.auditMethodology,
      currentAnswer?.title
    ]
  );

  const onConfirmAddExternalContributor = useCallback(
    async (externalUser: ExternalUserDTO) => {
      setAddExternalUserErrors({});
      try {
        await postContributorExternalUserAPi({ auditId, answersetId: currentAnswer?.id || "", payload: externalUser });
        await reloadTenantUsers();
        onCloseContributorAddDialog();
        fetch();
      } catch (e) {
        if (isAxiosErrorWithCode(e, 409)) {
          setAddExternalUserErrors(current => ({ ...current, email: t("common:duplicateEmail") }));
        }
      }
    },
    [auditId, currentAnswer?.id, fetch, onCloseContributorAddDialog, reloadTenantUsers, t]
  );

  const onRemoveContributor = useCallback(
    async (id: string) => {
      const payload = {
        participantsUIDs: (currentAnswer?.participantsUIDs || []).filter(_id => _id !== id)
      };
      await patchAnswerSetApi({ auditId, id: currentAnswer?.id || "", payload });
      fetch();
    },
    [auditId, currentAnswer?.id, currentAnswer?.participantsUIDs, fetch]
  );
  const contributorsEl = useMemo(() => {
    if (currentAnswer?.auditMethodology === AUDIT_METHODOLOGY.INTERVIEW) {
      return <></>;
    }
    const avatars = (currentAnswer?.participantsUIDs || []).map(
      (contributorId: string) =>
        ({
          userId: contributorId,
          size: "small",
          color: colors.blue.blue400,
          allowRemove: true
        }) as UserAvatarProps
    );
    return (
      <Box sx={sx.row}>
        <Box sx={sx.rowLeft}>
          <GroupAddIcon />
          <Box mr={2} />
          <Typography>{t("add_participant:Contributors")}</Typography>
        </Box>
        <Box sx={{ ...sx.rowRight, ...sx.contributors }}>
          <UserAvatarList avatars={avatars} onRemove={onRemoveContributor} onAdd={onShowContributorAddDialog} />
        </Box>
      </Box>
    );
  }, [
    currentAnswer?.participantsUIDs,
    currentAnswer?.auditMethodology,
    onRemoveContributor,
    onShowContributorAddDialog,
    sx.contributors,
    sx.row,
    sx.rowLeft,
    sx.rowRight,
    t
  ]);

  const onCloseCallback = useCallback(() => {
    setCurrentAnswerIndex(1);
    setCurrentAnswer(null);
    onClose();
  }, [onClose]);

  /* PROGRESS */
  const progressEl = useMemo(() => {
    return (
      <Box sx={sx.row} borderBottom={`1px solid ${colors.divider}`}>
        <Box sx={sx.rowLeft}>
          <ProgressIcon />
          <Box mr={2} />
          <Typography>{t("audit_details:status")}</Typography>
        </Box>
        <Box sx={sx.rowRight}>
          {currentAnswer?.status === "pending" && <LazySvgIcon name="Draft" />}
          {currentAnswer?.status === "inprogress" && <InProgressStatusIcon />}
          {currentAnswer?.status === "completed" && <LazySvgIcon name="Completed" />}
          <Box mr={2} />
          <Typography>{currentAnswer?.status ? t(`audit_status:${currentAnswer.status}`) : ""}</Typography>
        </Box>
      </Box>
    );
  }, [currentAnswer?.status, sx.row, sx.rowLeft, sx.rowRight, t]);

  const onAnswerCallback = useCallback(
    async (questionId, payload) => {
      if (auditId && currentAnswer) {
        await postAnswerForAnswerSet({ auditId, answerSetId: currentAnswer.id, questionId, payload });
        loadQuestionnaire();
      }
    },
    [auditId, currentAnswer, loadQuestionnaire]
  );

  const auditQuestionsRenderEl = useMemo(
    () =>
      currentAnswer && (
        <Box style={{ paddingRight: "42px" }}>
          <AuditQuestionsRender
            allowAnswer={currentAnswer.auditMethodology === "interview" ? true : false}
            templateStatus={"active"}
            selectedId={null}
            questions={questions}
            onAnswer={onAnswerCallback}
          />
        </Box>
      ),
    [currentAnswer, questions, onAnswerCallback]
  );

  return (
    <ViewerModal
      open={open}
      title={auditTitle}
      onClose={onCloseCallback}
      metaView={metaviewEl}
      pagination={itemPagination}
      // topRightIcons={<LazySvgIcon name="Link" color={theme.palette.grey[600]} />}
    >
      {!isLoading && (
        <Box>
          {participantEl}
          {contributorsEl}
          {progressEl}
          {customAlertEl}
          {auditQuestionsRenderEl}
          {submitButtonEl}
        </Box>
      )}
      <AddContributorDialog
        auditMethodology={auditMethodology}
        open={openAddContributorDialog}
        errors={addExternalUserErrors}
        onCancel={onCloseContributorAddDialog}
        onConfirmInternal={onConfirmAddInternalContributor}
        onConfirmExternal={onConfirmAddExternalContributor}
      />
    </ViewerModal>
  );
};

export default ParticipantViewer;
