import { AxiosInstance } from "axios";
import { getOrganizationFilter } from "app/pages/shared/Filters/filters";
import overviewBaseController, {
  OverviewController,
  OverviewNewItem,
  OverviewResult,
  OverviewSetup
} from "components/Overview/controllers/overviewBaseController";
import { COLLECTIONS } from "app/collections";
import { useUserAndTenantData } from "../../../../handlers/userAndTenant/user-tenant-context";
import { useResources } from "../../../../contexts/resource-context";
import { departmentsDecorator } from "../../../../../components/Overview/controllers/decorator/departmentsDecorator";
import { apiEndpoints } from "app/api/apiEndpoint";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { OVERVIEW_ACTIONS, useOverviewDispatch, useOverviewState } from "app/contexts/overview-context";

const ResourceTypeOverviewController = (
  axiosInstance: AxiosInstance,
  options: {
    readonly filter?: {
      readonly resourceType: string;
    };
  }
): OverviewController => {
  const apiUrl = `${apiEndpoints.resourceUrl}/api/resources/${options?.filter?.resourceType}/overview`;

  const { addToSeenItemsOfUserHook } = useUserAndTenantData();
  const { refreshResources } = useResources();
  const dispatch = useOverviewDispatch();
  const { selectedId } = useOverviewState()[COLLECTIONS.RESOURCES];

  const { t } = useTranslation(`resources_${options?.filter?.resourceType}`);

  const sortings = [
    {
      field: "title",
      type: "asc",
      label: t("filter_criteria:aToZ")
    },
    {
      field: "title",
      type: "desc",
      label: t("filter_criteria:zToA")
    }
  ];

  const translateItem = useCallback(obj => ({ ...obj, title: t(obj.title) }), [t]);

  const baseController = overviewBaseController(axiosInstance, COLLECTIONS.RESOURCES, translateItem, [
    departmentsDecorator
  ]);
  const getFilters = (data: OverviewResult) => [getOrganizationFilter("orgUnitIds", data._departments, t)];

  const getOverview = async (setup: OverviewSetup): Promise<OverviewResult | null> => {
    const data = await baseController.getOverview(setup, apiUrl);
    if (!data) {
      return null;
    }

    return {
      ...data,
      items: data?.items || [],
      sortings: sortings,
      filters: getFilters(data)
    };
  };

  const addItem = async (data: OverviewNewItem) => {
    const response = await baseController.addItem(
      {
        nameKey: data.title
      },
      `/${options?.filter?.resourceType}`,
      {
        params: { wait: true }
      }
    );
    const resourceId = response.headers["x-resource-id"] || "";
    await addToSeenItemsOfUserHook(COLLECTIONS.RESOURCES, resourceId);
    await refreshResources();
    return resourceId;
  };

  const patchItem = async (id: string, data: object) => {
    const title = ("title" in data && (data.title as string)) || "";

    const result = await baseController.patchItem(
      id,
      {
        nameKey: title
      },
      `/${options?.filter?.resourceType}/${id}`,
      {
        params: { wait: true }
      }
    );
    await refreshResources();
    return result;
  };

  const deleteItem = async (id: string) => {
    const result = await baseController.deleteItem(id, `/${options?.filter?.resourceType}/${id}`, {
      params: { wait: true }
    });
    dispatch({
      type: OVERVIEW_ACTIONS.SET_SELECTED_ID,
      collection: COLLECTIONS.RESOURCES,
      selectedId: selectedId === id ? null : selectedId
    });
    await refreshResources();
    return result;
  };

  const goToItem = async () => {
    // do nothing
  };

  const addItemAndGo = async (data: OverviewNewItem) => {
    await addItem(data);
    await refreshResources();
  };

  const validateItem = (data: object) => {
    const title = ("title" in data && (data.title as string)) || "";
    if (!title) {
      return "Invalid";
    }
    return null;
  };

  const markAllAsRead = async () => {
    if (!baseController.markAllAsRead) {
      return;
    }
    await baseController.markAllAsRead(options?.filter?.resourceType);
    await refreshResources();
  };

  return {
    ...baseController,
    addItem,
    patchItem,
    deleteItem,
    getOverview,
    validateItem,
    goToItem,
    addItemAndGo,
    markAllAsRead
  };
};

export default ResourceTypeOverviewController;
