import { useCallback } from "react";
import overviewBaseController, {
  OverviewController,
  OverviewNewItem,
  OverviewResult,
  OverviewSetup
} from "components/Overview/controllers/overviewBaseController";
import { createOverviewItemDefaultName } from "app/utils/create-overview-item-default-name";
import { COLLECTIONS } from "app/collections";
import { useTranslation } from "react-i18next";
import {
  getContractTypesFilter,
  getDataProcessingOutsideEUFilter,
  getExternalRecipientSelectedInPAFilter,
  getExternalRecipientStatusFilter,
  getLabelsFilter,
  getOrganizationFilter,
  getProcessTaskFilter,
  getSafeguardsFilter,
  getServiceTypeFilter
} from "app/pages/shared/Filters/filters";
import { createExternalRecipient, updateExternalRecipient } from "../../../handlers/externalRecipientHandler";
import { useUserAndTenantData } from "../../../handlers/userAndTenant/user-tenant-context";
import { AxiosInstance, AxiosRequestConfig } from "axios";
import { useExternalRecipients } from "../../../contexts/external-recipient-context";
import { resourcesDecorator } from "../../../../components/Overview/controllers/decorator/resourcesDecorator";
import { departmentsDecorator } from "../../../../components/Overview/controllers/decorator/departmentsDecorator";

export interface ExternalRecipientsOverviewCollectionParams {
  readonly setNotConnectedItemsCount?: (count: number) => void;
  readonly exportToXLSX: (ids: string[]) => Promise<void>;
  readonly filter?: {
    readonly externalRecipientIds?: string[];
  };
}

const ExternalRecipientsOverviewController = (
  axiosInstance: AxiosInstance,
  collectionParams: ExternalRecipientsOverviewCollectionParams
): OverviewController => {
  const { t } = useTranslation();

  const baseController = overviewBaseController(axiosInstance, COLLECTIONS.EXTERNAL_RECIPIENTS, undefined, [
    resourcesDecorator,
    departmentsDecorator
  ]);
  const { addToSeenItemsOfUserHook } = useUserAndTenantData();
  const { loadExternalRecipientsHook } = useExternalRecipients();

  const getFilters = useCallback(
    (overviewResult: OverviewResult) => [
      getLabelsFilter("labels", overviewResult._resources, t),
      getServiceTypeFilter("serviceType", t),
      getContractTypesFilter("contractTypes", t, overviewResult._resources),
      getOrganizationFilter("allOrgUnitIds", overviewResult._departments, t),
      getOrganizationFilter("responsibleOrgUnitId", overviewResult._departments, t, "responsibleDepartment"),
      getDataProcessingOutsideEUFilter("dpExcluded", t),
      getSafeguardsFilter("safeguards", t, overviewResult._resources),
      getExternalRecipientStatusFilter("approved", t),
      getExternalRecipientSelectedInPAFilter("selectedInProcess", t),
      getProcessTaskFilter("openTasks", t)
    ],
    [t]
  );

  const getSortings = useCallback(
    () => [
      {
        field: "title",
        type: "asc",
        label: t("filter_criteria:aToZ")
      },
      {
        field: "title",
        type: "desc",
        label: t("filter_criteria:zToA")
      },
      {
        field: "createdAt",
        type: "desc",
        label: t("filter_criteria:newFirst")
      },
      {
        field: "createdAt",
        type: "asc",
        label: t("filter_criteria:oldFirst")
      }
    ],
    [t]
  );

  const getPaginatedOverview = async (setup: OverviewSetup) => {
    const overview = await baseController.getPaginatedOverview(
      collectionParams?.filter?.externalRecipientIds && Array.isArray(collectionParams.filter.externalRecipientIds)
        ? {
            ...setup,
            filter: {
              id: collectionParams.filter.externalRecipientIds
            }
          }
        : setup
    );
    if (!overview) {
      return null;
    }

    // set notConnectedItemsCount
    if (collectionParams?.setNotConnectedItemsCount && overview) {
      collectionParams?.setNotConnectedItemsCount(overview.notConnectedItemsCount || 0);
    }
    return {
      ...overview,
      filters: getFilters(overview),
      sortings: getSortings()
    };
  };

  const updatePaginatedOverview = async (setup: OverviewSetup) => {
    const overview = await baseController.updatePaginatedOverview(
      collectionParams?.filter?.externalRecipientIds && Array.isArray(collectionParams.filter.externalRecipientIds)
        ? {
            ...setup,
            filter: {
              id: collectionParams.filter.externalRecipientIds
            }
          }
        : setup
    );

    if (!overview) {
      return null;
    }

    // set notConnectedItemsCount
    if (collectionParams?.setNotConnectedItemsCount && overview) {
      collectionParams?.setNotConnectedItemsCount(overview.notConnectedItemsCount || 0);
    }

    return {
      ...overview,
      filters: getFilters(overview),
      sortings: getSortings()
    };
  };

  const goToItem = (id: string) => {
    baseController.goToItem(`/external-recipients/general/${id}`);
  };

  const addItem = async (data: OverviewNewItem) => {
    const { id: createdId, response } = await createExternalRecipient({
      name: data?.title || createOverviewItemDefaultName(COLLECTIONS.EXTERNAL_RECIPIENTS),
      serviceType: "SERVICEPROVIDER"
    });
    await addToSeenItemsOfUserHook(COLLECTIONS.EXTERNAL_RECIPIENTS, createdId);
    await loadExternalRecipientsHook();
    return response;
  };

  const patchItem = async (id: string, data: object) => {
    const response = await updateExternalRecipient(id, {
      name: ("title" in data && (data.title as string)) || ""
    });
    await loadExternalRecipientsHook();
    return response;
  };

  const deleteItem = async (id: string, url?: string, options?: AxiosRequestConfig) => {
    const response = await baseController.deleteItem(id, url, options);
    await loadExternalRecipientsHook();
    return response;
  };

  const addItemAndGo = async (data: OverviewNewItem) => {
    const response = await addItem(data);
    goToItem(response.headers["x-resource-id"] || "");
  };

  const exportItems = async (format: string, ids: string[]) => {
    await collectionParams.exportToXLSX(ids);
  };

  const exportAllItems = async (format: string, setup: OverviewSetup) => {
    const data = await baseController.loadAllPaginatedPages(setup);
    if (data) {
      const ids = data.allItems.map(({ id }) => id);
      await collectionParams.exportToXLSX(ids);
    }
  };

  const markAllAsRead = async () => {
    await baseController?.markAllAsRead?.();
    await loadExternalRecipientsHook();
  };

  const getTemplateItems = async () => {
    return baseController?.getTemplateItems?.("/overview/templates");
  };

  const addItemsFromTemplates = async (data: { templateIds: string[] }) => {
    const response = await baseController?.addItemsFromTemplates?.(data, "/overview/templates");
    await loadExternalRecipientsHook();
    return response;
  };

  return {
    ...baseController,
    getPaginatedOverview,
    updatePaginatedOverview,
    goToItem,
    addItem,
    patchItem,
    deleteItem,
    addItemAndGo,
    getTemplateItems,
    addItemsFromTemplates,
    exportItems,
    exportAllItems,
    markAllAsRead
  };
};

export default ExternalRecipientsOverviewController;
