import React from "react";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

export default function QuestionTitle({ children, disabled }) {
  return <Typography variant="body1">{children}</Typography>;
}

QuestionTitle.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool
};

QuestionTitle.defaultProps = {
  children: null,
  disabled: false
};
